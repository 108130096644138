import {Flex} from "antd";
import {useState} from "react";
import {PracticesHttpService} from "../../services/Http/PracticesHttpService";
import {Practice, PracticeSearchBody} from "../../models/Practice/Practice";
import {Utils} from "../../services/Utils";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Paths} from "../../models/Paths";
import {PracticesTableFilters} from "../../components/Common/PracticesTableFilters/PracticesTableFilters";
import {useNavigate} from "react-router-dom";
import {QueryParameterModel} from "../../models/QueryParameter";
import {usePage} from "../../customHooks/UsePage";
import {SorterResult} from "antd/es/table/interface";
import {SortTableLuxottica} from "../../services/SortTableLuxottica";
import {useTranslation} from "react-i18next";
import {AuthService} from "../../services/AuthService";
import {ClaimStatuses} from "../../models/Practice/PracticeDetail";
import {PracticeTable} from "../../components/PracticeTable";
import {useDispatch, useSelector} from "react-redux";
import {saveSortParameters} from "../../store/states/FormState";

export function Practices(){
    const appUser = AuthService.getUser();
    const shop = appUser?.defaultLocation?.code || null

    const [listOfPractices, setListOfPractices] = useState<Practice[]>([]);
    const[loading,setLoading] = useState<boolean>(false);

    const [bodyFilters,setBodyFilters] = useState<PracticeSearchBody>({
        patientName: null,
        insuranceProviderId: null,
        agreement: null,
        shop: shop,
        status: null,
        creationDateRange: [null,null],
        claimStatus: ClaimStatuses.OPEN
    })

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const sortParameters:QueryParameterModel[] = useSelector((state: any) => state.practiceTableFilterForm.sortParameters);

    const sortParametersMap = new Map<string, any>(sortParameters.map(item => [item.name, item.value]));

    const {
        page,
        lastPage,
        updateValues,
        incrementPage,
        getDefaultPageQueryParameters,
        resetPagination,
        updateSortParams,
        getPaginationInfo
    } = usePage({ page:sortParametersMap.get('page'), size:sortParametersMap.get('pageSize'), lastPage:1, allItemsNumber:0 }, sortParametersMap.get('sortColumn'), sortParametersMap.get('sortDirection'));

    const navigate = useNavigate();

    const onScrollLastRow = async () => {
        if (page !== lastPage) {
            let queryParameters:QueryParameterModel[] = incrementPage();
            await loadData(queryParameters,bodyFilters)
        }
    }

    const loadData = async (queryParameters: QueryParameterModel[], body?: PracticeSearchBody, isFilterChange: boolean = false) => {
        setLoading(true);
        const response = await PracticesHttpService.getListOfPractices(queryParameters,body)
        updateValues(response.data.currentPage, response.data.allItemsNumber, response.data.totalPages)
        let dataWithKeys = Utils.addReactUniqueKey(response.data.items)
        if(!isFilterChange){
            dataWithKeys = listOfPractices.concat(dataWithKeys)
        }
        setListOfPractices(dataWithKeys);
        setLoading(false);
    }

    function goToDetails(key: number){
        navigate(Paths.PRACTICES_DETAILS + "/" + key );
    }

    const onCloseModal = async (body:PracticeSearchBody) => {
        setBodyFilters(body);
        setListOfPractices([]);
        resetPagination();
        await loadData(sortParameters,body,true);
    }

    const onSort = async (info :SorterResult<any> | SorterResult<any>[]) => {
        setListOfPractices([]);
        let sortQueryParams: QueryParameterModel[] = SortTableLuxottica.getQueryParams(info);
        updateSortParams(sortQueryParams);
        resetPagination();
        let params = getDefaultPageQueryParameters(false);
        params = params.concat(sortQueryParams)
        dispatch(saveSortParameters(params));
        await loadData(params, bodyFilters,true);
    }

    const extractDefaultOrderedColumn = ():string => {
        return sortParameters?.find(item => item.name === "sortColumn")?.value;
    }

    const extractDefaultOrderedColumnDirection = () => {
        const beValue = sortParameters?.find(item => item.name === "sortDirection")?.value;
        return (beValue === 'ASC') ? 'ascend' : 'descend';
    }

    const title = t("practices") + " - " + getPaginationInfo().allItemsNumber;

    return <>
        <Flex justify={'space-between'} align={"center"}>
            <PageTitle title={title} backTo={Paths.HOME} />
            <PracticesTableFilters onCloseModal={onCloseModal}></PracticesTableFilters>
        </Flex>
        <Flex vertical>
            <PracticeTable goToDetail={goToDetails}
                           data={listOfPractices}
                           loading={loading}
                           onSort={onSort}
                           orderedColumn={extractDefaultOrderedColumn()}
                           orderedColumnDirection={extractDefaultOrderedColumnDirection()}
                           onScrollLastRow={onScrollLastRow}
            />
        </Flex>

     </>
}
