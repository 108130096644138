import {Input, Select, SelectProps} from "antd";
import {useGetInsuranceProvidersQuery} from "../../../store/states/InsuranceProvidersState";
import {InsuranceProviderEclaim} from "../../../models/InsuranceProvider";
import {useEffect, useState} from "react";

export interface SearchBarProps {
    onChange:(value:number)=>void;
}

export function InsuranceProviderSearchDropdown(props:SearchBarProps){
    const {data: insuranceProviders, error, isLoading} = useGetInsuranceProvidersQuery([]);
    const [options,setOptions] = useState<SelectProps['options']>();

    useEffect(() => {
        setOptions(getOptions(insuranceProviders));
    }, [insuranceProviders]);

    function onChange(element:number){
        props.onChange(element)
    }

    function getOptions(insuranceProviders:InsuranceProviderEclaim[]){
        return insuranceProviders?.map((element:InsuranceProviderEclaim)=>{
            return { value: element.id, label: element.description }
        }).sort((a,b) => {
            return a.label.localeCompare(b.label)
        })
    }

    return <>
        <Select
            showSearch
            placeholder="Seleziona ente"
            optionFilterProp="label"
            onChange={onChange}
            style={{width:300}}
            allowClear={true}
            //onSearch={onSearch}
            options={options}
        />
    </>
}