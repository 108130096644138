export function saveFile(blobData: string, filename: string) {
    const data = new Blob([blobData]);
    const URL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = URL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
}

export function parseFilenameFromHeader(contentDisposition: string) {
    return contentDisposition.replace("attachment; filename=", "");
}