import {ReactElement} from "react";
import {IconLuxottica} from "../../../Common/IconLuxottica/IconLuxottica";
import {IconLuxotticaDimension} from "../../../../models/IconLuxotticaDimension";

export interface SquareTileIconProps{
    icon:ReactElement<any, any>;
    
}
export function SquareTileIcon (props:SquareTileIconProps){
    return <>
        <span className={"icon"}>
            {props.icon}
        </span>

    </>
}