import {Flex, Table, TablePaginationConfig, TableProps} from "antd";
import "./TableLuxottica.css"
import {LogService} from "../../../services/LogService";
import {ReactNode, useState} from "react";
import {ArrowDownOutlined,ArrowUpOutlined} from "@ant-design/icons";
import {isNil} from "lodash";
import {FilterValue, SorterResult, SortOrder} from "antd/es/table/interface";
import {ExpandTableRows} from "./components/ExpandTableRows";
export interface TableLuxotticaProps{
    columns: TableProps<any>['columns']
    data: any[]|undefined
    onScrollLastRow?:()=>void;
    expandable?:boolean
    expandedRowComponent?: (record: any) => ReactNode; // Aggiungi il parametro record
    loading?:boolean;
    onSort?:(info :SorterResult<any> | SorterResult<any>[])=>void
    scrollY?:string|number
    virtualScroll?:boolean
}
export function TableLuxottica (props:TableLuxotticaProps){
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const virtualScroll = props.virtualScroll != undefined ? props.virtualScroll : true;

    function getScroll(){
        if(isNil(props.scrollY) === true ) {
            return {y: 500}
        }
        return {y: props.scrollY}
    }
    function handleScroll (e:any){
        const scrollTop = Math.round(e.target.scrollTop);
        const bottom:boolean = e.target.scrollHeight - scrollTop === e.target.clientHeight;
        if (bottom) {
            if(props?.onScrollLastRow)
                props.onScrollLastRow();
        }
    }
    function getColumns(){
        return props.columns?.map((element)=>{
            element.sortIcon = (option: {
                sortOrder: SortOrder;
            })=>{
                if(option.sortOrder === "descend")
                    return <ArrowDownOutlined />
                if(option.sortOrder === "ascend")
                    return <ArrowUpOutlined />
                return ""
            }
            return element;
        })
    }
    function onChangeTable(selectedRowKeys:TablePaginationConfig, selectedRows: Record<string, FilterValue | null>, info :SorterResult<any> | SorterResult<any>[]){
        if(isNil(info) === false && !isNil(props?.onSort) ){
            // @ts-ignore
            props?.onSort(info)
        }
    }
    function getExpandableConfig (){
        if(isNil(props.expandable) ||  props.expandable === false){
            return undefined;
        }

        return {
            expandedRowRender: (record:any) => props.expandedRowComponent ? props.expandedRowComponent(record) : false,
            rowExpandable: (record:any) => props.expandable ?? false,
            expandedRowKeys:expandedKeys,
/*
            onExpand: (expanded:any, record:any) =>{ handleExpand(expanded, record)}
*/
            onExpandedRowsChange: (keys:any) => setExpandedKeys(keys),
        }
    }

    return<div className={"table-luxottica-container"} style={{ width: '100%' }}>
        <Flex vertical>
            {/*<div className={"expand-all-container"} hidden={!props.expandable}>*/}
            {/*    <ExpandTableRows dataSource={props.data} onExpand={setExpandedKeys}></ExpandTableRows>*/}
            {/*</div>*/}
            <Table rowKey={'key'} virtual={virtualScroll} scroll={getScroll()} pagination={false}
                   columns={getColumns()} dataSource={props.data}
                   className={"table-luxottica"} onScroll={handleScroll}
                   loading={props.loading ?? false}
                   onChange={onChangeTable}
                   expandable={getExpandableConfig()}
            />
        </Flex>

    </div>
}
