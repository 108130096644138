export class EnvService{
    static getBackEndUrl():string|undefined{
        return process.env.REACT_APP_BACKEND_URL;
    }
    static getLogLevel(){
        return process.env.REACT_APP_LOG_LEVEL;
    }
    static authModeIsMock(){
        return process.env.REACT_APP_AUTH === "MOCK";
    }
}