import {AutoComplete, Select, SelectProps} from "antd";
import {useEffect, useState} from "react";
import {AuthService} from "../../../../services/AuthService";
import {Employee} from "../../../../models/Auth/Employee";
import {AxiosResponse} from "axios";
import {isNil} from "lodash";
import {OperatorAutocompleteOptions} from "../../../Common/PracticesTableFilters/filters/FilterUser";

export interface ListOfOperatorsProps {
    id:string
    onChangeOperator?:(value:Employee|undefined)=>void
    onSelected?:(value:any, patient:Employee)=>void
    onDeselect?:(value:any)=>void
    onClear?:()=>void
}
export function ListOfOperator(props:ListOfOperatorsProps){
    const [operators,setOperators] = useState<Employee[]>([])
    const [options,setOptions] = useState<SelectProps['options']>()

    async function handleSearch (value:string){
        let response = await AuthService.searchOperators(value);
        let operators : Employee[] = response.data
        setOperators(operators);
        if(!isNil(response.data)){
            const newOptions:OperatorAutocompleteOptions[]= operators.map((operator:Employee)=>{
                return {
                    key: operator.id,
                    value:operator.name,
                }
            })
            setOptions(newOptions);
        }
    }
    function onChange(value:any){
        if(props?.onChangeOperator){
            props?.onChangeOperator(value)
        }
    }
    return <>

        <AutoComplete options={options}
                      style={{ width: 250 }}
                      placeholder="Cognome Nome Operatore"
                      onSearch={handleSearch}
                      onChange={onChange}
                      allowClear={true}
                      onSelect={(value, option:any) => {
                          if(isNil(props?.onSelected) === false && isNil(option?.key) === false){
                              let found = operators?.find((element: Employee)=>{
                                  return element.id === option.key
                              })
                              // @ts-ignore
                              props?.onSelected(option,found);
                          }
                      }}
                      onDeselect={props.onDeselect}
                      onClear={props.onClear}
        ></AutoComplete>
    </>
}