import {Form, Input} from "antd";
import { Rule } from "antd/lib/form";
import {CSSProperties} from "react";

const FormItem = Form.Item;
const Password = Input.Password;

export interface PasswordProps {
    name: string;
    placeholder?: string;
    followPasswordPolicy: boolean,
    style?: CSSProperties;
    label?: string;
    customRules?: Array<Rule>;
}

const LuxotticaPassword = (props: PasswordProps) => {

    const rules = [];
    rules.push({
        required: true,
        message: 'Inserisci la password'
    })

    if (props.followPasswordPolicy) {
        rules.push({
            min: 8,
            message: 'La password deve essere lunga almeno 8 caratteri'
        })
        rules.push({
            pattern: /\d+/,
            message: 'La password deve contenere almeno un numero'
        })
        rules.push({
            pattern: /[A-Z]+/,
            message: 'La password deve contenere almeno una lettera maiuscola'
        })
        rules.push({
            pattern: /[a-z]+/,
            message: 'La password deve contenere almeno una lettera minuscola'
        })
        rules.push({
            pattern: /[\^$*.\[\]{}()?\-"!@#%&/\\,><':;|_~`+=]+/,
            message: 'La password deve contenere almeno un carattere speciale'
        })
    }

    if (props.customRules) {
        rules.push(...props.customRules)
    }

    return <FormItem
        name={props.name}
        rules={rules}
        validateFirst
        style={props.style}
        label={props.label}
    >
        <Password placeholder={props.placeholder} />
    </FormItem>
}

export default LuxotticaPassword;