import {Form, Select, SelectProps} from "antd";
import "./Filters.css"
import {PracticeType} from "../../../../models/Practice/Practice";
import {useTranslation} from "react-i18next";
export interface FilterManageProps{
    onChange?: (value:PracticeType)=>void;
    className?:React.HTMLAttributes<HTMLDivElement>['className'];
    required?:boolean;
    showLabel?:boolean
    disabled?:boolean
}

export function FilterRefundType (props:FilterManageProps){
    const { t, i18n } = useTranslation();
    const options : SelectProps['options'] = getOptions();
    const rules=[{ required: props.required ?? false, message: 'Il campo gestione è obbligatorio' }]

    const handleChange = (value: PracticeType) => {
        if(props?.onChange){
            props?.onChange(value)
        }
    };

    function getOptions(){
        return Object.keys(PracticeType).map((key:string)=>{
            return {value: PracticeType[key as keyof typeof PracticeType], label:t(PracticeType[key as keyof typeof PracticeType])}
        })
    }

    function getClassName(){
        return "filter " + (props?.className ?? "")
    }

    return <>
        <Form.Item name={"refundType"}
                   label={props.showLabel && "Gestione"}
                   rules={rules}
        >
            <Select
                placeholder="Gestione"
                className={getClassName()}
                options={options}
                onChange={handleChange}
                allowClear={true}
                disabled={props.disabled}
            />
        </Form.Item>

    </>
}
