import { Modal} from "antd";
import {CambioVisus} from "../index";
import {Patient} from "../../../models/Patient";
import {InsuranceProviderEclaim} from "../../../models/InsuranceProvider";
import {CambioVisusFormData} from "../../../models/cambioVisus";

export interface NewVisusModal {
    isModalOpen:boolean;
    onCancel: ()=>void;
    patient?:Patient,
    insuranceProvider:InsuranceProviderEclaim,
    shopGroup: string;
    onSubmit: (data: CambioVisusFormData) => Promise<void>;
}

export function NewVisusModal(props:NewVisusModal) {

    return <>
        <Modal title="Modulo Cambio Visus" width={1000} open={props.isModalOpen} onCancel={props.onCancel} footer={[]} destroyOnClose>
            <CambioVisus shopGroup={props.shopGroup} insuranceProvider={props.insuranceProvider} onSubmit={props.onSubmit} onClose={props.onCancel} patient={props.patient} ></CambioVisus>
        </Modal>
    </>
}