import {Form, Select} from "antd";

export interface FilterInsegnaProps {
    onChange?:(valued:string)=>void;
    className?:React.HTMLAttributes<HTMLDivElement>['className'];
}

export function FilterInsegna(props: FilterInsegnaProps) {
    const options = [
        {
            value: 'SV',
            label: 'Salmoiraghi & Viganò'
        },
        {
            value: 'GV',
            label: 'Grand Vision'
        }
    ]

    function getClassName(){
        return "filter " + (props?.className ?? "")
    }

    return <>
        <Form.Item name="insegnaCode">
            <Select
                options={options}
                placeholder="Insegna"
                className={getClassName()}
                onChange={props.onChange}
                allowClear={true}
            />
        </Form.Item>
    </>
}