
export interface PracticeMessage{
    id:number
    operator: string,
    insertDate: number,
    text: string,
    status:PracticeMessageState
}
export interface PracticeMessageBody{
    operator: string,
    text: string,
    status:PracticeMessageState|undefined
}
export interface PracticeMessageResponse{
    allItemsNumber:number;
    items:PracticeMessage[];
}
export enum PracticeMessageState{
    CONTATTATO="CONTATTATO",
    PRESO_APPUNTAMENTO="PRESO_APPUNTAMENTO",
    STATELESS="STATELESS"
    // CLOSE_PRACTICE="Chiudi pratica",
}
