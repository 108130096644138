import {Alert, Form, FormProps, Modal} from "antd";
import LuxotticaPassword from "../LoginForm/LuxotticaPassword";
import {Rule} from "antd/lib/form";
import { updatePassword } from "aws-amplify/auth";
import {useState} from "react";

export interface ChangePasswordModalProps {
    isOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
}

interface ChangePasswordForm {
    oldPassword: string;
    newPassword: string;
}

export function ChangePasswordModal(props: ChangePasswordModalProps) {
    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const newPasswordRule: Array<Rule> = [
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value) {
                    return Promise.resolve();
                }
                if (getFieldValue('oldPassword') === value) {
                    return Promise.reject(new Error('La nuova password non può essere uguale alla vecchia!'));
                }
                return Promise.resolve();
            },
        })
    ]

    const setNewPassword: FormProps<ChangePasswordForm>['onFinish'] = async (values) => {
        setIsLoading(true);
        setErrorMessage(null);
        try {
            await updatePassword({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword
            });
            props.onOk();
        } catch (e) {
            console.error(e)
            setErrorMessage('La password inserita non è quella corretta');
        } finally {
            setIsLoading(false);
        }
    }

    return <>
        <Modal open={props.isOpen}
               title="Cambia password"
               onCancel={props.onCancel}
               okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
               destroyOnClose
               modalRender={(dom) => {
                   return <Form form={form} onFinish={setNewPassword}>
                       {dom}
                   </Form>
               }}
               confirmLoading={isLoading}
        >
            {errorMessage && <Alert message={errorMessage} type="error" style={{marginBottom: "24px"}} />}
            <LuxotticaPassword name="oldPassword" followPasswordPolicy={false} placeholder="Vecchia password"/>
            <LuxotticaPassword name="newPassword" followPasswordPolicy={true} placeholder="Nuova password" customRules={newPasswordRule} />
        </Modal>
    </>
}