import {QueryParameterModel} from "../../models/QueryParameter";

export class QueryParamsService {
    static getUrl(queryParameters:QueryParameterModel[]):string{
        if (!queryParameters || queryParameters.length === 0) {
            return "";
        }

        let returnValue="?";
        queryParameters?.forEach((element:QueryParameterModel, index: number)=>{
            returnValue += `${encodeURIComponent(element.name)}=${encodeURIComponent(element.value)}`;
            if (index < queryParameters.length - 1) {
                returnValue += "&";
            }
        });

        return returnValue;
    }
}
