import {Image, Typography} from 'antd';
import {useGetInsuranceProvidersQuery} from "../../../store/states/InsuranceProvidersState";
import {InsuranceProviderEclaim} from "../../../models/InsuranceProvider";
import {getLogoFromIntegrationCode} from "../logoUtils";
export interface InfoLogoProps{
    organizationId:number|undefined
    width?:number
    height?:number
}
export function InfoLogo(props:InfoLogoProps){
    const {data: insuranceProviders, error, isLoading} = useGetInsuranceProvidersQuery([]);
    const { Text } = Typography;

    const getLogoProvider = (): string => {
        let insuranceProvider: InsuranceProviderEclaim|null = getInsuranceProviderById(props.organizationId);
        if (insuranceProvider == null)
            return "";
        return getLogoFromIntegrationCode(insuranceProvider.integrationCode)
    };

    const getInsuranceProviderById = (id:number|undefined): InsuranceProviderEclaim | null => insuranceProviders?.find((element: InsuranceProviderEclaim) => {
        return id === element.id
    });

    const getNameInsuranceProvider = () => {
        let insuranceProvider: InsuranceProviderEclaim|null = getInsuranceProviderById(props.organizationId);
        return insuranceProvider?.description
    };
    return <>
        <div className="logo-container">

            { getLogoProvider() !== "" &&  <Image src={getLogoProvider()} preview={false} width={props.width} height={props.height}></Image>}
            { getLogoProvider() === "" &&  <Text strong>{getNameInsuranceProvider()}</Text>}

        </div>
    </>
}