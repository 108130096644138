import './App.css';
import './styles/Buttons.css';
import './styles/Menu.css';
import './styles/Icon.css';
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {CognitoService} from "./services/CognitoService";
import React, {useEffect, useState} from "react";
import {Paths} from "./models/Paths";
import {HttpService} from "./services/Http/HttpService";
import {AuthService} from "./services/AuthService";
import {EnvService} from "./services/EnvService";
import {AppRouting} from "./routes/AppRouting";
import {Button, notification} from "antd";
import {NotificationService} from "./services/NotificationService";

function Loading() {
  return <div>Loading...</div>;
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const user = AuthService.getUser();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    onInit()
  }, []);

  async function onInit() {
    if (!EnvService.authModeIsMock()) {
      try {
        await CognitoService.get().loadConfig();
        await CognitoService.get().doLogin();
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false)
    }
  }

  if (loading) {
    HttpService.createLuxotticaAxios(location, navigate);
    CognitoService.createInstance();
    return (
      <Loading />
    );
  }

  if (location.pathname === Paths.LOGIN && user != null)
    return <Navigate to={Paths.HOME} />

  NotificationService.init(api)

  return (
      <>
        {contextHolder}
        <AppRouting />
      </>
  );
}

export default App;
