import {SubmitFunction} from "../LoginForm";
import { confirmResetPassword } from "aws-amplify/auth";
import { useState } from "react";
import AskCode from "./AskCode";
import AskUsername from "./AskUsername";

export interface RecoverPasswordFormProps {
  handleComplete: () => void
}

const RecoverPasswordForm = (props: RecoverPasswordFormProps) => {
  const [step, updateStep] = useState('ASK_USERNAME');
  const [username, updateUsername] = useState('');

  const goNext = (username: string) => {
    updateStep('ASK_CODE');
    updateUsername(username);
  }

  const updatePassword: SubmitFunction<{code: string, newPassword: string}> = async (data) => {
    const {code, newPassword} = data;
    await confirmResetPassword({ username, confirmationCode: code, newPassword });
    props.handleComplete();
  }

  if (step === 'ASK_USERNAME') {
    return <AskUsername goNext={goNext}/>;
  } else {
    return <AskCode onSubmit={updatePassword}/>;
  }
}

export default RecoverPasswordForm;