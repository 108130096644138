import {Avatar, Dropdown, Flex, MenuProps, Typography} from "antd";
import React from "react";
import {UserOutlined} from "@ant-design/icons";
import {AuthService} from "../../../services/AuthService";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../models/Paths";
import {SwitchUserModal} from "../../../components/Common/SwitchUserModal/SwitchUserModal";
import {useModal} from "../../../utils/modals";
import {ChangePasswordModal} from "../../../components/Login/ChangePasswordModal";
import {LoadOperatorsModal} from "../../../components/AdminLoadOperators/index";

export interface UserMenuProps {
    image?: any;
    username: string | undefined;
    lastname?: string | undefined;
    reloadOperator: ()=>void;
}

export function UserMenu(props: UserMenuProps) {
    const {Text} = Typography;
    const navigate = useNavigate();
    const {
        isOpen: isSwitchOperatorOpen,
        open: openSwitchOperatorModal,
        close: closeSwitchOperatorModal
    } = useModal();

    const {
        isOpen: isChangePasswordOpen,
        open: openChangePasswordModal,
        close: closeChangePasswordModal
    } = useModal();

    const {
        isOpen: isLoadOperatorsOpen,
        open: openLoadOperatorsModal,
        close: closeLoadOperatorsModal
    } = useModal();

    const doLogout = () => {
        AuthService.logout()
            .then(() => {
                navigate(Paths.LOGIN)
            });
    }

    const handleClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'logoutButton') {
            doLogout();
        }
        if (e.key === 'changePassword') {
            openChangePasswordModal();
        }
        if (AuthService.isAdmin() && e.key === 'loadOperators') {
            openLoadOperatorsModal();
        }
        if (e.key === 'switchOperator') {
            openSwitchOperatorModal();
        }
    }

    function authMenu() {
        let dropdownItems = [
            {
                label: 'Cambia operatore',
                key: 'switchOperator'
            },
            {
                label: 'Cambia password',
                key: 'changePassword'
            },
            {
                label: 'Logout',
                key: 'logoutButton'
            }
        ];
        /*
        if (AuthService.isAdmin()) {
            dropdownItems.splice(2, 0, {
                label: 'Carica Operatori',
                key: 'loadOperators'
            });
        }
        */
        return dropdownItems;
    }

    function onOk(){
        closeSwitchOperatorModal();
        props.reloadOperator()
    }

    return (
        <div className={"user-menu"}>
            <Flex align={"center"}>
                <Flex vertical style={{marginRight: '10px'}}>
                    <Text>{props.username}</Text>
                    <Text>{props.lastname}</Text>
                </Flex>
                <Dropdown menu={{ items: authMenu(), onClick: handleClick }} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Avatar size="large" icon={<UserOutlined/>}/>
                    </a>
                </Dropdown>
                { isSwitchOperatorOpen && <SwitchUserModal isOpen={isSwitchOperatorOpen} onOk={onOk} onCancel={closeSwitchOperatorModal}></SwitchUserModal> }
                { isChangePasswordOpen && <ChangePasswordModal isOpen={isChangePasswordOpen} onOk={closeChangePasswordModal} onCancel={closeChangePasswordModal}></ChangePasswordModal> }
                { isLoadOperatorsOpen && <LoadOperatorsModal isOpen={isLoadOperatorsOpen} onOk={closeLoadOperatorsModal} onCancel={closeLoadOperatorsModal}></LoadOperatorsModal> }
            </Flex>
        </div>
    );
}
