import {Button} from "antd";
import {PreventiveDialog} from "./PreventiveDialog";
import {PracticeType} from "../../../../../models/Practice/Practice";
import {useModal} from "../../../../../utils/modals";
import {UploadEstimateBodyRequest} from "../../../../../models/Practice/PracticeDetail";

export interface PreventiveDialogTriggerProps {
    disabled?: boolean;
    isLoaded: boolean;
    onSubmit: (body: UploadEstimateBodyRequest) => Promise<void>;
    agreement: PracticeType;
    integrationCode:string
    insuranceProviderId:number,
    locationCode: string;
}

export function PreventiveDialogTrigger(props: PreventiveDialogTriggerProps) {

    const {
        isOpen,
        open,
        close
    } = useModal()

    const openModal = () => {
        if (!props.isLoaded) {
            open();
        }
    }

    const onSubmit = async (body: UploadEstimateBodyRequest) => {
        await props.onSubmit(body);
        close();
    }

    const onCancel = () => {
        close();
    }

    return <>
        <Button disabled={props.disabled} type={"primary"} className={!props.disabled && props.isLoaded ? "luxottica-button-success" : ""} onClick={openModal}>Preventivo</Button>
        <PreventiveDialog isModalOpen={isOpen} onSubmit={onSubmit} onCancel={onCancel}
                          agreement={props.agreement}
                          integrationCode={props.integrationCode}
                          insuranceProviderId={props.insuranceProviderId}
                          locationCode={props.locationCode}
        />
    </>
}