import {LeftOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";

export interface BackButtonProps {
    navigateTo?: string;
    onClick?:()=>void;
    historyBack?:boolean;
}
export function BackButton(props: BackButtonProps) {
    const navigate = useNavigate();

    const handleClick = () => {
        if(props.onClick) {
            return props.onClick();
        }
        if(props.historyBack){
            return navigate(-1)
        }
        if(props.navigateTo) {
            return navigate(props.navigateTo);
        }

    }

    return (
        <>
            <Button type="primary" icon={<LeftOutlined />} size="small" shape="circle" onClick={handleClick}/>
        </>
    )
}
