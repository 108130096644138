import {NotificationInstance, NotificationPlacement} from "antd/es/notification/interface";

export class NotificationService{
    private static _instance: NotificationService;
    private _api:NotificationInstance;
    private constructor(api:NotificationInstance) {
        this._api = api;
    }
    public static getInstance(): NotificationService {
        return NotificationService._instance
    }
    public static init(api:NotificationInstance):boolean{
        if (!NotificationService._instance) {
            NotificationService._instance = new NotificationService(api)
            return true;
        }
        return false;
    }
    public openNotificationInfo (message:string,content:string="", placement: NotificationPlacement = "topRight")  {
        this._api.info({
            message: message,
            description: content,
            placement
        });
    };
    public openNotificationSuccess (message:string,content:string="", placement: NotificationPlacement = "topRight")  {
        this._api.success({
            message: message,
            description: content,
            placement
        });
    };
    public openNotificationWarning (message:string,content:string="",placement: NotificationPlacement = "topRight")  {
        this._api.warning({
            message: message,
            description: content,
            placement
        });
    };
    public openNotificationError (message:string,content:string="",placement: NotificationPlacement = "topRight")  {
        this._api.error({
            message: message,
            description: content,
            placement
        });
    };
}