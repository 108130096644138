import {Flex, Typography} from "antd";
import Barcode from "react-barcode";
import {toString} from "lodash";
const { Text } = Typography;

export interface DiscountCodeProps {
    discountCode: number|undefined;

}
export function DiscountCode(props: DiscountCodeProps) {
    return (
        <>
            <Flex justify={"space-around"} align={"end"}>
                <Flex align={"center"} gap={"small"}>
                    <Barcode height={35} fontSize={15} background={"none"}  value={toString(props.discountCode)} />
                </Flex>
            </Flex>
        </>
    )
}