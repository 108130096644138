import {Flex} from "antd";
import {PageTitle} from "../../Common/PageTitle/PageTitle";
import {Paths} from "../../../models/Paths";
import {useEffect, useState} from "react";
import {
    Practice,
    PracticeProgressStatus,
    PracticeSearchBody
} from "../../../models/Practice/Practice";
import {usePage} from "../../../customHooks/UsePage";
import {QueryParameterModel} from "../../../models/QueryParameter";
import {useNavigate} from "react-router-dom";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {Utils} from "../../../services/Utils";
import {SorterResult} from "antd/es/table/interface";
import {SortTableLuxottica} from "../../../services/SortTableLuxottica";
import {DashboardFilter, DashboardIdSeries} from "../../../models/Dashboard/Dashboard";
import {isNil} from "lodash";
import {useMediaQuery} from "react-responsive";
import {PracticeTable} from "../../PracticeTable";

interface DashboardTableProps {
    onClickBack: () => void,
    title: string,
    dashboardIdSeries: DashboardIdSeries | null | undefined,
    filters: DashboardFilter | undefined
}

export function DashboardTable (props: DashboardTableProps){
    const [listOfPractices, setListOfPractices] = useState<Practice[]>([]);
    const[loading,setLoading] = useState<boolean>(false);
    const [bodyFilters,setBodyFilters] = useState<PracticeSearchBody>();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const {
        page,
        lastPage,
        updateValues,
        incrementPage,
        getPageQueryParameters,
        getDefaultPageQueryParameters,
        updateSortParams,
        resetPagination
    } = usePage({ page:0,size:20,lastPage:1,allItemsNumber:0 }, "creationDate", "DESC");

    const navigate = useNavigate();

    useEffect(()=>{
        onFilterChange()
    },[props.filters]);

    const onFilterChange = async () => {
        let status;
        switch (props.dashboardIdSeries) {
            case DashboardIdSeries.TO_BE_CONTACTED:
                status = [PracticeProgressStatus.DA_CONTATTARE]
                break;
            case DashboardIdSeries.IN_PROGRESS:
                status = [
                    PracticeProgressStatus.CONTATTATO,
                    PracticeProgressStatus.PRESO_APPUNTAMENTO,
                    PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE,
                    PracticeProgressStatus.DOCUMENTAZIONE_INVIATA,
                    PracticeProgressStatus.PIC_AUTORIZZATA,
                    PracticeProgressStatus.PIC_NON_AUTORIZZATA,
                    PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE,
                    PracticeProgressStatus.FATTURA_DA_INVIARE,
                    PracticeProgressStatus.ERRORE_IN_FATTURA
                ]
                break;
            case DashboardIdSeries.PURCHASE_MADE:
                status = [
                    PracticeProgressStatus.ACQUISTO_EFFETTUATO,
                    PracticeProgressStatus.FATTURA_INVIATA
                ]
                break;
            case DashboardIdSeries.CLOSE_WITHOUT_SALE:
                status = [
                    PracticeProgressStatus.ACQUISTO_NON_EFFETTUATO,
                    PracticeProgressStatus.CHIUSA
                ]
                break;
            case DashboardIdSeries.EXPIRED:
                status = [
                    PracticeProgressStatus.SCADUTA
                ];
                break;
        }

        const locationGroupCode = [];

        if (props.filters) {
            if (props.filters.areaField)
                locationGroupCode.push(props.filters.areaField);
            if (props.filters.insegnaCode)
                locationGroupCode.push(props.filters.insegnaCode);
        }

        let body:PracticeSearchBody = {
            status,
            agreement: props.filters?.refundType,
            shop: props.filters?.locationCode,
            locationGroupCode: locationGroupCode,
            claimStatus: props.filters?.claimStatus
        }
        if(isNil(props?.filters?.dataFrom) === false || isNil(props?.filters?.dataTo) === false){
            body.creationDateRange=[props?.filters?.dataFrom ?? "",props?.filters?.dataTo ?? ""]
        }
        setBodyFilters(body);
        let queryParameters:QueryParameterModel[] = getDefaultPageQueryParameters()
        await loadData(queryParameters, body, true)
    }

    const onScrollLastRow = async () => {
        if(page !== lastPage){
            let queryParameters:QueryParameterModel[] = incrementPage();
            await loadData(queryParameters,bodyFilters)
        }

    };

    const loadData = async (queryParameters:QueryParameterModel[], body?:PracticeSearchBody, isFilterChange:boolean=false) => {
        setLoading(true);
        const response = await PracticesHttpService.getListOfPractices(queryParameters,body)
        updateValues(response.data.currentPage, response.data.allItemsNumber, response.data.totalPages)
        let dataWithKeys = Utils.addReactUniqueKey(response.data.items)
        if(!isFilterChange){
            dataWithKeys = listOfPractices.concat(dataWithKeys);
        }
        setListOfPractices(dataWithKeys);
        setLoading(false);
    };

    const goToDetails = (key: number) => {
        navigate(Paths.PRACTICES_DETAILS + "/" + key );
    };

    const onSort = async (info :SorterResult<any> | SorterResult<any>[]) => {
        setListOfPractices([]);
        let sortQueryParams: QueryParameterModel[] = SortTableLuxottica.getQueryParams(info);
        updateSortParams(sortQueryParams);
        resetPagination();
        let params = getDefaultPageQueryParameters(false);
        params = params.concat(sortQueryParams)

        await loadData(params, bodyFilters,true);
    };

    const getScrollY = () => {
        if (isTabletOrMobile) return 370;
        return undefined
    };

    return  <>
        <Flex justify={'space-between'} align={"center"}>
            <PageTitle title={props.title} onClick={props.onClickBack}>
            </PageTitle>
        </Flex>
        <Flex>
            <PracticeTable data={listOfPractices}
                           loading={loading}
                           goToDetail={goToDetails}
                           onSort={onSort}
                           onScrollLastRow={onScrollLastRow}
                           getScrollY={getScrollY}
            />
        </Flex>

    </>
}
