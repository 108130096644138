import {
    Practice,
    PracticeProgressStatus,
    PracticeType
} from "../../../models/Practice/Practice";
import {Divider, Flex, Typography} from "antd";
import "./PracticesExpandedInfo.css"
import {ClaimProgressInfoResponse, PracticeProgress} from "../../../models/Practice/PracticeDetail";
import {Utils} from "../../../services/Utils";
import {useEffect, useState} from "react";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {AxiosResponse} from "axios";
import {PracticeMessage} from "../../../models/Practice/PracticeMessage";
import {IconState} from "../IconsState/IconState";
import {isNil} from "lodash";

export interface PracticesExpandedInfoProps{
    record:Practice
}
export function PracticesExpandedInfo(props:PracticesExpandedInfoProps){
    const { Text } = Typography;
    const [listOfProgress,setListOfProgress]= useState<PracticeProgress[]>([])
    const [lastChatMessage, setLastChatMessage] = useState<PracticeMessage | null>(null);

    const loadData = async () => {
        const {data} = await PracticesHttpService.getProgressInfo(props.record.id)
        setListOfProgress(data.progressList)
        setLastChatMessage(data.lastChatMessage);
    };

    useEffect(() => {
        loadData();
    }, []);

    const currentStep = listOfProgress.at(-1);

    const isStatusInProgress = (status:PracticeProgressStatus): boolean => Utils.isStatusInProgress(status, listOfProgress);
    const getProgress = (status:PracticeProgressStatus) => Utils.getProgressData(status, listOfProgress);

    const getDirect = () => {
        const docInviata = getProgress(PracticeProgressStatus.DOCUMENTAZIONE_INVIATA)
        const erroreInDoc = getProgress(PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE)

        const docInErrore = !isNil(erroreInDoc) && !isNil(docInviata) && erroreInDoc.completedAt > docInviata.completedAt

        return <Flex justify={'space-around'}>
            <Flex vertical align={"center"}>
                <Text>Contatti</Text>
                <div>
                    <IconState progressStatus={PracticeProgressStatus.CONTATTATO}
                               isChecked={isStatusInProgress(PracticeProgressStatus.CONTATTATO)}/>
                </div>
            </Flex>
            <Flex vertical align={"center"}>
                <Text>Appuntamento </Text>
                <div>
                    <IconState progressStatus={PracticeProgressStatus.PRESO_APPUNTAMENTO}
                               isChecked={isStatusInProgress(PracticeProgressStatus.PRESO_APPUNTAMENTO)}/>
                </div>
            </Flex>
            <Flex vertical align={"center"}>
                <Text>
                    Documentazione inviata
                </Text>
                <div>
                    <IconState progressStatus={PracticeProgressStatus.DOCUMENTAZIONE_INVIATA}
                               isChecked={isStatusInProgress(PracticeProgressStatus.DOCUMENTAZIONE_INVIATA)}
                               inError={docInErrore}/>
                </div>
            </Flex>
            <Flex vertical align={"center"}>
                <Text>
                    Autoriz. PIC
                </Text>
                <div>
                    <IconState progressStatus={PracticeProgressStatus.PIC_AUTORIZZATA}
                               isChecked={isStatusInProgress(PracticeProgressStatus.PIC_AUTORIZZATA)}/>
                </div>
            </Flex>

            <Flex vertical align={"center"}>
                <Text>Fattura inviata </Text>
                <div>
                    <IconState progressStatus={PracticeProgressStatus.FATTURA_INVIATA}
                               isChecked={isStatusInProgress(PracticeProgressStatus.FATTURA_INVIATA)}/>
                </div>
            </Flex>
        </Flex>;
    }

    const getIndirect = () => <Flex justify={'space-around'}>
        <Flex vertical align={"center"}>
            <Text>
                Contatti
            </Text>
            <div>
                <IconState progressStatus={PracticeProgressStatus.CONTATTATO}
                           isChecked={isStatusInProgress(PracticeProgressStatus.CONTATTATO)}/>
            </div>
        </Flex>
        <Flex vertical align={'center'}>
            <Text>
                Appuntamento
            </Text>
            <div>
                <IconState progressStatus={PracticeProgressStatus.PRESO_APPUNTAMENTO}
                           isChecked={isStatusInProgress(PracticeProgressStatus.PRESO_APPUNTAMENTO)}/>
            </div>
        </Flex>
        <Flex vertical align={'center'}>
            <Text>
                Acquisti
            </Text>
            <div>
                <IconState progressStatus={PracticeProgressStatus.ACQUISTO_EFFETTUATO}
                           isChecked={isStatusInProgress(PracticeProgressStatus.ACQUISTO_EFFETTUATO)}/>
            </div>
        </Flex>
    </Flex>;

    return <div className={"practices-expanded-info-container"}>
        {props.record.agreement === PracticeType.DIRECT ? getDirect() : getIndirect()}
        <Divider className={"divider"}/>
        <Flex justify={'space-around'} >
            <div>
                <Flex vertical={true}>
                    <Text strong>Ultima Modifica</Text>
                    {currentStep?.completedAt && <Text>{Utils.getDateItFormatFromTimestamp(currentStep?.completedAt * 1000)}</Text> }
                </Flex>
            </div>
            <div>
                <Flex vertical={true}>
                    <Text strong>Operatore</Text>
                    <Text ellipsis={true} className={"text-container"}>
                        {lastChatMessage?.operator}
                    </Text>
                </Flex>
            </div>
            <div>
                <Flex vertical={true}>
                    <Text strong>Chat contatti</Text>
                    <Text ellipsis={true} className={"text-container"}>{lastChatMessage?.text}</Text>
                </Flex>
            </div>
        </Flex>
    </div>
}
