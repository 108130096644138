import { DeleteFilled } from "@ant-design/icons";
import { useState } from "react";
import { CambioVisusFirmaModal } from "../CambioVisusFirmaModal";
import { Button } from "antd";

interface FirmaInputProps {
  id?: string;
  value?: string;
  onChange?: (value: string | undefined) => void;
}

export function FirmaInput(props: FirmaInputProps) {
  const { id, value = undefined, onChange } = props;

  const [firmaModalOpen, setFirmaModalOpen] = useState(false);

  const openFirmaModal = () => {
    setFirmaModalOpen(true);
  }

  const closeFirmaModal = () => {
    setFirmaModalOpen(false);
  }

  const putFirma = (base64Firma: string) => {
    onChange?.(base64Firma);
    setFirmaModalOpen(false);
  }

  const removeFirma = () => {
    onChange?.(undefined);
    setFirmaModalOpen(false);
  };

  let openFirmaButton;
  if (value != null) {
    openFirmaButton = <Button
      className="luxottica-button-success"
      onClick={openFirmaModal}
    >
      Firma
    </Button>
  } else {
    openFirmaButton = <Button
      type="primary"
      onClick={openFirmaModal}
    >
      Firma
    </Button>
  }

  return (
    <div id={id}>
      Firma dell'ottico/optometrista
      <br />
      {openFirmaButton}
      {value != null && <Button
                      type="primary"
                      shape="circle"
                      icon={<DeleteFilled />}
                      onClick={removeFirma}
                    />}
      <CambioVisusFirmaModal isOpen={firmaModalOpen} onComplete={putFirma} onCancel={closeFirmaModal} />
    </div>
  )
}