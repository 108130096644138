import {useParams} from "react-router-dom";
import {Flex} from "antd";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Paths} from "../../models/Paths";
import {DiscountCodeHeader} from "../../components/Info/DiscountCodeHeader/DiscountCodeHeader";
import {AgreementDetail} from "../../components/Info/AgreementDetail/AgreementDetail";
import "./Discount.css"
import {useEffect, useState} from "react";
import {Voucher, VoucherDetail} from "../../models/Info/voucher.model";
import {InformationService} from "../../services/InformationService";

export function Discount() {
    let {id} = useParams();
    const [voucherDetails, setVoucherDetails] = useState<Array<VoucherDetail>>([])
    const [voucher, setVoucher] = useState<Voucher>()

    useEffect(() => {
        if (!id ) return;
        InformationService.getVoucherDetailList(id)
            .then((res) => {
                setVoucherDetails(res.discounts);
                setVoucher(res)
            })

    }, [])

    const renderVoucherDetails = () => {
        const voucherDetailList = voucherDetails.map((voucherDetail) =>
            <li key={voucherDetail.id} className={"voucher-detail-list-element"}>
                <AgreementDetail agreementDetail={voucherDetail} />
            </li>
        )
        return <ul className={"voucher-detail-list"}>{voucherDetailList}</ul>
    }

    return (
        <>
            <Flex vertical >
                <PageTitle title={"Scontistica"} backTo={Paths.INFO} />
                <Flex vertical className={"discount-content"} gap={"middle"}>
                    { voucher !== null && <DiscountCodeHeader organizationId={voucher?.insuranceProviderId} discountCode={voucher?.code} /> }
                    {renderVoucherDetails()}
                </Flex>
            </Flex>
        </>
    )
}