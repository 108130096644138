import {Form, Input} from "antd";
export interface EmailFieldProps{
    required?:boolean
}
export function EmailField(props:EmailFieldProps){
    const rules:any=[{  required: props.required ?? false, message: 'Il campo email è obbligatorio' },{type: 'email'}]

    return <>
        <Form.Item name={"email"} label="E-mail" rules={rules}>
            <Input placeholder="E-mail"/>
        </Form.Item>
    </>
}