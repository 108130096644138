import {
    CalendarOutlined,
    EyeOutlined,
    FileExcelOutlined,
    FileOutlined,
    FilePptOutlined,
    FileSyncOutlined,
    PhoneOutlined
} from "@ant-design/icons";
import {PracticeProgressStatus} from "./Practice/Practice";

export function getIconFromProgress(progressStatus: PracticeProgressStatus) {
    switch (progressStatus) {
        case PracticeProgressStatus.CONTATTATO:
            return <PhoneOutlined/>
        case PracticeProgressStatus.PRESO_APPUNTAMENTO:
            return <CalendarOutlined/>
        case PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE:
        case PracticeProgressStatus.FATTURA_DA_INVIARE:
            return <FileSyncOutlined/>
        case PracticeProgressStatus.DOCUMENTAZIONE_INVIATA:
            return <FileOutlined/>;
        case PracticeProgressStatus.PIC_AUTORIZZATA:
            return <FilePptOutlined/>;
        case PracticeProgressStatus.ACQUISTO_EFFETTUATO:
            return <EyeOutlined/>;
        case PracticeProgressStatus.FATTURA_INVIATA:
            return <FileExcelOutlined/>;
        default:
            return <></>
    }
}