import {SquareTile} from "../../components/Home/SquareTile/SquareTile";
import React from "react";
import "./InfoMenu.css"
import {VideoCameraOutlined,BookOutlined} from "@ant-design/icons";
import {Flex} from "antd";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Paths} from "../../models/Paths";
import {useNavigate} from "react-router-dom";

export function InfoMenu (){
    const navigate = useNavigate();

    function goInfoPage() {
        navigate(Paths.INFO);
    }
    function goVideoPage() {
        navigate(Paths.VIDEO);
    }
    return <div className={"info-menu-container"}>
        <PageTitle title={"Info"} backTo={Paths.HOME}></PageTitle>

        <Flex justify={"space-around"} align={"center"} gap={"middle"} className={"flex-container"}>
            <div className={"info-menu-square-tile"}>
                <SquareTile title={"Info Convenzioni"} description={"Puoi consultare la documentazione e i manuali assicurativi"}
                            icon={<BookOutlined/>} onCLick={() => {
                    goInfoPage()
                }}></SquareTile>
            </div>
            <div className={"info-menu-square-tile"}>
                <SquareTile title={"Video Tutorial"} description={"Puoi consultare i video tutorial"}
                            icon={<VideoCameraOutlined/>} onCLick={() => {
                    goVideoPage()
                }}></SquareTile>
            </div>
        </Flex>

    </div>
}