import {Button, Flex, Typography} from "antd";
import {DiscountCode} from "../DiscountCode/DiscountCode";
import {InfoLogo} from "../../Common/InfoLogo/InfoLogo";
import {Paths} from "../../../models/Paths";
import {useNavigate} from "react-router-dom";



const { Text } = Typography;

export interface DiscountCodeHeaderProps {
    organizationId: number|undefined;
    discountCode: number|undefined;
    showManual?:boolean
    integrationCode?: string|null;
}

export function DiscountCodeHeader(props: DiscountCodeHeaderProps) {
    const navigate = useNavigate();

    const goToManual = () => {
        navigate(`${Paths.MANUAL}/${props.integrationCode}`)
    }

    const canShowManual = props.integrationCode != undefined && [
        'PREVIMEDICAL',
        'AON',
        'BLUEASSISTANCE',
        'INSALUTE',
        'CASPIE',
        'MARSH',
        'POSTE'
    ].includes(props.integrationCode);

    return (
        <>
            <Flex vertical={false} justify={"space-between"} align={"center"}>
                <Flex align={"left"} gap={"small"} vertical>
                    <InfoLogo organizationId={props.organizationId} width={200}></InfoLogo>
                    { (props.showManual && canShowManual) && <Button type={"primary"} onClick={goToManual}>Manuale</Button>}
                </Flex>
                { props.discountCode && <DiscountCode discountCode={props.discountCode}></DiscountCode> }
            </Flex>
        </>
    )
}