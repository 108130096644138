import {Flex, Typography} from "antd";
import "./AgreementDetail.css"
import {VoucherDetail} from "../../../models/Info/voucher.model";
// @ts-ignore
import DOMPurify from 'dompurify';

export interface AgreementDetailProps {
    agreementDetail: VoucherDetail
}

export function AgreementDetail(props: AgreementDetailProps) {
    const { Text } = Typography;

    const sanitizedTitle = DOMPurify.sanitize(props.agreementDetail.title);
    const sanitizedBody = DOMPurify.sanitize(props.agreementDetail.text);

    return (
        <>
            <Flex vertical className={"agreement-detail"}>
                <Text strong className={"agreement-detail-header"}>
                    <span dangerouslySetInnerHTML={{__html: sanitizedTitle}}></span>
                </Text>
                <Flex vertical className={"agreement-detail-body"}>
                    <div style={{textWrap: 'wrap'}} dangerouslySetInnerHTML={{__html: sanitizedBody}}/>
                </Flex>
            </Flex>
        </>
    )
}