import Title from "antd/es/typography/Title"

export interface SquareTileTitleProps{
    title :string
}
export function SquareTileTitle (props:SquareTileTitleProps){
    return <>
    <Title level={3}>
        {props.title}

    </Title>
    </>
}