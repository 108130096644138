import { createSlice } from '@reduxjs/toolkit';
import {QueryParameterModel} from "../../models/QueryParameter";

const defaultPageQueryParameters: QueryParameterModel[] = [{
    name:"pageSize",
    value:20
}, {
    name:"page",
    value:0
}, {
    name: "sortColumn",
    value: "creationDate"
}, {
    name: "sortDirection",
    value: "DESC"
}];

const initialState = {
    values: {},
    sortParameters: defaultPageQueryParameters
};

const FormState = createSlice({
    name: 'form',
    initialState,
    reducers: {
        saveForm: (state, action) => {
            state.values  = action.payload;
        },
        addToForm: (state, action) => {
            const key = action.payload.key;
            // FIXME
            // @ts-ignore
            state.values[key] = action.payload.value;
        },
        saveSortParameters: (state, action) => {
            state.sortParameters  = action.payload;
        },
        resetSortParameters: (state) => {
            state.sortParameters  = defaultPageQueryParameters;
        }
    },
});

export const { saveForm, addToForm, saveSortParameters, resetSortParameters} = FormState.actions;
export default FormState.reducer;