import {useState} from "react";
import {SelectionValue} from "../models/Common/SelectionValue";
import {FiltersHttpService} from "../services/Http/FiltersHttpService";

export function useAreas() {
    const [areas, setAreas] = useState<Array<SelectionValue>>([]);

    const loadAreas = async function(signCode?: string) {
        const response = await FiltersHttpService.getListOfAreas(signCode);
        setAreas(response.data);
    }

    return {
        areas,
        loadAreas
    }

}