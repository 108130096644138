import {SubmitFunction} from "../LoginForm";
import {Button, Form} from "antd";
import "../../../../styles/Buttons.css";
import LuxotticaPassword from "../LuxotticaPassword";
import {RuleRender} from "rc-field-form/lib/interface";

export interface NewPasswordFormProps {
    onSubmit: SubmitFunction<{newPassword: string}>;
}

const NewPasswordForm = (props: NewPasswordFormProps) => {
    const confirmPasswordRule: RuleRender = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('Le password che hai inserito non sono uguali!'));
        },
    });

    return (
        <Form key="newPasswordStep" onFinish={props.onSubmit} autoComplete="off">
            <LuxotticaPassword name="newPassword"
                               placeholder="Nuova Password"
                               followPasswordPolicy={true}
                               style={{width: '100%'}}/>
            <LuxotticaPassword name="newPasswordConfirm"
                               placeholder="Conferma Password"
                               followPasswordPolicy={true}
                               customRules={[confirmPasswordRule]}
                               style={{width: '100%'}}/>
            <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                <Button className={"luxottica-button-primary"} htmlType="submit">
                    Imposta la password e accedi
                </Button>
            </Form.Item>
        </Form>
    )
}

export default NewPasswordForm;
