import {InputNumber} from "antd";
import React from "react";

export interface InputFloatNumberProps {
    min?: number;
    onChange?: (value: number | null) => void;
}

export function InputFloatNumber(props: InputFloatNumberProps) {

    return <InputNumber onChange={props.onChange} min={props.min} inputMode="numeric" decimalSeparator={","} />
}