import {Button, Flex, Modal} from "antd";
import {ListOfOperator} from "../../Login/LoginForm/ListOfOperators/ListOfOperator";
import {isNil, set} from "lodash";
import {Employee} from "../../../models/Auth/Employee";
import {useState} from "react";
import {AuthService} from "../../../services/AuthService";
export interface SwitchUserModalProps{
    isOpen:boolean
    onOk:()=>void
    onCancel:()=>void
}
export function SwitchUserModal(props:SwitchUserModalProps){
    const [selectedOperator, setSelectedOperator] = useState<Employee|undefined>();

    function onSelectedOperator(option:any,value:Employee|undefined){
        if(isNil(value) === false) {
            setSelectedOperator(value);
            AuthService.saveOperator(value);
        }
    }

    function onOk() {
        setSelectedOperator(undefined);
        props.onOk();
    }

    function onCancel() {
        setSelectedOperator(undefined);
        props.onCancel();
    }

    return <>
        <Modal title="Seleziona operatore" open={props.isOpen}
                onCancel={onCancel}
               footer={(_, { OkBtn, CancelBtn }) => (
                   <>
                       <Button  onClick={onCancel} htmlType="reset">Chiudi</Button>
                   </>
               )}>
             <Flex gap={"middle"} justify={"center"} align={"center"}>
                <ListOfOperator onSelected={onSelectedOperator} id={"1"}></ListOfOperator>
                <Button onClick={onOk} disabled={isNil(selectedOperator) === true}>Seleziona</Button>
            </Flex>

        </Modal>
    </>
}