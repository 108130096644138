import {IconButton} from "../IconButton/IconButton";
import {FilterFilled} from "@ant-design/icons";
import {IconLuxotticaDimension} from "../../../models/IconLuxotticaDimension";
import {Button, Flex, Form, FormProps, Modal} from "antd";
import {useEffect, useRef} from "react";
import {FilterUser} from "./filters/FilterUser";
import {FilterConvection} from "./filters/FilterConvection";
import {FilterState} from "./filters/FilterState";
import {FilterRefundType} from "./filters/FilterRefundType";
import {FilterShop} from "./filters/FilterShop";
import {PracticeProgressStatus, PracticeSearchBody, PracticeType} from "../../../models/Practice/Practice";
import {AuthService} from "../../../services/AuthService";
import {FilterStatus} from "./filters/FilterStatus";
import {ClaimStatuses} from "../../../models/Practice/PracticeDetail";
import {useDispatch, useSelector} from "react-redux";
import {saveForm} from "../../../store/states/FormState";
import {FilterClaimCode} from "./filters/FilterClaimCode";
import {useShops} from "../../../utils/shops";
import {useModal} from "../../../utils/modals";

export interface PracticesTableFiltersProps {
    statusFilterShow?: boolean,
    manageFilterDisabled?: boolean,
    refundTypeFilterShow?: boolean,
    onCloseModal: (body: PracticeSearchBody) => void
    disabled?: boolean,
    disableInitLoadData?: boolean;
}

export interface PracticesTableFiltersForm {
    patientName?: string;
    insuranceProviderId?: number;
    state?: PracticeProgressStatus;
    refundType?: PracticeType;
    locationCode?: string;
    claimStatus?: ClaimStatuses;
    claimCode?: string;
}

export function PracticesTableFilters({
                                          statusFilterShow = true,
                                          refundTypeFilterShow = true,
                                          manageFilterDisabled = false,
                                          onCloseModal,
                                          disabled = false,
                                          disableInitLoadData = false
                                      }: PracticesTableFiltersProps) {
    const appUser = AuthService.getUser();
    const [form] = Form.useForm();

    const defaultLocation = appUser?.defaultLocation?.code || null

    const initialValues = {
        locationCode: defaultLocation,
        claimStatus: ClaimStatuses.OPEN
    }

    const {
        isOpen,
        open,
        close
    } = useModal();

    const formRef = useRef<any>();
    const formValues = useSelector((state: any) => state.practiceTableFilterForm.values);
    const dispatch = useDispatch();

    const {
        shops, loadShops
    } = useShops();

    useEffect(() => {
        loadShops();
    }, []);

    useEffect(() => {
        form.setFields([
            {
                name: "refundType",
                value: undefined,
            },
        ]);
    }, [manageFilterDisabled]);

    useEffect(() => {
        let values = Object.assign({}, initialValues, formValues);
        form.setFieldsValue(values);
        if (!disableInitLoadData) {
            onCloseModal(transformFormToBody(values))
        }
    }, [form]);

    const clean = () => {
        form.resetFields();
        dispatch(saveForm(form.getFieldsValue()));
    };

    function transformFormToBody(values: PracticesTableFiltersForm) {
        return {
            claimCode: values?.claimCode,
            agreement: values?.refundType,
            insuranceProviderId: values?.insuranceProviderId,
            patientName: values?.patientName,
            shop: values?.locationCode,
            status: values?.state ? [values.state] : null,
            creationDateRange: [null, null],
            claimStatus: values?.claimStatus
        };
    }

    const finish: FormProps<PracticesTableFiltersForm>['onFinish'] = (values: PracticesTableFiltersForm) => {
        dispatch(saveForm(values));
        let body: PracticeSearchBody = transformFormToBody(values);
        close();
        onCloseModal(body)
    };

    const apply = () => {
        formRef.current.submit()
    };

    // FIXME: sistemare modal con form dentro
    return <>
        <IconButton hide={disabled} icon={<FilterFilled/>} dimension={IconLuxotticaDimension.SMALL}
                    onCLick={open}></IconButton>
        <Modal open={isOpen}
               onCancel={close} okText={"Applica"} cancelText={"Pulisci"}
               width={"90%"}
               footer={(_, {OkBtn, CancelBtn}) => (
                   <>
                       <Button onClick={clean} htmlType="reset">Pulisci</Button>
                       <Button type={"primary"} htmlType="submit" onClick={apply}>Applica</Button>
                   </>
               )}
               modalRender={dom => (
                   <Form form={form}
                         onFinish={finish}
                         className={"form-filter"}
                         ref={formRef}
                   >{dom}</Form>
               )}
        >
            <Flex wrap="wrap" gap="large">
                <FilterUser></FilterUser>
                <FilterConvection></FilterConvection>
                {statusFilterShow && <FilterState></FilterState>}
                {refundTypeFilterShow && <FilterRefundType disabled={manageFilterDisabled}></FilterRefundType>}
                <FilterShop shops={shops} defaultValue={defaultLocation}></FilterShop>
                {statusFilterShow && <FilterStatus defaultValue={ClaimStatuses.OPEN}></FilterStatus>}
                {AuthService.isAdmin() && <FilterClaimCode></FilterClaimCode>}
            </Flex>
        </Modal>
    </>
}
