import {Flex, List, Typography} from "antd";
import {PracticeNote} from "../../models/Practice/PracticeNote";
import {UserOutlined} from "@ant-design/icons";
import {Utils} from "../../services/Utils";

export function NoteList(props: {
    data: Array<PracticeNote>
}) {
    return <div className={"note-container"}>
        <List
            itemLayout="horizontal"
            dataSource={props.data}
            renderItem={(item: PracticeNote, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<UserOutlined/>}
                        title={<Flex justify={"space-between"}>
                            <Typography.Text>{item.operator}</Typography.Text>
                            <Typography.Text>{Utils.getDatetimeItFormatFromTimestamp(item.insertDate * 1000)}</Typography.Text></Flex>}
                        description={<div style={{ whiteSpace: 'pre-line' }}>{item.text}</div>}
                    />
                </List.Item>
            )}
        />
    </div>
}