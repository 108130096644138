import {Button} from "antd";
import {useState} from "react";
import {UploadFilesModal} from "../../../../Common/UploadFileModal/UploadFilesModal";
import {AttachmentType, UploadAttachment} from "../../../../../models/Practice/PracticeFIle";
import {InsuranceProviderEclaim} from "../../../../../models/InsuranceProvider";
import {useClaimStatus, claimClosed} from "../../../ClaimStatusProvider";

export interface UploadDocumentsProps {
    onDocumentUpload: () => void;
    insuranceProvider: InsuranceProviderEclaim;
}

export function UploadDocuments (props: UploadDocumentsProps){
    const [isModalOpen,setIsModalOpen]=useState<boolean>(false);
    const claimStatus = useClaimStatus();
    const uploadAttachment : UploadAttachment ={
        filename: "foto.png",
        attachmentType:AttachmentType.OTHER
    }

    return <>
        <Button disabled={claimClosed(claimStatus)} type={"primary"} onClick={()=>{setIsModalOpen(true)}}>Aggiungi documento</Button>
        {
            isModalOpen &&
            <UploadFilesModal insuranceProvider={props.insuranceProvider} attachmentData={uploadAttachment} isModalOpen={isModalOpen} onClose={() => {
                props.onDocumentUpload();
                setIsModalOpen(false)
                }}></UploadFilesModal>
        }

    </>
}