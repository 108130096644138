import {Flex, Typography} from "antd";
import "./ShopDetail.css"
import DOMPurify from "dompurify";
import {isNil} from "lodash";

export interface ShopDetailProps{
    shopInfo:string|undefined
}

export function ShopDetail(props:ShopDetailProps) {
    const { Text } = Typography;
    function getSanitizedText(){
        if(isNil(props?.shopInfo) === false) {
            // @ts-ignore
            return DOMPurify.sanitize(props?.shopInfo);
        }
        return ""
    }
    return (
        <>
            <Flex vertical className={"shop-detail"}>
                <Text strong className={"shop-detail-header"}>In Negozio</Text>
                <Flex vertical className={"shop-detail-body"}>
                    <div dangerouslySetInnerHTML={{__html: getSanitizedText()}}/>

                </Flex>
            </Flex>
        </>
    )
}