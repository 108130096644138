import {PracticeType} from "../../../models/Practice/Practice";

export interface AgreementTypeProps{
    value:string
}
export function AgreementTypeLabel(props:AgreementTypeProps){

    function getTranslation(){
        if(props.value === PracticeType.DIRECT){
            return "diretta"
        }
        if (props.value === PracticeType.INDIRECT){
            return "indiretta"
        }
        return "diretta, indiretta";
    }
    return <>{getTranslation()}</>
}