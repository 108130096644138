import {Flex, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {PracticeType} from "../../models/Practice/Practice";
import {isNil} from "lodash";
import {TodoListStats} from "../../models/TodoList/TodoListStats";
import "./TodoListFilters.css"

export enum TodoFilterTiles {
    NONE = "NONE",
    TO_CONTACT = "TO_CONTACT",
    WAITING_PURCHASE= "WAITING_PURCHASE",
    WAITING_DOCS = "WAITING_DOCS",
    WAITING_AUTHORIZATION = "WAITING_AUTHORIZATION",
    INVOICE_TO_UPLOAD = "INVOICE_TO_UPLOAD",
    TO_BE_RECTIFIED = "TO_BE_RECTIFIED"
}

export interface NewTodoListFiltersProps {
    selectedTile: TodoFilterTiles;
    todoListStats: TodoListStats | undefined;
    onTileClick: (tileClicked: TodoFilterTiles) => void;
    currentRefundType?: PracticeType | null;
    canChangeRefundType: boolean;
    onRefundTypeClicked: (refundType: PracticeType) => void;
}

export function NewTodoListFilters(props: NewTodoListFiltersProps) {
    const onTileClicked = (value:TodoFilterTiles) => {
        props.onTileClick(value);
    };

    const changeRefundType = (refundType: PracticeType) => {
        if (props.canChangeRefundType)
            props.onRefundTypeClicked(refundType)
    }

    const refundTypeIndicatorClass = (refundType: PracticeType) => {
        let classes = ['refundTypeIndicator'];
        if (props.canChangeRefundType) {
            classes.push('clickable')
        }
        if (isNil(props.currentRefundType) || props.currentRefundType == refundType) {
            classes.push('active');
        }
        return classes.join(' ')
    }
    
    return <Flex vertical gap="middle" className={"todolist-filters"}>
        <Flex gap={16}>
            <div className={refundTypeIndicatorClass(PracticeType.DIRECT)}
                 onClick={() => changeRefundType(PracticeType.DIRECT)}>DIRETTE
            </div>
            <div className={refundTypeIndicatorClass(PracticeType.INDIRECT)}
                 onClick={() => changeRefundType(PracticeType.INDIRECT)}>INDIRETTE
            </div>
        </Flex>
        <TodoFilterTile tile={TodoFilterTiles.TO_CONTACT}
                        isSaved={TodoFilterTiles.TO_CONTACT == props.selectedTile}
                        count={props.todoListStats?.toContact ?? 0}
                        onClick={onTileClicked}/>
        <TodoFilterTile tile={TodoFilterTiles.WAITING_PURCHASE}
                        isSaved={TodoFilterTiles.WAITING_PURCHASE == props.selectedTile}
                  count={props.todoListStats?.waitingPurchase ?? 0}
                  onClick={onTileClicked} />
        <TodoFilterTile tile={TodoFilterTiles.WAITING_DOCS}
                  isSaved={TodoFilterTiles.WAITING_DOCS == props.selectedTile}
                  count={props.todoListStats?.waitingDocs ?? 0}
                  onClick={onTileClicked}/>
        <TodoFilterTile tile={TodoFilterTiles.WAITING_AUTHORIZATION}
                  isSaved={TodoFilterTiles.WAITING_AUTHORIZATION == props.selectedTile}
                  count={props.todoListStats?.waitingAuthorization ?? 0}
                  onClick={onTileClicked}/>
        <TodoFilterTile tile={TodoFilterTiles.INVOICE_TO_UPLOAD}
                  isSaved={TodoFilterTiles.INVOICE_TO_UPLOAD == props.selectedTile}
                  count={props.todoListStats?.invoiceToUpload ?? 0}
                  onClick={onTileClicked}/>
        <TodoFilterTile tile={TodoFilterTiles.TO_BE_RECTIFIED}
                  isSaved={TodoFilterTiles.TO_BE_RECTIFIED == props.selectedTile}
                  count={props.todoListStats?.toBeRectifed ?? 0}
                  onClick={onTileClicked}/>
    </Flex>
}

function TodoFilterTile(props: {
    tile: TodoFilterTiles;
    isSaved: boolean
    count: number
    onClick: (tile: TodoFilterTiles) => void;
}) {
    const { t, i18n } = useTranslation();
    const { Text } = Typography;

    const classname = 'tile' + (props.isSaved ? ' tile-selected': '');

    const onClick = () => {
        props.onClick(props.tile);
    }

    return <>
        <Flex className={classname} align={"center"} justify={"space-between"} onClick={onClick}>
            <Text className={"text"}>{ t(props.tile) }</Text>
            <Text className={"number"}>{ props.count }</Text>
        </Flex>
    </>
}