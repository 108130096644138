import {Button} from "antd";
import {useState} from "react";
import "./ButtonCheckBox.css"

export interface ButtonCheckBoxProps{
    text?:string;
    onClick?:(selected:boolean)=>void
    selected?:boolean
    readonly?:boolean
}

export function ButtonCheckBox(props:ButtonCheckBoxProps = {readonly: false}){
    const [isSelected, setIsSelected]= useState<Boolean>(props?.selected ?? false)

    function onClick (){
        if(props?.onClick){
            props.onClick(!isSelected)
        }
        setIsSelected(!isSelected);
    }

    const className = [];
    if (props.readonly) {
        className.push("button-readonly");
    }
    if (isSelected) {
        className.push("button-checkbox-selected");
    }

    if (props.readonly) {
        return <>
            <div className={className.join(' ')}>{props.text}</div>
        </>
    } else {
        return <>
            <Button className={className.join(' ')} onClick={onClick}>
                {props.text}
            </Button>
        </>
    }
}
