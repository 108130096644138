import {Flex, Typography} from "antd";
import "./PageTitle.css"
import {BackButton} from "../BackButton/BackButton";

export interface PageTitleProps{
    title:string;
    backTo?: string;
    children?: React.ReactNode;
    onClick?:()=>void
    historyBack?:boolean
}
export function PageTitle(props:PageTitleProps){
    const { Text, Link } = Typography;

    return <>
       <Flex className={"page-title-container"}>
           <Flex justify={"flex-start"} align={"center"} gap={"small"}>
               <BackButton navigateTo={props.backTo ?? ""} historyBack onClick={props.onClick}/>
               <Text className={"title"} strong>{props.title}</Text>
           </Flex>
{/*
           {props.children}
*/}

       </Flex>

   </>
}