import {Form, Select, SelectProps} from "antd";
import "./Filters.css"
import {useGetInsuranceProvidersQuery} from "../../../../store/states/InsuranceProvidersState";
import {InsuranceProviderEclaim} from "../../../../models/InsuranceProvider";

export interface FilterConvectionProps{
    onChange?:(value:number)=>void;
}
export function FilterConvection (props:FilterConvectionProps){
    const {data: insuranceProviders, error, isLoading} = useGetInsuranceProvidersQuery([]);
    const options :any[]|undefined = getOptions();
    const handleChange = (value: string) => {
        if(props?.onChange) {
            props?.onChange(Number(value))
        }
    };

    function getOptions():any[]|undefined{
        const ipDiretta = ['PREVIMEDICAL', 'AON', 'BLUEASSISTANCE', 'CASPIE', 'GENERALI','POSTE', 'POSTEWELFARE', 'INSALUTE', 'MARSH']

        const ips = insuranceProviders || [];

        const gestioneDiretta = ips.filter((ip:InsuranceProviderEclaim) => {
            return ipDiretta.includes(ip.integrationCode);
        }).sort((a:InsuranceProviderEclaim,b:InsuranceProviderEclaim) => {
            return a.description.localeCompare(b.description)
        });

        const gestioneIndiretta = ips.filter((ip:InsuranceProviderEclaim) => {
            return !ipDiretta.includes(ip.integrationCode);
        }).sort((a:InsuranceProviderEclaim,b:InsuranceProviderEclaim) => {
            return a.description.localeCompare(b.description)
        })

        return [...gestioneDiretta, ...gestioneIndiretta].map((element:InsuranceProviderEclaim)=>{
            return { label:element.description, value:element.id }
        });
    }
    return <>
        <Form.Item name={"insuranceProviderId"}>
            <Select
                placeholder="Convenzione"
                className={"filter"}
                options={options}
                onChange={handleChange}
                showSearch
                optionFilterProp="label"
                allowClear={true}
            />
        </Form.Item>

    </>
}
