import {HttpService} from "./HttpService";
import {AxiosResponse} from "axios";
import {SelectionValue} from "../../models/Common/SelectionValue";

export class FiltersHttpService {
    static URL_SHOPS = "/shops";
    static URL_AREAS = "/areas";

    static getListOfShops(areaCode?: string): Promise<AxiosResponse<SelectionValue[], any>>{
        return HttpService.getLuxotticaAxios().get<SelectionValue[]>(this.URL_SHOPS, {params: {area: areaCode}});
    }

    static getListOfAreas(groupCode?: string): Promise<AxiosResponse<SelectionValue[], any>> {
        const url = this.URL_AREAS + '/' + groupCode;
        return HttpService.getLuxotticaAxios().get<SelectionValue[]>(url);
    }

}
