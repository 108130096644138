import { Button, Flex, Form, Input } from "antd";
import { resetPassword } from "aws-amplify/auth";

export interface AskUsernameProps {
  goNext: (username: string) => void
}

const AskUsername = (props: AskUsernameProps) => {
  const [form] = Form.useForm();

  const onSubmit = async (data: {username: string}) => {
    await resetPassword({username: data.username});
    props.goNext(data.username);
  }

  const skipCodeRequest = async () => {
    try {
      await form.validateFields()
      const username = form.getFieldValue('username');
      props.goNext(username);
    } catch(_) {}
  }

  return (
    <Form key="recoverPassword-askUsername" form={form} onFinish={onSubmit} autoComplete="off">
      <Flex gap="middle" align="center" justify="center" vertical>
        <Form.Item name="username" rules={[{ required: true, message: 'Inserire username' }]} style={{width: '100%'}}>
          <Input placeholder="Username"/>
        </Form.Item>
        <Form.Item>
          <Button className={"luxottica-button-primary"} htmlType="submit">
            Richiedi codice
          </Button>
        </Form.Item>
        <p>
          Se hai già il codice di recupero, inserisci l'username e clicca <Button type="link" onClick={skipCodeRequest}>qui</Button>
        </p>
      </Flex>
    </Form>
  )
}

export default AskUsername;