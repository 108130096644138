import { Form, Input } from "antd";
import { PatientAutocomplete } from "../../../../models/Patient/PatientAutocomplete";

import "./Filters.css"

export interface FilterUserProps{
    onChange?:(value:string)=>void
    className?:React.HTMLAttributes<HTMLDivElement>['className'];
    onSelected?:(value:any, patient:PatientAutocomplete)=>void
    onDeselect?:(value:any)=>void
    onClear?:()=>void
    required?:boolean
    showLabel?:boolean
}

export interface OperatorAutocompleteOptions {
    key: number;
    value: string|number;
    label?:string

}

export function FilterUser(props:FilterUserProps){
    const rules=[{ required: props.required ?? false, message: 'Il campo paziente è obbligatorio' }]

    function onChange(value:any){
        if(props?.onChange){
            props?.onChange(value.target.value)
        }
    }

    const className = "filter " + (props?.className ?? "");

    return  <>
        <Form.Item name={"patientName"}
                   label={props.showLabel && "Paziente"}
                   rules={rules}
        >
            <Input className={className} placeholder="Cognome Nome" onChange={onChange} allowClear={true}/>
        </Form.Item>

    </>

}