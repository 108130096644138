import {Button, Flex, Modal, Radio, RadioChangeEvent, Typography} from "antd";
import {useEffect, useState} from "react";
import {ClosingReasonPractice} from "../../../models/Practice/PracticeDetail";
import {PracticeType} from "../../../models/Practice/Practice";
export interface ClosePracticesDialogProps{
    isModalOpen:boolean;
    handleOk:(value: ClosingReasonPractice|undefined)=>void;
    handleCancel:()=>void;
    manage:PracticeType
}
export function ClosePracticesDialog(props:ClosePracticesDialogProps){
    const [value, setValue] = useState<ClosingReasonPractice>();
    const { Text } = Typography;

    useEffect(()=>{
        if(props.manage === PracticeType.INDIRECT ){
            setValue(ClosingReasonPractice.NOT_CONTACTABLE_NOT_INTERESTED);
        }

    },[])

    function getFooter():React.ReactNode {
        return [
            <Button type={"default"} key="back" onClick={onCancel}>
            Annulla
          </Button>,
          <Button key="submit" type="primary" disabled={!value} onClick={()=>props.handleOk(value)}>
            Conferma
          </Button>,
        ]
    }

    function onChange(e: RadioChangeEvent)  {
        setValue(e.target.value);
    };
    function getDirectUi(){
        return <>
            <Radio value={ClosingReasonPractice.NOT_CONTACTABLE_NOT_INTERESTED}>Non contattabile / Non interessato</Radio>
            <Radio value={ClosingReasonPractice.PIC_AUTHORIZED_NOT_PURCHASED}>PIC autorizzata - Non acquistato</Radio>
            <Radio value={ClosingReasonPractice.PIC_NOT_AUTHORIZED_NOT_PURCHASED}>PIC non autorizzata - Rifiuto acquisto</Radio>
            <Radio value={ClosingReasonPractice.PIC_NOT_AUTHORIZED_PURCHASED}>PIC non autorizzata - Acquisto comunque / altro</Radio>

        </>
    }
    function getIndirectUi(){
        return <>
            <Radio value={ClosingReasonPractice.NOT_CONTACTABLE_NOT_INTERESTED} >Non contattabile / Non interessato</Radio>
        </>
    }

    function onCancel() {
        setValue(undefined);
        props.handleCancel();
    }

    return <>
      <Modal  open={props.isModalOpen}
              footer={getFooter()}
              onCancel={onCancel}
              className="close-practices-dialog">
          <Text strong>La pratica sta per essere chiusa. Selezionare la causale</Text>
          <Radio.Group onChange={onChange} value={value}>
              <Flex vertical gap={"small"}>
                  { props.manage === PracticeType.INDIRECT && getIndirectUi()}
                  { props.manage === PracticeType.DIRECT && getDirectUi()}
              </Flex>
          </Radio.Group>
      </Modal>
    </>
}
