import {IconLuxotticaDimension} from "../../../models/IconLuxotticaDimension";
import {IconLuxottica} from "../IconLuxottica/IconLuxottica";
import {ReactElement} from "react";
import "./IconCheckBox.css"

export interface IconCheckBoxProps {
    icon: ReactElement<any, any>;
    checked: boolean;
    inError?: boolean;
}

export function IconCheckBox({icon, checked, inError = false}: IconCheckBoxProps) {

    const getSelectedStyle = () => {
        if (inError) return "icon-checkbox-error"
        return checked ? "icon-checkbox-selected" : ""
    };

    return <>
        <IconLuxottica icon={icon}
                       dimension={IconLuxotticaDimension.SMALL}
                       className={getSelectedStyle()}></IconLuxottica>
    </>
}