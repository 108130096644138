import {Form, Input} from "antd";
import {Utils} from "../../../services/Utils";

export interface SurnameField{
    required?:boolean
}
export function SurnameField(props:SurnameField){
    const rules = [{required: props.required ?? false, message: 'Il campo cognome è obbligatorio'}]

    return <>
        <Form.Item name={"patientLastName"}
                   label={"Cognome"}
                   rules={rules}
        >
            <Input placeholder="Cognome" allowClear={true}
                   onInput={(e:any) => e.target.value = Utils.capitalizeFirstLetter(e.target.value)}/>

        </Form.Item>
    </>
}