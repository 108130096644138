import {Button, Flex, Input, Space, Tag} from "antd";
import {SendOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {isNil, trim} from "lodash";
import {PracticeMessageState} from "../../../models/Practice/PracticeMessage";
import {PracticeProgress} from "../../../models/Practice/PracticeDetail";
import {PracticeProgressStatus} from "../../../models/Practice/Practice";
import {useTranslation} from "react-i18next";
import './MessageTextBox.css'
import {Utils} from "../../../services/Utils";
import {claimClosed, useClaimStatus} from "../../PracticesDetails/ClaimStatusProvider";

export interface MessageTextBoxProps{
    onClickButton:(from:string, messageText:string, state:PracticeMessageState|undefined)=>void
    showState?:boolean
    showSendFrom?:boolean;
    disable?:boolean;
    isMandatoryStatus?:boolean
    onClickLabel?:(labelType:PracticeMessageState)=>void;
    progress?: PracticeProgress[]
}
export function MessageTextBox(props:MessageTextBoxProps){
    const claimStatus = useClaimStatus();
    const { t } = useTranslation();
    const [from,setFrom] = useState<string>("");
    const [messageText,setMessageText] = useState<string>("");
    const [isDisableButton,setIsDisableButton] = useState<boolean>((props?.isMandatoryStatus || claimClosed(claimStatus)) ? true : false);
    const [state,setState] = useState<PracticeMessageState|undefined>(PracticeMessageState.STATELESS);

    function onChangeFrom(element:any){
        setFrom(element.target.value);
        disableButton(messageText,element.target.value,state);
    }

    function onChangeMessageText(element:any){
        setMessageText(element.target.value);
        disableButton(element.target.value,from,state);
    }

    function onClickButton(){
        props.onClickButton(from,messageText,state);
        setMessageText("");
        setFrom("")
        setState(undefined)
        disableButton("","",undefined);

    }

    function disableButton(text:string,from:string, state:PracticeMessageState|undefined){
        if(props.isMandatoryStatus){
            if( trim(text) === "" || (props.showSendFrom === true && trim(from) === "") || (isNil(state) && props.showState) || props.disable){
                setIsDisableButton(true);
            } else {
                setIsDisableButton(false);
            }
        }

    }
    function getSelectedStateColor(isDisabled: boolean, elem : PracticeMessageState){
        if (isDisabled) {
            return "gray";
        }

        if (elem === state ){
            return "green"
        }
        return ""
    }

    function getStateLabels() {
        return Object.keys(PracticeMessageState).map((key:string)=> {
            // FIXME
            //@ts-ignore
            const practiceProgressStatus = PracticeProgressStatus[key];
            if (isNil(practiceProgressStatus)) {
                return;
            }
            const isDisabled = Utils.isStatusInProgress(practiceProgressStatus, props.progress);
            const className = "state-label"+ (isDisabled ? " state-label-disabled" : "")

            if(key !== "STATELESS"){
                let elem : PracticeMessageState = PracticeMessageState[key as keyof typeof PracticeMessageState];
                return <Tag className={className}
                            color={getSelectedStateColor(isDisabled, elem)}
                            onClick={()=>{
                                if (isDisabled) return;

                                onClickState(elem);
                            }}>
                    {t(key)}
                </Tag>
            }
            return;
        })
    }
    function onClickState(elem : PracticeMessageState ){
        if(elem === state){
            setState(PracticeMessageState.STATELESS)
        } else {
            setState(elem);
        }

        disableButton(messageText,from,elem);

        if(props?.onClickLabel){
            props?.onClickLabel(elem);
        }
    }
    return <>
        <Space direction="vertical" >
            <Flex justify={'space-between'}>
                {props.showState === true && props?.disable === false && getStateLabels()}
            </Flex>

            { props.showSendFrom === true && <Input placeholder="Inviato da" className={"width-100"} onChange={onChangeFrom}/>}
            <Space.Compact className={"width-100"}>
                <Input.TextArea placeholder="Digita il testo" value={messageText} onChange={onChangeMessageText}
                                disabled={props.disable || claimClosed(claimStatus)}
                                autoSize={{ minRows: 3, maxRows: 3 }}
                                style={{ resize: 'none' }}
                />
                <Button style={{ height: 'auto' }} icon={<SendOutlined />} onClick={onClickButton} disabled={isDisableButton}/>
            </Space.Compact>
        </Space>

    </>
}