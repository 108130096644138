import {Form, Select} from "antd";
import {ClaimStatuses} from "../../../../models/Practice/PracticeDetail";
import {useTranslation} from "react-i18next";

export interface FilterStatusProps {
    defaultValue?: ClaimStatuses
}

export function FilterStatus(props:FilterStatusProps) {
    const { t, i18n } = useTranslation();

    const options = Object.keys(ClaimStatuses).map((element:string)=>{
        return { value: element, label: t(element) }
    })

    return <>
        <Form.Item name={"claimStatus"} initialValue={props.defaultValue}>
            <Select
                placeholder="Stato"
                className={"filter"}
                options={options}
                allowClear={true}
            />
        </Form.Item>
    </>
}