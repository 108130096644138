import React from "react";
import {Typography} from "antd";

export interface CambioVisusInfoProps {
    shopGroup: string;
}

const {Paragraph} = Typography;

export function CambioVisusInfo(props: CambioVisusInfoProps) {
    if (props.shopGroup == "SV") {
        return <Paragraph italic>
            La rilevazione del visus effettuata presso i negozi Salmoiraghi &
            Viganò e le informazioni contenute nel presente documento non devono
            essere considerate come sostitutive di apposita visita oculistica e
            non forniscono indicazioni in merito alla propria salute oculare. A
            tal proposito Salmoiraghi & Viganò S.p.A. declina da ogni
            responsabilità in tal senso. Salmoiraghi & Viganò invita inoltre i
            propri clienti ad effettuare periodiche visite oculistiche per la
            propria salute oculare.
        </Paragraph>
    } else {
        return <Paragraph italic>
            La rilevazione del visus effettuata presso i negozi Grand Vision
            e le informazioni contenute nel presente documento non devono
            essere considerate come sostitutive di apposita visita oculistica e
            non forniscono indicazioni in merito alla propria salute oculare. A
            tal proposito Grand Vision. declina da ogni
            responsabilità in tal senso. Grand Vision invita inoltre i
            propri clienti ad effettuare periodiche visite oculistiche per la
            propria salute oculare.
        </Paragraph>
    }
}