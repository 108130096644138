import {Paths} from "../../models/Paths";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Alert} from "antd";
import "./NewPractice.css"
import {PracticesHttpService} from "../../services/Http/PracticesHttpService";
import {PracticeToSave} from "../../models/Practice/PracticeToSave";
import {useState} from "react";
import {isNil} from "lodash";
import {useTranslation} from "react-i18next";
import {AuthService} from "../../services/AuthService";
import {AppUser} from "../../models/Auth/AppUser";
import {useNavigate} from "react-router-dom";
import {NewPracticeForm, NewPracticeFormBody} from "../../components/NewPractice/NewPracticeForm";
import {NotificationService} from "../../services/NotificationService";

export function NewPractice() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [appUser, setAppUser] = useState<AppUser | null>(AuthService.getUser());

    const allowedInsuranceProvidersForCreation = ['BLUEASSISTANCE', 'CASPIE', 'INSALUTE', "POSTE", "POSTEWELFARE","GENERALI"];

    const initialValues = {
        locationCode: appUser?.defaultLocation?.code || null
    }

    const savePractice = async (data: NewPracticeFormBody) => {
        const body: PracticeToSave = Object.assign({}, data);
        if(isNil(appUser?.defaultLocation) === false && appUser?.defaultLocation?.code){
            body.locationCode = appUser?.defaultLocation?.code;
        }

        try {
            const response = await PracticesHttpService.savePractice(body)
            const newClaimId = response.data;
            navigate(`/practices/details/${newClaimId}`)
        } catch (err) {
            console.error("error while creating new claim", err);
            NotificationService.getInstance().openNotificationError("Impossibile completare l'operazione")
        }
    }

    return <>
        <PageTitle title={"Nuova Pratica"} backTo={Paths.HOME}></PageTitle>
        <Alert message={t("checkThePractices")} type="warning" />

        <NewPracticeForm initialValues={initialValues}
                         onSubmit={savePractice}
                         allowedInsuranceProviders={allowedInsuranceProvidersForCreation}/>
    </>
}
