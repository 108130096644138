import {useEffect, useState} from "react";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {InsuranceProviderSearchDropdown} from "../../components/Info/SearchBar/InsuranceProviderSearchDropdown";
import {Flex} from "antd";
import {Paths} from "../../models/Paths";
import {AgreementRow} from "../../components/Info/AgreementRow/AgreementRow";
import {Voucher, VoucherBodyFilter} from "../../models/Info/voucher.model";
import {InformationService} from "../../services/InformationService";
import "./Info.css";
import {SpinLuxottica} from "../../components/Common/SpinLuxottica/SpinLuxottica";
import {EmptyLuxottica} from "../../components/Common/Empty/EmptyLuxottica";
import {usePage} from "../../customHooks/UsePage";
import {QueryParameterModel} from "../../models/QueryParameter";
import {isNil} from "lodash";
import {LogService} from "../../services/LogService";

export function Info() {
    const [vouchers, setVouchers] = useState<Array<Voucher>>([])
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [bodyFilters,setBodyFilters] = useState<VoucherBodyFilter>({
        insuranceProviderId:null
    })

    const {
        page,
        lastPage,
        updateValues,
        incrementPage,
        getPageQueryParameters,
        getDefaultPageQueryParameters,
        resetPagination
    } = usePage({ page:0, size:20, lastPage:1, allItemsNumber:0 }, 'id', 'ASC');

    useEffect(() => {
        loadData(getPageQueryParameters());
    }, []);

    const loadData = async (
        queryParameters:QueryParameterModel[],
        bodyFilters?:VoucherBodyFilter,
        isFilterChange:boolean=false
    ) =>{
        setIsLoading(true);
        try {
            let res;
            if (isNil(bodyFilters)) {
                res = await InformationService.getVoucherList(queryParameters)
            } else {
                res = await InformationService.getVoucherListPost(queryParameters,bodyFilters ?? undefined);
            }
            updateValues(res.currentPage, res.allItemsNumber, res.totalPages)
            if(isFilterChange === false ){
                setVouchers(vouchers.concat(res.items));
            } else {
                setVouchers(res.items);
            }
        } finally {
            setIsLoading(false)
        }
    }

    const renderVouchers = () => {
        const voucherList = vouchers.map((voucher) =>
            <li key={voucher.id}>
                <AgreementRow
                    id={voucher.id}
                    insuranceProviderId={voucher.insuranceProviderId}
                    agreement={voucher.agreement}
                    visus={voucher.visus}
                    refundCondition={voucher.refundConditions}
                    code={voucher.code}//TODO REFACTORING
                    locationGroupCode={voucher.locationGroupCode}
                />
            </li>
        );
        return <ul className={"voucher-list"}  onScroll={handleScroll}>{voucherList}</ul>
    }

    const handleScroll = (e:any) => {
        const scrollHeight = e.target.scrollHeight;
        const scrollTop = Math.round(e.target.scrollTop);
        const clientHeight = e.target.clientHeight;
        const bottom = scrollHeight - scrollTop === clientHeight;
        LogService.info("isBottom", bottom, scrollHeight, scrollTop, scrollHeight - scrollTop, clientHeight)
        if(bottom){
            if(page !== (lastPage-1) ){
                let queryParameters:QueryParameterModel[] = incrementPage();
                loadData(queryParameters,bodyFilters)
            }
        }
    }

    function onChange(element:number){
        bodyFilters.insuranceProviderId = element;
        setBodyFilters(bodyFilters);
        resetPagination();
        loadData(getDefaultPageQueryParameters(), bodyFilters, true);
    }
    return <>
        <Flex vertical >
            <SpinLuxottica loading={isLoading}>
                <Flex align={"center"} justify={"space-between"}>
                    <PageTitle title={"Info convenzioni"} backTo={Paths.HOME}>
                    </PageTitle>
                    <InsuranceProviderSearchDropdown onChange={(element:number)=>{
                        onChange(element);
                    }}></InsuranceProviderSearchDropdown>
                </Flex>


                {vouchers && vouchers.length > 0 ?
                    renderVouchers()
                    : <EmptyLuxottica></EmptyLuxottica>
                }

            </SpinLuxottica>

        </Flex>


    </>
}