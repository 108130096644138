import {useParams} from "react-router-dom";
import {Flex} from "antd";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Paths} from "../../models/Paths";
import {DiscountCodeHeader} from "../../components/Info/DiscountCodeHeader/DiscountCodeHeader";
import "./ShopActivities.css";
import {ShopDetail} from "../../components/Info/ShopDetail/ShopDetail";
import {useEffect, useState} from "react";
import {Voucher} from "../../models/Info/voucher.model";
import {InformationService} from "../../services/InformationService";
import {useGetInsuranceProvidersQuery} from "../../store/states/InsuranceProvidersState";
import {InsuranceProviderEclaim} from "../../models/InsuranceProvider";
import {PracticeType} from "../../models/Practice/Practice";

export function ShopActivities() {
    let {id} = useParams();
    const [voucher, setVoucher] = useState<Voucher>()
    const {data: insuranceProviders, error, isLoading} = useGetInsuranceProvidersQuery([]);

    useEffect(() => {
        if (!id ) return;
        InformationService.getVoucherDetailList(id)
            .then((res) => {
                setVoucher(res);
            })
    },[])

    function getInsuranceProviderIntegrationCode(insuranceProviderId: number) {
        if (insuranceProviders == undefined) return null;

        const insuranceProvider = (insuranceProviders as Array<InsuranceProviderEclaim>).find(ip => ip.id == insuranceProviderId);
        if (insuranceProvider)
            return insuranceProvider.integrationCode;

        return null;
    }
    function showManual(){
        if(voucher?.agreement === PracticeType.INDIRECT ){
            return false
        }
        return true;
    }
    return (
        <>

            <Flex vertical >
                <PageTitle title={"Attività Negozio"} backTo={Paths.INFO} />
                <Flex vertical className={"shop-activities-content"} gap={"middle"}>
                    { voucher !== undefined && <DiscountCodeHeader organizationId={voucher.insuranceProviderId} discountCode={voucher.code} showManual={showManual()} integrationCode={getInsuranceProviderIntegrationCode(voucher.insuranceProviderId)} /> }
                    <ShopDetail shopInfo={voucher?.shopInfo}/>
                </Flex>
            </Flex>
        </>
    )
}