import { createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {EnvService} from "../../services/EnvService";
import {AuthService} from "../../services/AuthService";
import {isNil} from "lodash";

export const InsuranceProvidersApi = createApi({
    reducerPath: 'api',
/*    baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
    endpoints: (builder) => ({
        getInsuranceProviders: builder.query({
            query: () => 'insuranceProviders',
        }),
    }),    */
    baseQuery: fetchBaseQuery({
        baseUrl: EnvService.getBackEndUrl(),
        prepareHeaders: (headers) => {
            const authToken = AuthService.getUserTokens();
            if (isNil(authToken) === false )
            {
                headers.set('Authorization', `Bearer ${authToken}`);
            }

            return headers;
        }
    }),
    endpoints: (builder) => ({
        getInsuranceProviders: builder.query({
            query: () => 'insuranceProviders',
        }),
    }),
})

export const { useGetInsuranceProvidersQuery } = InsuranceProvidersApi
