import {Route, Routes} from "react-router-dom";
import {Paths} from "../models/Paths";
import {BaseLayout} from "../layout/BaseLayout/BaseLayout";
import {Home} from "../pages/Home/Home";
import {Info} from "../pages/Info/Info";
import {Login} from "../pages/Login/Login";
import {Discount} from "../pages/Discount/Discount";
import {ShopActivities} from "../pages/ShopActivities/ShopActivities";
import {Practices} from "../pages/Practices/Practices";
import {PracticesDetails} from "../pages/PracticesDetails/PracticesDetails";
import {DashBoardPage} from "../pages/DashBoard/DashBoardPage";
import {TodoList} from "../pages/TodoList/TodoList";
import {NewPractice} from "../pages/NewPractice/NewPractice";
import {Manual} from "../components/Info/Manual/Manual";
import {InfoMenu} from "../pages/InfoMenu/InfoMenu";
import {VideoPage} from "../pages/VideoPage/VideoPage";
import {NewPage} from "../pages/NewPage";
import {NewDocsPage} from "../pages/NewDocsPage";

export function AppRouting() {

    return (
            <Routes>
                <Route element={<BaseLayout />}>
                    <Route path={Paths.HOME} element={<Home />} />
                    <Route path={Paths.INFO} element={<Info />} />
                    <Route path={Paths.INFOMENU} element={<InfoMenu />} />
                    <Route path={Paths.PRACTICES} element={<Practices />}></Route>
                    <Route path={Paths.PRACTICES_DETAILS + '/:id' } element={<PracticesDetails/>}></Route>
                    <Route path={Paths.DISCOUNT + '/:id/:discountCode'} element={<Discount />} />
                    <Route path={Paths.SHOP_ACTIVITIES + '/:id'} element={<ShopActivities />} />
                    <Route path={Paths.MANUAL+ '/:integrationCode'} element={<Manual />}></Route>

                    <Route path={Paths.DASHBOARD} element={<DashBoardPage/>}></Route>
                    <Route path={Paths.VIDEO} element={<VideoPage/>}></Route>
                    <Route path={Paths.TODO} element={<TodoList/>}></Route>
                    <Route path={Paths.NEW} element={<NewPage/>}></Route>
                    <Route path={Paths.NEW_PRACTICES} element={<NewPractice/>}></Route>
                    <Route path={Paths.NEW_DOCS} element={<NewDocsPage />}></Route>
                </Route>
                <Route path={Paths.LOGIN} element={<Login />} />
            </Routes>
    );
}
