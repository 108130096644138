import {HttpService} from "./HttpService";

export class UserHttpService {
    static GET_USER_AVATAR_URL = "/user/avatar/";
    static getUserAvatar(userId:string|undefined){
        return HttpService.getLuxotticaAxios().get(this.GET_USER_AVATAR_URL + userId);
    }

    static getUserProfile() {
        return HttpService.getLuxotticaAxios().get('/profiles');
    }
}
