import {Form, Select} from "antd";
import {InsuranceProviderEclaim} from "../../../models/InsuranceProvider";

export interface EntityFieldsProps {
    className?: React.HTMLAttributes<HTMLDivElement>['className'];
    required?: boolean
    insuranceProviders: Array<InsuranceProviderEclaim>;
}

export function EntityFields (props: EntityFieldsProps) {
    const rules=[{ required: props.required ?? false, message: 'Il campo ente è obbligatorio' }]

    const options = props.insuranceProviders.map((element:InsuranceProviderEclaim) => {
        return { value: element.id, label: element.description }
    }).sort((a, b) => a.label.localeCompare(b.label));

    const getClassName = () => props?.className ?? "";

    return <>
        <Form.Item name={"insuranceProviderId"} label ={"Convenzione"} rules={rules}>
            <Select
                className={getClassName()}
                placeholder="Ente"
                options={options}
            />
        </Form.Item>
    </>
}