import {PracticeProgressStatus, PracticeType} from "./Practice";
import {Patient} from "../Patient";
import {InsuranceProviderEclaim} from "../InsuranceProvider";
import {PracticeMessage} from "./PracticeMessage";
import {UploadCambioVisusRequest} from "../cambioVisus";

export interface PracticeDetail{
    //diretta
    id:number,
    picNumber:number,
    picStatus:string
    patient: Patient,
    insuranceProvider: InsuranceProviderEclaim,
    code:string,
    voucherDate:string,
    status:PracticeProgressStatus
    claimStatus: ClaimStatuses
    agreement: PracticeType,

    //progress: PracticeProgressStatus,//todo modifica questo
    progress: PracticeProgress[],//todo modifica questo
    //indiretta
    addVoucher: string,
    dateLastModification: string,
    operator: string,
    dataDownloadVoucher:string,
    changeVisus:string,
    refundCondition:string,
    shopGroup: string;
    creationDate: number;
    shopName: string;
}

export enum ClaimStatuses {
    OPEN="OPEN",
    CLOSED="CLOSED"
}

export interface PracticeProgress{
    id: number,
    claimId:number,
    completedAt:number,
    name: PracticeProgressStatus,
    //current: boolean,
    metadata: any
}
export interface ProceedNextRequest {
    event:PracticeProgressStatus|number|string
    metadata:any
}

export interface PracticeDetailRow extends PracticeDetail{
    "key":string
}

export enum ClosingReasonPractice{
    NOT_CONTACTABLE_NOT_INTERESTED="Non contattabile / Non interessato",
    NOT_CONTACTABLE="Non contattabile",
    PIC_AUTHORIZED_NOT_PURCHASED ="PIC autorizzata - Non acquistato",
    PIC_NOT_AUTHORIZED_NOT_PURCHASED ="PIC non autorizzata - Rifiuto acquistato",
    PIC_NOT_AUTHORIZED_PURCHASED ="PIC non autorizzata - Acquisto comunque / altro",
    EMPTY = ''
}

export interface ClosingPracticeBody {
    adminExecution?:boolean
    reason : ClosingReasonPractice,
}

export interface ClaimProgressInfoResponse {
    progressList: PracticeProgress[];
    lastChatMessage: PracticeMessage;
}

export interface UploadEstimateBodyRequest {
    orders: Array<OrderData>
    note: string;
}

export interface OrderData {
    code: string;
    label: string;
    quantity: number;
    amount: number;
    amountRaw: number | undefined;
}

export interface BaseShelveRequest {
    insuranceProviderId: number;
    refundType: PracticeType;
    locationCode: string;
    patientCF: string;
    patientFirstName: string;
    patientLastName: string;
    email?: string;
    phonenumber: string;
}

export interface ShelveEstimateBodyRequest extends UploadEstimateBodyRequest, BaseShelveRequest {}

export interface ShelveCambioVisusBodyRequest extends UploadCambioVisusRequest, BaseShelveRequest {}