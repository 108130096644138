import {Empty, Typography} from "antd";

export function EmptyLuxottica (){
    const { Text } = Typography;

    return <><Empty description={
        <Text strong>
            Nessun dato trovato
      </Text>
    }
    /></>
}