import {Button, Flex, Typography} from "antd";
import {CambioVisusModal} from "../../../../CambioVisus/CambioVisusModal/CambioVisusModal";
import {Patient} from "../../../../../models/Patient";
import {useState} from "react";
import {AttachmentType, PracticeFileRow, UploadAttachment} from "../../../../../models/Practice/PracticeFIle";
import {PracticeProgressStatus, PracticeType} from "../../../../../models/Practice/Practice";
import {InsuranceProviderEclaim} from "../../../../../models/InsuranceProvider";
import {
    PracticeProgress,
    ProceedNextRequest,
    UploadEstimateBodyRequest
} from "../../../../../models/Practice/PracticeDetail";
import {UploadFilesModal} from "../../../../Common/UploadFileModal/UploadFilesModal";
import {useModal} from "../../../../../utils/modals";
import {AuthService} from "../../../../../services/AuthService";
import {PracticesHttpService} from "../../../../../services/Http/PracticesHttpService";
import {NotificationService} from "../../../../../services/NotificationService";
import {useParams} from "react-router-dom";
import {PreventiveDialogTrigger} from "../Preventive/PreventiveDialogTrigger";
import {isNil} from "lodash";
import {useClaimStatus, claimClosed} from "../../../ClaimStatusProvider";

export interface UploadFilesWarrantyActivationProps{
    patient?:Patient,
    locationCode: string;
    shopGroup: string,
    insuranceProvider:InsuranceProviderEclaim,
    agreement:PracticeType,
    convention:string,
    onClickConfirmDocs:()=>void;
    files: Array<PracticeFileRow>;
    loadFiles:()=>void;
    progress: PracticeProgress[] | undefined,
    status: PracticeProgressStatus
    reloadDetail: () => Promise<void>
}
export function UploadFilesWarrantyActivation(props:UploadFilesWarrantyActivationProps){
    const { Text} = Typography;
    const [dataToSend, setDataToSend] = useState<UploadAttachment>();
    const { id } = useParams();

    const {
        isOpen: isUploadVoucherModalOpen,
        open: openUploadVoucherModal,
        close: closeUploadVoucherModal
    } = useModal()

    const estimatedLoaded = props.files.find(file => file.type == AttachmentType.ESTIMATE) != undefined;
    const cambioVisusLoaded = props.files.find(file => file.type == AttachmentType.CAMBIO_VISUS) != undefined;
    const voucherLoaded = props.files.find(file => file.type == AttachmentType.VOUCHER) != undefined;

    const claimStatus = useClaimStatus();

    const uploadVoucher = () => {
        if (voucherLoaded) return;
        const dataToSend = {
            filename: "codice_alfanumerico.png",
            attachmentType: AttachmentType.VOUCHER
        }
        setDataToSend(dataToSend);
        openUploadVoucherModal();
    }

    const onClose = () => {
        closeUploadVoucherModal()
        props.loadFiles();
    }

    const cambioVisusRequired = !["POSTEWELFARE"].includes(props.insuranceProvider.integrationCode);
    const voucherRequired = ["POSTEWELFARE"].includes(props.insuranceProvider.integrationCode);

    const shouldConfirm = [
        PracticeProgressStatus.DA_CONTATTARE,
        PracticeProgressStatus.CONTATTATO,
        PracticeProgressStatus.PRESO_APPUNTAMENTO,
        PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE
    ].includes(props.status)

    const canConfirm = (
        estimatedLoaded &&
        (!cambioVisusRequired || cambioVisusLoaded) &&
        (!voucherRequired || voucherLoaded)
    )

    const onDocConfimed = async () => {
        let operator = AuthService.getOperatorOnline();
        const body: ProceedNextRequest = { metadata: {operator: operator!.name }, event: PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE}
        try {
            await PracticesHttpService.saveProceedNext(id ?? "", body);
            await props.reloadDetail();
        } catch (_) {
            NotificationService.getInstance().openNotificationError("Operazione non riuscita")
        }
    }

    const onPreventiveSubmit = async (body: UploadEstimateBodyRequest) => {
        if (isNil(id)) return;
        try {
            const response = await PracticesHttpService.uploadEstimate(id, body);
            const compressed = response.data ? response.data.compressed : false;
            const message = compressed ? "Preventivo compresso correttamente e salvato" : "Preventivo salvato";
            NotificationService.getInstance().openNotificationSuccess(message);
        } catch (error:any) {
            console.log(error);
            let message = "Impossibile salvare il preventivo";
            if (error.response.status === 400) {
                const data = error.response.data;
                message = data.message;
            }
            NotificationService.getInstance().openNotificationError(message);
        } finally {
            props.loadFiles();
        }
    }

    const disableButtons = (status: string, useAonClause:boolean, documentLoaded:boolean)=> {
        // disabilita il pulsante se la pratica è chiusa oppure usa clusola aon (se prevjsta) oppure (non è caricato il file relativo e la pratica è in stato PIC non autorizzata)
        const disableButtonForAon = props.insuranceProvider.code === 'AON';
        return claimClosed(claimStatus) || (useAonClause && disableButtonForAon) || (!documentLoaded && props.status === PracticeProgressStatus.PIC_NON_AUTORIZZATA);
    }

    return <>
        <Text strong>Documenti obbligatori per attivazione garanzia</Text>
        <Flex gap={"small"} className={"margin-y-10px"}>
            <PreventiveDialogTrigger disabled={disableButtons(claimStatus, true, estimatedLoaded)}
                                     isLoaded={estimatedLoaded}
                                     onSubmit={onPreventiveSubmit}
                                     agreement={props.agreement}
                                     integrationCode={props.insuranceProvider.integrationCode}
                                     insuranceProviderId={props.insuranceProvider.id} locationCode={props.locationCode}/>

            { cambioVisusRequired && <CambioVisusModal disabled={disableButtons(claimStatus, true, cambioVisusLoaded)} shopGroup={props.shopGroup} isLoaded={cambioVisusLoaded} loadFiles={props.loadFiles} patient={props.patient} insuranceProvider={props.insuranceProvider} ></CambioVisusModal>}
            { voucherRequired && <Button disabled={disableButtons(claimStatus, false, voucherLoaded)} type={"primary"} className={voucherLoaded ? "luxottica-button-success" : ""} onClick={uploadVoucher}>Codice alfanumerico</Button>}
            { dataToSend && <UploadFilesModal attachmentData={dataToSend} isModalOpen={isUploadVoucherModalOpen} insuranceProvider={props.insuranceProvider} onClose={onClose}></UploadFilesModal> }
            { shouldConfirm && <Button disabled={claimClosed(claimStatus) || !canConfirm} type={"primary"} onClick={onDocConfimed}>Conferma doc.</Button> }
        </Flex></>
}