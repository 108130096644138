import {FilterFilled} from "@ant-design/icons";
import {IconLuxotticaDimension} from "../../../models/IconLuxotticaDimension";
import {useModal} from "../../../utils/modals";
import {IconButton} from "../IconButton/IconButton";
import {Flex, Form, Modal} from "antd";
import React, {useEffect} from "react";
import {FilterUser} from "./filters/FilterUser";
import {PracticeProgressStatus, PracticeType} from "../../../models/Practice/Practice";
import {ClaimStatuses} from "../../../models/Practice/PracticeDetail";
import {FilterConvection} from "./filters/FilterConvection";
import {FilterState} from "./filters/FilterState";
import {FilterRefundType} from "./filters/FilterRefundType";
import {FilterShop} from "./filters/FilterShop";
import {FilterStatus} from "./filters/FilterStatus";
import {FilterClaimCode} from "./filters/FilterClaimCode";
import {useShops} from "../../../utils/shops";

export interface PracticesTableFiltersForm {
    patientName?: string | null;
    insuranceProviderId?: number | null;
    state?: PracticeProgressStatus | null;
    refundType?: PracticeType | null;
    locationCode?: string | null;
    claimStatus?: ClaimStatuses;
    claimCode?: string | null;
}

export interface NewPracticesTableFiltersProps {
    hide?: boolean;
    showStatusFilter?: boolean;
    showRefundTypeFilter?: boolean;
    disableRefundTypeFilter?: boolean;
    showClaimCode?: boolean;
    initialValues: PracticesTableFiltersForm;
    defaultValues: PracticesTableFiltersForm;
    onCloseModal: (body: PracticesTableFiltersForm) => void
}

export function NewPracticesTableFilters({
                                             hide = false,
                                             showStatusFilter = true,
                                             showRefundTypeFilter = true,
                                             disableRefundTypeFilter = false,
                                             showClaimCode = false,
                                             onCloseModal,
                                             initialValues,
                                             defaultValues
                                         }: NewPracticesTableFiltersProps) {

    const defaultLocation = undefined;

    const [form] = Form.useForm();

    const {
        isOpen,
        open,
        close
    } = useModal();

    const {
        shops, loadShops
    } = useShops();

    useEffect(() => {
        loadShops();
    }, []);

    const onFinish = (data: PracticesTableFiltersForm) => {
        onCloseModal(data);
        close();
    }

    const modalRender = (dom: any) => {
        return <Form form={form}
                     name="practiceTableFiltersDialog"
                     className={"form-filter"}
                     onFinish={onFinish}
                     initialValues={initialValues}
        >{dom}</Form>
    }

    const clear = () => {
        Object.entries(defaultValues)
            .forEach(([key, value]) => {
                form.setFieldValue(key, value);
            })
        onFinish(defaultValues);
    }

    return <>
        <IconButton hide={hide} icon={<FilterFilled/>}
                    dimension={IconLuxotticaDimension.SMALL} onCLick={open}/>
        <Modal open={isOpen} onCancel={close} width={'90%'}
               okText="Applica"
               cancelText="Pulisci"
               okButtonProps={{loading: false, autoFocus: true, htmlType: 'submit'}}
               destroyOnClose
               cancelButtonProps={{onClick: clear}}
               modalRender={modalRender}
        >
            <Flex wrap="wrap" gap="large">
                <FilterUser></FilterUser>
                <FilterConvection></FilterConvection>
                {showStatusFilter && <FilterState></FilterState>}
                {showRefundTypeFilter && <FilterRefundType disabled={disableRefundTypeFilter}></FilterRefundType>}
                <FilterShop shops={shops} defaultValue={defaultLocation}></FilterShop>
                {showStatusFilter && <FilterStatus defaultValue={ClaimStatuses.OPEN}></FilterStatus>}
                {showClaimCode && <FilterClaimCode></FilterClaimCode>}
            </Flex>
        </Modal>
    </>
}
