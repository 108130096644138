import {useState} from "react";

export function useModal(initialState: boolean = false) {
    const [isOpen, setState] = useState<boolean>(initialState);

    return {
        isOpen,
        toggle: () => {setState(!isOpen)},
        open: () => {setState(true)},
        close: () => {setState(false)}
    };
}