import {HttpService} from "./Http/HttpService";
import {Voucher, VoucherBodyFilter, VoucherResponse} from "../models/Info/voucher.model";
import {Utils} from "./Utils";
import {PracticeType} from "../models/Practice/Practice";
import {isNil} from "lodash";
import {QueryParameterModel} from "../models/QueryParameter";
import {QueryParamsService} from "./Http/QueryParamsService";

export class InformationService{
    static GET_VOUCHERS = "/vouchers";
    static GET_VOUCHERS_DETAIL_LIST = "/vouchers/:id";
    static GET_VOUCHERS_DETAILS = "/vouchers/find/:shopGroup/:id/:agreement";

    static async getVoucherList(queryParameters:QueryParameterModel[]){
        let url:string = QueryParamsService.getUrl(queryParameters);
        const {data} = await HttpService.getLuxotticaAxios().get<VoucherResponse>(this.GET_VOUCHERS+url);
        return data;
    }
    static async getVoucherListPost(queryParameters:QueryParameterModel[],body:VoucherBodyFilter|undefined){
        let url:string = QueryParamsService.getUrl(queryParameters);
        const {data} = await HttpService.getLuxotticaAxios().post<VoucherResponse>(this.GET_VOUCHERS+url,body);
        //const {data} = await HttpService.getLuxotticaAxios().get<VoucherResponse>(this.GET_VOUCHERS+url);
        return data;
    }
    static async getVoucherDetailList(id: string):Promise<Voucher>{
        let url = Utils.replaceId(this.GET_VOUCHERS_DETAIL_LIST,id);
        const {data} = await HttpService.getLuxotticaAxios().get<Voucher>(url);
        return data;
    }
    static getVoucherDetails(shopGroup: string, insuranceProviderId:number, agreement:PracticeType){
        let url = Utils.replaceId(this.GET_VOUCHERS_DETAILS,insuranceProviderId.toString());
        if(isNil(url) === false){
            url = url.replace(":agreement", agreement);
        }
        url = url.replace(":shopGroup", shopGroup);
        return HttpService.getLuxotticaAxios().get<Voucher>(url);
    }
}
