import {useState} from "react";
import {SelectionValue} from "../models/Common/SelectionValue";
import {FiltersHttpService} from "../services/Http/FiltersHttpService";

export function useShops() {
    const [shops, setShops] = useState<Array<SelectionValue>>([]);

    const loadShops = async function(areaCode?: string) {
        const response = await FiltersHttpService.getListOfShops(areaCode);
        setShops(response.data);
    }

    return {
        shops,
        loadShops
    }
}