import previmedical from "../../assets/providers/1.png";
import blueAssistance from "../../assets/providers/2.png";
import aon from "../../assets/providers/aon.png"
import caspie from "../../assets/providers/caspie-logo.png"
import marsh from "../../assets/providers/marsh.png"
import insalute from "../../assets/providers/insalute.png"
import poste from "../../assets/providers/posteassicura.png"
import postewelfare from "../../assets/providers/postewelfare.png"
import generali from "../../assets/providers/logo-generali.png"

import {InsuranceProviderIntegrationCodes} from "../../models/InsuranceProvider";

export function getLogoFromIntegrationCode(integrationCode: string) {
    switch (integrationCode) {
        case InsuranceProviderIntegrationCodes.PREVIMEDICAL:
            return previmedical;
        case InsuranceProviderIntegrationCodes.BLUEASSISTANCE:
            return blueAssistance;
        case InsuranceProviderIntegrationCodes.AON:
            return aon;
        case InsuranceProviderIntegrationCodes.CASPIE:
            return caspie;
        case InsuranceProviderIntegrationCodes.MARSH:
            return marsh;
        case InsuranceProviderIntegrationCodes.INSALUTE:
            return insalute;
        case InsuranceProviderIntegrationCodes.GRUPPOGENERALI:
        case InsuranceProviderIntegrationCodes.GENERALI:
            return generali;
        case InsuranceProviderIntegrationCodes.POSTE:
            return poste;
        case InsuranceProviderIntegrationCodes.POSTEWELFARE:
            return postewelfare;
        default:
            return "";
    }
}
