import {Button, Flex, Form, Tag, Typography} from "antd";
import {FilterShop} from "../Common/PracticesTableFilters/filters/FilterShop";
import {SurnameField} from "./Fields/SurnameField";
import {NameField} from "./Fields/NameField";
import {EntityFields} from "./Fields/EntityFields";
import {FiscalCodeField} from "./Fields/FiscalCodeField";
import {PhoneNumber} from "./Fields/PhoneNumber";
import {EmailField} from "./Fields/EmailField";
import {useShops} from "../../utils/shops";
import {useEffect} from "react";
import {NotificationService} from "../../services/NotificationService";
import {useGetInsuranceProvidersQuery} from "../../store/states/InsuranceProvidersState";
import {InsuranceProviderEclaim} from "../../models/InsuranceProvider";

const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => {
    const { Text } = Typography;

    return <>
        {required ? <Tag color="error">Obbligatorio</Tag> : <Tag color="warning">Facoltativo</Tag>}

        <Text strong >{label}</Text>
    </>
};

export interface NewPracticeFormBody {
    insuranceProviderId?: number;
    locationCode?: string | null;
    patientCF?: string;
    patientFirstName?: string;
    patientLastName?: string;
    email?: string;
    phonenumber?: string;
}

export interface NewPracticeFormProps {
    initialValues: NewPracticeFormBody;
    onSubmit: (values: NewPracticeFormBody) => void;
    allowedInsuranceProviders: string[];
}

export function NewPracticeForm(props: NewPracticeFormProps) {
    const [form] = Form.useForm();

    const {data: insuranceProviders, error, isLoading} = useGetInsuranceProvidersQuery([]);

    const actualInsuranceProviders = insuranceProviders?.filter((element:InsuranceProviderEclaim) => {
        return props.allowedInsuranceProviders.includes(element.integrationCode)
    }) ?? [];

    const {
        shops, loadShops
    } = useShops();

    useEffect(() => {
        loadShops();
    }, []);

    const fieldClassName = "field-new-practice"

    const onKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const onFinish = (data: NewPracticeFormBody) => {
        props.onSubmit(data);
    }

    const onFinishFailed = (ignored: any) => {
        NotificationService.getInstance().openNotificationError("Impossibile completare l'operazione");
    };

    return <>
        <Form className={"new-practice-form"}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              requiredMark={customizeRequiredMark}
              layout="vertical"
              initialValues={props.initialValues}
              onKeyDown={onKeyDown}
        >
            <Flex wrap="wrap" gap="small">
                <div className={fieldClassName}>
                    <FilterShop shops={shops} required={true} showLabel={true}></FilterShop>
                </div>
                <div className={fieldClassName}>
                    <SurnameField required={true}></SurnameField>
                </div>
                <div className={fieldClassName}>
                    <NameField required={true}></NameField>
                </div>

                <div className={fieldClassName}>
                    <EntityFields required={true} insuranceProviders={actualInsuranceProviders}></EntityFields>
                </div>

                <div className={fieldClassName}>
                    <FiscalCodeField required={true}></FiscalCodeField>
                </div>
                <div className={fieldClassName}>
                    <PhoneNumber required={true}></PhoneNumber>
                </div>
                <div className={fieldClassName}>
                    <EmailField required={true}></EmailField>
                </div>


            </Flex>
            <Flex gap={"middle"} justify={"flex-end"} className={"buttons"}>
                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button htmlType="reset">
                        Pulisci
                    </Button>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">
                        Salva e accedi
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    </>
}
