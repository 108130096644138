import {Form, Input} from "antd";
import {Utils} from "../../../services/Utils";

export interface NameField {
    required?: boolean
}

export function NameField(props: NameField) {
    const rules = [{required: props.required ?? false, message: 'Il campo nome è obbligatorio'}]

    return <>
        <Form.Item name={"patientFirstName"}
                   label={"Nome"}
                   rules={rules}
        >
            <Input placeholder="Nome" allowClear={true}
                   onInput={(e:any) => e.target.value = Utils.capitalizeFirstLetter(e.target.value)}/>

        </Form.Item>
    </>

}