import {ClockCircleOutlined, FileFilled, InfoOutlined, PieChartOutlined, PlusOutlined} from "@ant-design/icons";


export const IconHome ={
    DASHBOARD:<PieChartOutlined/>,
    INFO:<InfoOutlined/>,
    TODO_LIST:<ClockCircleOutlined/>,
    ADD_PRACTICES:<PlusOutlined/>,
    PRACTICES:<FileFilled/>,

}