import {Flex} from "antd";
import {useCallback, useEffect} from "react";
import {PieChartLegend} from "./PieChartLegend";
import ReactECharts from 'echarts-for-react';

import "./PieChart.css"
import {Dashboard, DashboardIdSeries} from "../../../models/Dashboard/Dashboard";
export interface PieChartProps{
    data:Dashboard|undefined;
    onCLickSeries:(id:any)=>void
}
export function PieChart (props:PieChartProps){
    const option = getOptions();
    const onReactEChartsEvents: Record<string, Function> = {
        click: (params: any) => {props.onCLickSeries(params)},

    };

    useEffect(() => {

    }, []);

    function getOptions(){
        return {
            aria: {
                enabled: true,
                decal: {
                    show: true,
                    //decals: [{ symbol: "circle", symbolSize: 1 }, { symbol: "rect", symbolSize: 1 }]
                }
            },
            legend: {
                orient: 'vertical',
                left: 0,
                top: 'center'

            },
            series: [
                {
                    name: 'dashboardPie',
                    type: 'pie',
                    center: ['60%', '50%'],
                    radius: '80%',
                    data: [
                        { id:DashboardIdSeries.IN_PROGRESS, value: props?.data?.inProgress ?? 0, name: 'In lavorazione' },
                        { id:DashboardIdSeries.TO_BE_CONTACTED, value: props?.data?.toBeContacted ?? 0, name: 'Da contattare' },
                        { id:DashboardIdSeries.CLOSE_WITHOUT_SALE,value: props?.data?.closedWithoutSale ?? 0, name: 'Chiuse senza vendita' },
                        { id:DashboardIdSeries.PURCHASE_MADE,value: props?.data?.purchaseMade ?? 0, name: 'Acquisto effettuato' },
                        { id:DashboardIdSeries.EXPIRED,value: props?.data?.expired ?? 0, name: 'Scadute' },
                    ],
                    label: {
                        show: true,
                        formatter: '{b}: {c} ({d}%)' // {b} per il nome, {d} per la percentuale
                    }
                }
            ]
        };
    }


    return <>
        <Flex vertical>
            <div><PieChartLegend data={props.data}></PieChartLegend></div>
            <div id="main" className={"pie-chart"} >
                <ReactECharts option={option} onEvents={onReactEChartsEvents} />

            </div>

        </Flex>
    </>
}