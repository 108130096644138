import {Form, Select, SelectProps} from "antd";
import React from "react";
import "./AddPerformance.css"
import {useParams} from "react-router-dom";
import { NamePath } from "antd/lib/form/interface";

export interface AddPerformance{
    performanceOptions:SelectProps['options']
    name: NamePath,
}

export function AddPerformance(props:AddPerformance){
    const { id } = useParams();


    return <>
        <Form.Item name={props.name}
                   rules={[{ required: true, message: 'Selezionare prestazione!' }]}
        >
            <Select
                showSearch
                optionFilterProp="label"
                placeholder="Seleziona prestazione"
                className={"add-performance-select"}
                options={props.performanceOptions}
            />
        </Form.Item>
     </>
}