import {Button, Flex, Modal} from "antd";
import {useState} from "react";
import {NewVisusModal} from "../NewVisusModal/NewVisusModal";
import {Patient} from "../../../models/Patient";
import {UploadFilesModal} from "../../Common/UploadFileModal/UploadFilesModal";
import {AttachmentType} from "../../../models/Practice/PracticeFIle";
import {InsuranceProviderEclaim} from "../../../models/InsuranceProvider";
import {CambioVisusFormData, UploadCambioVisusRequest} from "../../../models/cambioVisus";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {useParams} from "react-router-dom";

export interface CambioVisusModalProps{
    patient?:Patient,
    insuranceProvider:InsuranceProviderEclaim,
    isLoaded: boolean;
    loadFiles:()=>void;
    shopGroup: string;
    disabled:boolean
}
export function CambioVisusModal(props:CambioVisusModalProps) {
    let { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showNewVisus, setShowNewVisus]= useState <boolean>(false)
    const [showUploadFiles, setShowUploadFiles]= useState <boolean>(false)

    const showModal = () => {
        if (props.disabled || props.isLoaded) {
            return;
        }
        if (props.insuranceProvider.code === 'GRUPPOGENERALI') {
            setShowNewVisus(true);
        } else {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setShowNewVisus(false);
    };

    const handleCancel = () => {
        setShowNewVisus(false);
        setShowUploadFiles(false);
        props.loadFiles()
        setTimeout(() => {
            setIsModalOpen(false);
        }, 200)
    };

    const newVisus = () => {
        setIsModalOpen(false);
        setShowNewVisus(true);
    };

    const onClickOpenUpload = () => {
        setIsModalOpen(false);
        setShowUploadFiles(true);
    };

    const onSubmit = async (data: CambioVisusFormData) => {
        const body: UploadCambioVisusRequest = {
            insuranceProviderId: props.insuranceProvider.id,
            data: data
        }
        await PracticesHttpService.saveClaimChangeVisus(body, id);
    }

    return <>
        <Button disabled={props.disabled} type={"primary"} className={props.isLoaded ? "luxottica-button-success" : ""} onClick={showModal}>Cambio visus/Ricetta Oculistica</Button>
        <Modal title="Cambio visus" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Flex justify={"space-around"} >
                <Button type={"primary"} onClick={newVisus}>Crea nuovo</Button>
                <Button type={"primary"} onClick={onClickOpenUpload}>Carica</Button>
            </Flex>
        </Modal>
        {
            showNewVisus &&
            <NewVisusModal shopGroup={props.shopGroup} isModalOpen={showNewVisus} onSubmit={onSubmit} onCancel={handleCancel} patient={props.patient} insuranceProvider={props.insuranceProvider} ></NewVisusModal>
        }
        {
            <UploadFilesModal attachmentData={{
                filename:"cambio_visus.png",
                attachmentType:AttachmentType.CAMBIO_VISUS
            }} isModalOpen={showUploadFiles} onClose={handleCancel} insuranceProvider={props.insuranceProvider}></UploadFilesModal>
        }
    </>
}
