import {SorterResult} from "antd/es/table/interface";
import {QueryParameterModel} from "../models/QueryParameter";
import {isArray, isNil} from "lodash";

export class SortTableLuxottica{

    static getQueryParams(info: SorterResult<any> | Array<SorterResult<any>>): Array<QueryParameterModel> {
        let returnValue:QueryParameterModel[]=[];
        if(isArray(info)){
            info.forEach(i => {
                returnValue.push(...this.calculateSortQueryParams(i))
            })
        } else {
            returnValue.push(...this.calculateSortQueryParams(info))
        }
        return returnValue;
    }

    private static calculateSortQueryParams(sortInfo: SorterResult<any>) {
        return [
            {
                name: 'sortColumn',
                value: sortInfo.columnKey
            },
            {
                name: 'sortDirection',
                value: sortInfo.order == "descend" ? "DESC" : "ASC"
            }
        ]
    }
}