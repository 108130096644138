import ButtonGroup from "antd/es/button/button-group";
import {Button} from "antd";
import {MinusOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {useState} from "react";

export interface ZoomPdfProps{
    initialZoom: number;
    onClickZoom:(zoomValue:number)=>void
}
export function ZoomPdf(props:ZoomPdfProps){
    const STEP = 0.5
    const MAX_ZOOM = 10;

    const[zoomLevel,setZoomLevel] = useState<number>(props.initialZoom);
    function zoomLevelPlus(){
        if(zoomLevel < MAX_ZOOM){
            setZoomLevel(zoomLevel + STEP);
            props.onClickZoom(zoomLevel + STEP)
        }

    }
    function zoomLevelMinus(){
        if((zoomLevel - STEP) !== 0 ){
            setZoomLevel(zoomLevel - STEP);
            props.onClickZoom(zoomLevel - STEP)
        }
    }
    function isMinZoomLevel(){
        return (zoomLevel - STEP) === 0;

    }
    function isMaxZoomLevel(){
        return zoomLevel >= MAX_ZOOM;

    }
    return <>
        <ButtonGroup>
            <Button type={"primary"} onClick={zoomLevelMinus} disabled={isMinZoomLevel()}><MinusOutlined /></Button>
            <Button type={"primary"} >
                <SearchOutlined />
            </Button>
            <Button type={"primary"} onClick={zoomLevelPlus} disabled={isMaxZoomLevel()}><PlusOutlined /></Button>
        </ButtonGroup>
    </>
}