import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {Content, Header} from "antd/es/layout/layout";
import {Flex, Image, Layout, Menu, MenuProps, Typography} from "antd";
import React, {useState} from "react";
import {HomeFilled} from '@ant-design/icons'
import {Logo} from "./Logo/Logo";
import "./BaseLayout.css"
import {UserMenu} from "./UserMenu/UserMenu";
import {AuthService} from "../../services/AuthService";
import {AppUser} from "../../models/Auth/AppUser";
import {SideBar} from "../../components/SideBar/SideBar";
import {EnvService} from "../../services/EnvService";
import {Employee} from "../../models/Auth/Employee";
import {Paths} from "../../models/Paths";
import logoManydesigns from "../../assets/logo-manydesigns.svg";

interface RequiresAuthProps {
    user: AppUser | null;
    children: JSX.Element;
    operator: Employee | null;
}

const RequiresAuth = ({user, children, operator}: RequiresAuthProps) => {
    if (EnvService.authModeIsMock() === false) {
        if (!user || !operator) {
            AuthService.clearSession();
            return <Navigate to="/login" replace/>;
        }
    }

    return children;
};

export function BaseLayout() {
    const navigate = useNavigate();
    const {Text} = Typography;

    const isDev = process.env.NODE_ENV == "development";

    const items: MenuProps['items'] = [
        {
            label: (<HomeFilled style={{fontSize: '150%'}}/>),
            key: 'menu',
            onClick: () => {
                navigate(Paths.HOME)
            }
        },
    ]

    const user = AuthService.getUser();
    const [operator,setOperator] = useState<Employee | null> (AuthService.getOperatorOnline());

    function reloadOperator(){
        setOperator(AuthService.getOperatorOnline());
        navigate(Paths.HOME);
    }

    const headerClassName = "nav-bar" + (isDev ? " nav-bar-dev" : "")

    return (
        <RequiresAuth user={user} operator={operator}>
            <div className={"base-layout"}>
                <Layout>
                    <Header className={headerClassName}>
                        <Flex justify={"space-between"} align={"center"}>
                            <Menu
                                mode="horizontal"
                                items={items}
                                className={"menu-luxottica"}
                            />
                            <Logo></Logo>
                            <UserMenu reloadOperator={reloadOperator} username={operator?.name}></UserMenu>
                        </Flex>
                    </Header>
                    <Layout className={"main-container"}>
                        <SideBar></SideBar>
                        <Content className={"content"}>
                            <Flex vertical justify={"space-between"}  className={"flexbox-container"}>
                                <div className={"page-body"}><Outlet/></div>
                                <div className={"footer"}>
                                    <Flex align={"center"}  >
                                        <Text type="secondary" className={"text"}>Powered by Personal eClaim</Text>
                                        <Image src={logoManydesigns} preview={false} height={25}></Image>
                                    </Flex>

                                </div>
                            </Flex>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        </RequiresAuth>
    )
}
