import video1a from "../../assets/videos/1_a_login_logout_cambio_operatore.mp4";
import video1b from "../../assets/videos/1_b_password_dimenticata-possiedo_OTP.mp4"
import video1c from "../../assets/videos/1_c_cambio_password.mp4"
import video2a from "../../assets/videos/2_a_home_page–menu_laterale.mp4"
import video2b from "../../assets/videos/2_b_to_do_list.mp4"
import video2c from "../../assets/videos/2_c_info.mp4"
import video2d from "../../assets/videos/2_d_dashboard.mp4"
import video3a from "../../assets/videos/3_a_gestione_pratiche_indirette-attivita_di_recall_vendita.mp4"
import video3b from "../../assets/videos/3_b_chiusura_pratica_indirette.mp4"
import video4a from "../../assets/videos/4_a_gestione_pratica_previmedical.mp4"
import video4b from "../../assets/videos/4_b_gestione_altri_partner–creazione_nuova_pratica.mp4"
import video4c from "../../assets/videos/4_c_compilazione_preventivo-crea_doc_preventivo.mp4"
import video4d from "../../assets/videos/4_d_compilazione_cambio_visus-crea_doc_cambio_visus.mp4"
import video4e from "../../assets/videos/4_e_trasformazione_pratica_diretta_in_indiretta.mp4"
import video4f from "../../assets/videos/4_f_chiusura_forzata_pratica_diretta.mp4"
import { Flex, List, Typography, Collapse } from "antd";
import { PageTitle } from "../../components/Common/PageTitle/PageTitle";
import { Paths } from "../../models/Paths";
import React, { useState } from "react";

export function VideoPage() {
    const [opened, setOpened] = useState<number>(0);

    const data = [
        {
            key: 1,
            category: "ACCESSI",
            items: [
                {
                    key: 1,
                    label: "a. Login, logout, cambio operatore",
                    videoSrc: video1a,
                },
                {
                    key: 2,
                    label: "b. Password dimenticata - possiedo OTP",
                    videoSrc: video1b,
                },
                {
                    key: 3,
                    label: "c. Cambio password",
                    videoSrc: video1c,
                },
            ],
        },
        {
            key: 2,
            category: "OVERVIEW GENERALE",
            items: [
                {
                    key: 3,
                    label: "a. Home Page - Menu laterale",
                    videoSrc: video2a,
                },
                {
                    key: 4,
                    label: "b. To Do list",
                    videoSrc: video2b,
                },
                {
                    key: 5,
                    label: "c. Info",
                    videoSrc: video2c,
                },
                {
                    key: 6,
                    label: "d. Dashboard",
                    videoSrc: video2d,
                },
            ],
        },
        {
            key: 3,
            category: "PRATICHE INDIRETTE",
            items: [
                {
                    key: 7,
                    label: "a. Gestione Pratiche Indirette – Attività di recall e vendita",
                    videoSrc: video3a,
                },
                {
                    key: 8,
                    label: "b. Chiusura Pratica Indirette",
                    videoSrc: video3b, // Placeholder for new video
                },
            ],
        },
        {
            key: 4,
            category: "PRATICHE DIRETTE",
            items: [
                {
                    key: 9,
                    label: "a. Gestione Pratica Previmedical",
                    videoSrc: video4a,
                },
                {
                    key: 10,
                    label: "b. Gestione altri partner – Creazione Nuova Pratica",
                    videoSrc: video4b,
                },
                {
                    key: 11,
                    label: "c. Compilazione Preventivo",
                    videoSrc: video4c,
                },
                {
                    key: 12,
                    label: "d. Compilazione Cambio visus",
                    videoSrc: video4d,
                },
                {
                    key: 13,
                    label: "e. Trasformazione pratica diretta in indiretta",
                    videoSrc: video4e,
                },
                {
                    key: 14,
                    label: "f. Chiusura forzata pratica diretta",
                    videoSrc: video4f,
                },
            ],
        },
    ];

    const handleOpened = (keySelected: number) => {
        if (opened === keySelected) setOpened(0);
        else setOpened(keySelected);
    };

    const collapseItems = data.map((category) => ({
        key: category.key,
        label: category.category,
        children: (
            <List
                bordered
                dataSource={category.items}
                renderItem={(item) => (
                    <List.Item>
                        <Flex vertical>
                            <Typography.Text
                                onClick={() => handleOpened(item.key)}
                                style={{ cursor: "pointer" }}
                            >
                                {item.label}
                            </Typography.Text>
                            {opened === item.key && (
                                <>
                                    <br />
                                    <video controls style={{ width: "100%" }}>
                                        <source src={item.videoSrc} type="video/mp4" />
                                    </video>
                                </>
                            )}
                        </Flex>
                    </List.Item>
                )}
            />
        ),
    }));

    return (
        <>
            <PageTitle title={"Video Tutorial"} backTo={Paths.INFOMENU}></PageTitle>
            <Collapse accordion items={collapseItems} />
        </>
    );
}
