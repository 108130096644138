import {EnvService} from "./EnvService";

export enum LogLevel{
    DEVELOP="DEVELOP",
    BUSINESS="BUSINESS"

}
export class LogService{

    static warning (message?: any, ...optionalParams: any[]){
        if(EnvService.getLogLevel() === LogLevel.DEVELOP){
            console.warn(message,optionalParams)
        }
    }
    static error (message?: any, ...optionalParams: any[]){
        if(EnvService.getLogLevel() === LogLevel.DEVELOP){
            console.error(message,optionalParams)
        }
    }
    static info (message?: any, ...optionalParams: any[]){
        if(EnvService.getLogLevel() === LogLevel.DEVELOP){
            console.info(message,optionalParams)
        }
    }

    static log (message?: any, ...optionalParams: any[]){
        if(EnvService.getLogLevel() === LogLevel.DEVELOP){
            console.log(message,optionalParams)
        }
    }
}