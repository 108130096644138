import React from "react";
import {Alert, Button, Form, Input} from "antd";
import {SubmitFunction} from "../LoginForm";
import "../../../../styles/Buttons.css";

export interface SMSFormProps {
    errorMessage: string | null;
    onSubmit: SubmitFunction<{totp: string}>;
}

const SMSForm = (props: SMSFormProps) => {
    return (
        <Form key="totpStep" onFinish={props.onSubmit} autoComplete="off">
            <Form.Item
                label="Email Code"
                name="totp"
                rules={[{ required: true, message: 'Inserisci il codice!' }]}
            >
                <Input.OTP />
            </Form.Item>
            {props.errorMessage != null && <Alert message={props.errorMessage} type="error" />}
            <br/>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button className={"luxottica-button-primary"} htmlType="submit">
                    Login
                </Button>
            </Form.Item>
        </Form>
    )
}

export default SMSForm;
