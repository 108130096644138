import {Button, Divider, Flex, Modal, Typography} from "antd";
import { useState} from "react";
import "./PracticeDiscounts.css"
import {Voucher, VoucherDetail} from "../../../models/Info/voucher.model";
import {PracticeDiscount} from "./components/PracticeDiscount";
import {DiscountCodeHeader} from "../../Info/DiscountCodeHeader/DiscountCodeHeader";
import {toNumber} from "lodash";

export interface PracticeDiscountsProps{
    insuranceProviderId:number,
    voucher: Voucher;
    discounts: Array<VoucherDetail>;
}
export function PracticeDiscounts(props:PracticeDiscountsProps){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Text } = Typography;

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };

    function getFooter():React.ReactNode {
        return <></>
    }
    function getDiscountsList():React.ReactNode|undefined{
        let discountsElements:React.ReactNode[]|undefined= props.discounts?.map((elem: VoucherDetail,index:number) => {
            return <div key={index}>
                <PracticeDiscount agreementDetail={elem}></PracticeDiscount>
                <Divider></Divider>
            </div>
        })
        return <div className={"discounts-elements-container"}>
            {discountsElements}
        </div>
    }

    function getRefundCondition() {
        return <Flex gap={"small"} vertical>
            <Text strong>Condizione di rimborso</Text>
            <Text>{props.voucher.refundConditions}</Text>
        </Flex>
    }
    return <div>
        <Button type={"primary"}  onClick={showModal}>Scontistica</Button>
        <Modal className={"practice-discounts-modal"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={getFooter()}>
            <div className={"body-modal"}>
                <DiscountCodeHeader discountCode={toNumber(props.voucher.code)} organizationId={props.insuranceProviderId}></DiscountCodeHeader>
                <Flex gap={"small"} vertical>
                    <Text strong>Cambio visus</Text>
                    <Text>{props.voucher.visus}</Text>
                </Flex>
                <br/>
                {getRefundCondition()}
                <Divider className={"divider"} ></Divider>
                {getDiscountsList()}
            </div>

        </Modal>
    </div>
}
