import {useState} from 'react';
import {QueryParameterModel} from "../models/QueryParameter";
import {PaginationInfo} from "../models/Common/PagedResponse";
export interface usePageProps{
    page:number,
    size:number,
    lastPage:number,
    allItemsNumber:number
}
export function usePage(props:usePageProps, sortColumn: string, sortDirection: "ASC"|"DESC") {
    const[page,setPage] = useState<number>(props.page);
    const[size,setSize] = useState<number>(props.size);
    const[allItemsNumber,setAllItemsNumber] = useState<number>(props.allItemsNumber);
    const[lastPage,setLastPage] = useState<number>(props.lastPage);
    const[sortQueryParameters,setSortQueryParameters] =
        useState<QueryParameterModel[]>([
            {
                "name": "sortColumn",
                "value": sortColumn
            },
            {
                "name": "sortDirection",
                "value": sortDirection
            }
        ]);

    const resetPagination = () => {
        setPage(props.page);
        setAllItemsNumber(props.allItemsNumber)
        setLastPage(props.lastPage);
    }

    const updateValues = (page:number, allItemsNumber:number, lastPage:number) => {
        setPage(page);
        setAllItemsNumber(allItemsNumber)
        setLastPage(lastPage);
    };

    const getPaginationInfo = () => {
        return {
            currentPage: page,
            allItemsNumber: allItemsNumber
        } as PaginationInfo;
    }

    const incrementPage = () => {
        const params: Array<QueryParameterModel> = [];
        if (page !== lastPage) {
            setPage(value=> value + 1);
            params.push({
                name:"pageSize",
                value:size
            }, {
                name:"page",
                value:page+1
            })
        } else {
            params.push({
                name:"pageSize",
                value:size
            }, {
                name:"page",
                value:page
            })
        }
        return params.concat(sortQueryParameters);
    };

    const getDefaultPageQueryParameters = (appendQueryParams: boolean = true): Array<QueryParameterModel> =>{
        let params: Array<QueryParameterModel> = [{
            name:"pageSize",
            value:props.size
        }, {
            name:"page",
            value:props.page
        }];

        if (appendQueryParams) {
            params = params.concat(sortQueryParameters);
        }

        return params
    }

    const getPageQueryParameters = (appendQueryParams: boolean = true): Array<QueryParameterModel> => {
        let params: Array<QueryParameterModel> = [{
            name:"pageSize",
            value:size
        }, {
            name:"page",
            value:page
        }];

        if (appendQueryParams){
            params = params.concat(sortQueryParameters);
        }

        return params;
    }

    const getDefaultPage = () => {
        return props
    }

    const updateSortParams = (sortQueryParams: QueryParameterModel[]) => {
        setSortQueryParameters(sortQueryParams)
    }

    return {
        page,
        size,
        lastPage,
        allItemsNumber,
        updateValues,
        incrementPage,
        getPageQueryParameters,
        getDefaultPage,
        getDefaultPageQueryParameters,
        resetPagination,
        updateSortParams,
        getPaginationInfo
    };
}