export enum Paths{
    LOGIN = "/login",
    HOME = "/",
    INFO = "/info",
    INFOMENU = "/infoMenu",
    DISCOUNT = "/discount",
    SHOP_ACTIVITIES = "/shop_activities",
    MANUAL = "/manual",
    PRACTICES ="/practices",
    NEW="/new",
    NEW_PRACTICES ="/new/practices",
    NEW_DOCS="/new/docs",
    PRACTICES_DETAILS ="/practices/details",
    DASHBOARD ="/dashboard",
    TODO ="/todo",
    VIDEO ="/video",
}