import {PracticeProgressStatus} from "../../../models/Practice/Practice";
import {getIconFromProgress} from "../../../models/IconStateProgress";
import {IconCheckBox} from "../IconCheckBox/IconCheckBox";

export interface IconStateProps {
    progressStatus: PracticeProgressStatus
    isChecked: boolean;
    inError?: boolean;
}

export function IconState({progressStatus, isChecked, inError = false}: IconStateProps) {
    const icon = getIconFromProgress(progressStatus);

    return <IconCheckBox icon={icon} checked={isChecked} inError={inError}/>
}