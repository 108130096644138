import Form, {Rule} from "antd/lib/form";
import { InputNumber} from "antd";
import "./AddValue.css"
import {NamePath} from "antd/lib/form/interface";

export interface AddValueProps {
    name: NamePath
    otherValueName?: NamePath | null
    errorMessage: string;
    compare: (value: number, otherValue: number) => boolean;
}

export function AddValue(props:AddValueProps){
    const rules: Array<Rule> = [
        { required: true, message: 'Inserire valore!' }
    ]

    if (props.otherValueName != null) {
        rules.push(
            ({ getFieldValue }) => ({
                validator(_, value) {
                    let otherValue = getFieldValue(props.otherValueName)
                    if (value && otherValue && !props.compare(value, otherValue)) {
                        return Promise.reject(new Error(props.errorMessage));
                    }
                    return Promise.resolve();
                },
            })
        )
    }

    return <>
        <Form.Item name={props.name}
                   rules={rules}>
            <InputNumber min={0}
                         addonAfter="€"
                         className={"add-value-input-box"}
                         decimalSeparator={","}
                         controls={false} />
        </Form.Item>
    </>
}