import React, { useState } from 'react';
import { OperatorsHttpService } from '../../services/Http/OperatorsHttpService';
import {Button, Modal, Upload, Form, Typography} from 'antd';
import {FilterFilled, UploadOutlined} from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import {IconButton} from "../Common/IconButton/IconButton";
import {IconLuxotticaDimension} from "../../models/IconLuxotticaDimension";

const { Paragraph } = Typography;

export interface LoadOperatorsProps {
    isOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    disabled?: boolean,
}

export function LoadOperatorsModal(props: LoadOperatorsProps) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    const validMimeTypes = [
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    ];

    const handleFileChange = (info: any) => {
        const file = info.file;
        if (file && validMimeTypes.includes(file.type)) {
            setSelectedFile(file);
            setFileList([info.file]);
            setResponseMessage(null);
        } else {
            setSelectedFile(null);
            setFileList([]);
            setResponseMessage('Errore: Tipo di file non valido. Carica un file Excel (.xls o .xlsx).');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setResponseMessage('Errore: Seleziona un file prima di caricare.');
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        setUploading(true);
        try {
            const response = await OperatorsHttpService.uploadPracticeFiles(formData);
            console.log("response", response.status);
            if (response.status === 200) {
                const data = response.data;
                let message = `${data.message} - Totale file ${data.response.totaleFile}, caricati ${data.response.loaded}, invariati ${data.response.invariati}, aggiornati ${data.response.aggiornati}, disabilitati  ${data.response.disabilitati}`;
                setResponseMessage(message || 'Caricamento riuscito');
            } else {
                throw new Error('Errore durante il caricamento');
            }
        } catch (error: any) {
            console.error('Errore durante il caricamento:', error);
            if (error.response.status === 400) {
                const data = error.response.data;
                setResponseMessage("Errore: " + data.message);
            } else {
                setResponseMessage('Errore durante il caricamento del file.');
            }
        } finally {
            setUploading(false);
            setSelectedFile(null);
            setFileList([]);
        }
    };

    return <>
        <IconButton hide={props.disabled} icon={<FilterFilled/>} dimension={IconLuxotticaDimension.SMALL}></IconButton>
        <Modal open={props.isOpen}
               onCancel={props.onCancel}
               width={"40%"}
               footer={(_, {OkBtn, CancelBtn}) => (
                   <>
                       <Form.Item label="Seleziona file Excel">
                           <Upload multiple={false} beforeUpload={() => false} onChange={handleFileChange} fileList={fileList} accept=".xls,.xlsx" >
                               <Button icon={<UploadOutlined />}>Seleziona File</Button>
                           </Upload>
                       </Form.Item>
                       {responseMessage && (
                           <Paragraph style={{ color: responseMessage.includes('Errore') ? 'red' : 'green' }}>
                               {responseMessage}
                           </Paragraph>
                       )}
                       <Button key="close" onClick={props.onCancel}>
                           Chiudi
                       </Button>
                       <Button key="upload" type="primary"
                               onClick={handleUpload} loading={uploading} disabled={!selectedFile} >
                           {uploading ? 'Caricamento in corso...' : 'Carica'}
                       </Button>
                   </>
               )}
               modalRender={dom => (
                   <Form layout="vertical" className={"form-filter"}>
                       {dom}
                   </Form>
               )}
        >
        </Modal>
    </>
}
