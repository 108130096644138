import { Flex, Typography} from "antd";
import {DiscountCode} from "../DiscountCode/DiscountCode";
import "./AgreementVoucher.css";
import {AgreementTypeLabel} from "../../Common/AgreementTypeLabel/AgreementTypeLabel";
import {InfoLogo} from "../../Common/InfoLogo/InfoLogo";

const {Text} = Typography;

export interface AgreementVoucherProps {
    organizationId: number;
    agreementType: string;
    changeVisus: string;
    refundCondition: string;
    discountCode: number;
    locationGroupCode: string;
}

export function AgreementVoucher(props: AgreementVoucherProps) {

    const brand = props.locationGroupCode == "SV" ? "Salmoiraghi & Viganò" : "Grand Vision";

    const cambioVisus = props.changeVisus ? props.changeVisus : "-";
    const refundCondition = props.refundCondition ? props.refundCondition : "-";

    return (
        <>

            <Flex className={"agreement-voucher"} justify={"flex-start"} gap={"large"}>

                <Flex vertical >
                    <InfoLogo organizationId={props.organizationId} width={200}></InfoLogo>
                    <DiscountCode discountCode={props.discountCode}></DiscountCode>
                </Flex>

                <Flex vertical gap={"small"}>
                    <Flex align={"center"} gap={"small"}>
                        <Text strong>Brand:</Text> {brand}
                    </Flex>
                    <Flex align={"center"} gap={"small"}>
                        <Text strong>Gestione: </Text> <AgreementTypeLabel
                        value={props.agreementType}></AgreementTypeLabel>
                    </Flex>
                    <Flex align={"center"} gap={"small"}>
                        <Text strong>Cambio visus: </Text> {cambioVisus}
                    </Flex>
                    <Flex align={"center"} gap={"small"}>
                        <Text strong>Condizione di rimborso: </Text> {refundCondition}
                    </Flex>
                </Flex>

            </Flex>
        </>
    )
}