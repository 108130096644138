import {Image} from "antd";
import LogoSalmoiraghiVigano from '../../../assets/SalmoiraghiVigano.svg'
import LogoGrandVision from '../../../assets/GrandVision.svg'
import LogoLuxottica from '../../../assets/EssilorLuxotticaWhite.svg'
import {AuthService} from "../../../services/AuthService";

export function Logo(){

    const appUser = AuthService.getUser();

    let locationGroupCode = "LUX";

    if (appUser?.defaultLocation) {
        const defaultLocation = appUser.defaultLocation;
        locationGroupCode = defaultLocation.groups.ADM[0]
    }

    return <>
        <div className={"logo-container"}>
            { locationGroupCode == "LUX" && <Image preview={false} width={200} height={60} src={LogoLuxottica} ></Image> }
            { locationGroupCode == "SV" && <Image preview={false} width={200} height={60} src={LogoSalmoiraghiVigano} ></Image> }
            { locationGroupCode == "GV" && <Image preview={false} width={200} height={50} src={LogoGrandVision} ></Image> }
        </div>

    </>
}