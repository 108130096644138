import {Button, Modal} from "antd";
import {useState} from "react";
export interface TransformPracticeProps{
    disabled?: boolean;
    onClickTransformToIndirect:()=>void
}

export function TransformPractice(props:TransformPracticeProps){
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        props.onClickTransformToIndirect();
        setIsModalOpen(false);

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return <>
        <Button disabled={props.disabled} type={"primary"} className="button" onClick={showModal}>Trasforma in indiretta</Button>
        <Modal title="Trasforma in indiretta" open={isModalOpen} onOk={handleOk}
                okText="Conferma"
                onCancel={handleCancel}>
            La pratica sta per essere trasformata in indiretta. Sei sicuro di voler procedere?
        </Modal>
    </>
}