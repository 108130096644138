import {Patient} from "../../../models/Patient";
import {InsuranceProviderEclaim} from "../../../models/InsuranceProvider";
import {
    Button,
    Checkbox,
    Col,
    DatePicker, Flex,
    Form,
    FormProps,
    Image,
    Input,
    InputNumber,
    Radio,
    Row,
    Space,
    Typography
} from "antd";
import {useTranslation} from "react-i18next";
import {CambioVisusFormData} from "../../../models/cambioVisus";
import {DiottrieTable} from "../DiottrieTable";
import {FirmaInput} from "../FirmaInput";
import {getLogoFromIntegrationCode} from "../../Common/logoUtils";
import React from "react";
import {CambioVisusInfo} from "../CambioVisusInfo";
import {DateField} from "../DateField";
import {InputFloatNumber} from "../InputFloatNumber";

const FormItem = Form.Item<CambioVisusFormData>;

export interface CambioVisusGenericProps{
    onClose: ()=>void
    patient?: Patient,
    insuranceProviderIntegrationCode: string;
    isSubmitting: boolean;
    onSubmit: (data: CambioVisusFormData) => void;
    shopGroup: string;
}
export function CambioVisusGeneric(props: CambioVisusGenericProps) {
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation();

    const onSubmit: FormProps<CambioVisusFormData>['onFinish'] = (data) => {
        props.onSubmit(data);
    };

    function onClose ():void  {
        props.onClose();
    };

    const integrationCode = props.insuranceProviderIntegrationCode;
    const key = "cambioVisus" + integrationCode;

    return (
        <div>
            <Image
                preview={false}
                width={300}
                src={getLogoFromIntegrationCode(integrationCode)}
            ></Image>
            <Form
                form={form}
                scrollToFirstError
                key={key}
                layout="vertical"
                onFinish={onSubmit}
            >
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <div>
                        <strong>{t("insured")}</strong>
                        <FormItem
                            name="assicurato"
                            label="Cognome Nome"
                            rules={[
                                {
                                    required: true,
                                    message: "Inserisci il nome dell'assicurato",
                                },
                            ]}
                            initialValue={props?.patient?.name}
                        >
                            <Input />
                        </FormItem>
                    </div>
                    <div>
                        L'Assicurato presenta il seguente{" "}
                        <strong>vizio di rifrazione</strong>
                        <Row gutter={15}>
                            <Col span={6}>
                                <FormItem name="miopia" label="Miopia" valuePropName="checked">
                                    <Checkbox/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem name="ipermetropia" label="Ipermetropia" valuePropName="checked">
                                    <Checkbox/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem name="presbiopia" label="Presbiopia" valuePropName="checked">
                                    <Checkbox/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem name="astigmatismo" label="Astigmatismo" valuePropName="checked">
                                    <Checkbox/>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                    <FormItem
                        name="portatoreLenti"
                        label="L'Assicurato è già portatore di lenti per correggere il difetto:"
                        rules={[
                            {
                                required: true,
                                message: "Seleziona una voce",
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Si</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </FormItem>
                    <div>
                        Indicare le <strong>precedenti</strong> diottrie:
                        <DiottrieTable prefix="previous" isRequired={false} />
                    </div>
                    <FormItem
                        name="cambioVisus"
                        label="Se non rilevabili le precedenti diottrie, l'assistito dichiara un effettivo cambio del visus:"
                        rules={[
                            {
                                required: true,
                                message: "Seleziona una voce",
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Si</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </FormItem>
                    <div>
                        Indicare le <strong>attuali</strong> diottrie:
                        <DiottrieTable prefix="current" isRequired={false} />
                    </div>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                name="distanzaInterpupillare"
                                label={<strong>Distanza interpupillare mm</strong>}
                            >
                                <InputFloatNumber min={0} />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="note" label="Note">
                                <Input.TextArea />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                name="dataDoc"
                                label="Data"
                                rules={[
                                    {
                                        required: true,
                                        message: "Selezionare una data",
                                    },
                                ]}
                            >
                                <DateField />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem name="firma" rules={[{ required: true, message: 'Inserire la firma prima di procedere'}]}>
                                <FirmaInput />
                            </FormItem>
                        </Col>
                    </Row>
                    <CambioVisusInfo shopGroup={props.shopGroup}/>
                    <Flex justify="flex-end">
                        <Space>
                            <FormItem>
                                <Button htmlType="button" onClick={onClose}>Annulla</Button>
                            </FormItem>
                            <FormItem>

                                <Button
                                    loading={props.isSubmitting}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Salva
                                </Button>
                            </FormItem>

                        </Space>
                    </Flex>
                </Space>
            </Form>
        </div>
    );
}