import {Col, Flex, Row} from "antd";
import {Paths} from "../../models/Paths";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {PracticeTable} from "../../components/PracticeTable";
import {useEffect, useState} from "react";
import {Practice, PracticeProgressStatus, PracticeSearchBody, PracticeType} from "../../models/Practice/Practice";
import {
    NewPracticesTableFilters,
    PracticesTableFiltersForm
} from "../../components/Common/PracticesTableFilters/NewPracticesTableFilters";
import {ClaimStatuses} from "../../models/Practice/PracticeDetail";
import {AuthService} from "../../services/AuthService";
import {NewTodoListFilters, TodoFilterTiles} from "../../components/TodoList/NewTodoListFilters";
import {TodoListFilter, TodoListStats} from "../../models/TodoList/TodoListStats";
import {PracticesHttpService} from "../../services/Http/PracticesHttpService";
import {QueryParameterModel} from "../../models/QueryParameter";
import {Utils} from "../../services/Utils";
import {usePage} from "../../customHooks/UsePage";
import {useNavigate} from "react-router-dom";
import {SorterResult} from "antd/es/table/interface";
import {SortTableLuxottica} from "../../services/SortTableLuxottica";
import {assignWith, isNil} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {addToForm, saveForm} from "../../store/states/FormState";
import {saveTodoListFilters} from "../../store/states/TodoListFiltersSlice";
import {saveSortParameters} from "../../store/states/FormState";

function transformFormToBody(values: PracticesTableFiltersForm) {
    return {
        claimCode: values?.claimCode,
        agreement: values?.refundType,
        insuranceProviderId: values?.insuranceProviderId,
        patientName: values?.patientName,
        shop: values?.locationCode,
        status: values?.state ? [values.state] : null,
        creationDateRange: [null, null],
        claimStatus: values?.claimStatus
    };
}

function transformBodyToForm(values: PracticeSearchBody): PracticesTableFiltersForm {
    return {
        patientName: values.patientName,
        insuranceProviderId: values.insuranceProviderId,
        state: !isNil(values.status) ? values.status[0] : null,
        refundType: values.agreement,
        locationCode: values.shop,
        claimStatus: values.claimStatus,
        claimCode: values.claimCode
    }
}

const calcTileFilters = (selectedTile: TodoFilterTiles) => {
    let newFilters: PracticeSearchBody = {};
    switch (selectedTile) {
        case TodoFilterTiles.TO_CONTACT:
            newFilters.status = [PracticeProgressStatus.DA_CONTATTARE];
            newFilters.agreement = null;
            break;
        case TodoFilterTiles.WAITING_PURCHASE:
            newFilters.status = [PracticeProgressStatus.PRESO_APPUNTAMENTO, PracticeProgressStatus.CONTATTATO];
            newFilters.agreement = PracticeType.INDIRECT;
            break;
        case TodoFilterTiles.WAITING_DOCS:
            newFilters.status = [PracticeProgressStatus.PRESO_APPUNTAMENTO];
            newFilters.agreement = PracticeType.DIRECT;
            break;
        case TodoFilterTiles.WAITING_AUTHORIZATION:
            newFilters.status = [PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE, PracticeProgressStatus.DOCUMENTAZIONE_INVIATA];
            newFilters.agreement = PracticeType.DIRECT;
            break;
        case TodoFilterTiles.INVOICE_TO_UPLOAD:
            newFilters.status = [PracticeProgressStatus.PIC_AUTORIZZATA, PracticeProgressStatus.PIC_NON_AUTORIZZATA];
            newFilters.agreement = PracticeType.DIRECT;
            break;
        case TodoFilterTiles.TO_BE_RECTIFIED:
            newFilters.status = [PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE, PracticeProgressStatus.ERRORE_IN_FATTURA];
            newFilters.agreement = PracticeType.DIRECT;
            break;
    }

    return newFilters;
}

export function TodoList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appUser = AuthService.getUser();
    const defaultLocation = appUser?.defaultLocation?.code || null;

    const [todoListStats, setTodoListStats] = useState<TodoListStats>();
    const [practices, setPractices] = useState<Practice[]>([]);
    const [loading, setLoading] = useState(false);

    const savedSelectedTile = useSelector((state:any) => state.todoListFilters.selectedTile)
    const savedFilterValues = useSelector((state:any) => state.practiceTableFilterForm.values);

    const [selectedTile, setSelectedTile] = useState(isNil(savedSelectedTile) ? TodoFilterTiles.NONE : savedSelectedTile);

    const defaultValues = {
        insuranceProviderId: null,
        patientName: null,
        locationCode: defaultLocation,
        claimStatus: ClaimStatuses.OPEN
    }

    const initialValues: PracticesTableFiltersForm = Object.assign(
        {},
        transformBodyToForm(calcTileFilters(selectedTile)),
        isNil(savedSelectedTile) || savedSelectedTile == TodoFilterTiles.NONE ? defaultValues : savedFilterValues
    );

    const [bodyFilters, setBodyFilters] = useState<PracticeSearchBody>(transformFormToBody(initialValues))

    const sortParameters:QueryParameterModel[] = useSelector((state: any) => state.practiceTableFilterForm.sortParameters);

    const sortParametersMap = new Map<string, any>(sortParameters.map(item => [item.name, item.value]));

    const {
        page,
        lastPage,
        updateValues,
        incrementPage,
        getDefaultPageQueryParameters,
        resetPagination,
        updateSortParams
    } = usePage({ page:sortParametersMap.get('page'), size:sortParametersMap.get('pageSize'), lastPage:1, allItemsNumber:0 }, sortParametersMap.get('sortColumn'), sortParametersMap.get('sortDirection'));

    useEffect(() => {
        dispatch(saveForm(initialValues));
        loadTodoListStats(transformFormToBody(initialValues));
        if (selectedTile != TodoFilterTiles.NONE) {
            let newFilters = Object.assign({}, bodyFilters, calcTileFilters(selectedTile));
            setBodyFilters(newFilters);
            loadData(sortParameters, newFilters, true);
        }
    }, []);

    const loadTodoListStats = async (body: PracticeSearchBody) => {
        const todolistBody: TodoListFilter = {
            shop: body.shop,
            patientName: body.patientName,
            insuranceProviderId: body.insuranceProviderId,
            refundType: body.agreement,
        }
        const { data } = await PracticesHttpService.getTodoListStats(todolistBody)
        setTodoListStats(data)
    }

    const loadData = async (queryParameters:QueryParameterModel[], body: PracticeSearchBody, isFilterChange: boolean = false) => {
        setLoading(true);
        const response = await PracticesHttpService.getListOfPractices(queryParameters, body)
        updateValues(response.data.currentPage, response.data.allItemsNumber, response.data.totalPages)
        // FIXME: ???
        let dataWithKeys = Utils.addReactUniqueKey(response.data.items)
        if (!isFilterChange) {
            dataWithKeys = practices.concat(dataWithKeys)
        }
        setPractices(dataWithKeys);
        setLoading(false);
    }

    const loadNewData = async (bodyFilters: PracticeSearchBody) => {
        setBodyFilters(bodyFilters);
        await loadData(sortParameters, bodyFilters, true)
    }

    const goToDetail = (key: number) => {
        navigate(Paths.PRACTICES_DETAILS + "/" + key );
    }

    const onSort = async (info: SorterResult<any> | SorterResult<any>[]) => {
        setPractices([]);
        let sortQueryParams: QueryParameterModel[] = SortTableLuxottica.getQueryParams(info);
        updateSortParams(sortQueryParams);
        resetPagination();
        let params = getDefaultPageQueryParameters(false);
        params = params.concat(sortQueryParams)
        dispatch(saveSortParameters(params));
        await loadData(params, bodyFilters,true);
    }

    const onScrollLastRow = async () => {
        if (page !== lastPage) {
            const queryParameters: Array<QueryParameterModel> = incrementPage();
            await loadData(queryParameters, bodyFilters)
        }
    }

    const onCloseModal = async (form: PracticesTableFiltersForm) => {
        dispatch(saveForm(form));
        const body = transformFormToBody(form);
        let newBody = assignWith(bodyFilters, body, (objValue, srcValue, key) => {
            if (key == 'insuranceProviderId' || key == 'shop' || key == 'patientName')
                return srcValue;

            return isNil(objValue) ? srcValue : objValue;
        })
        await loadTodoListStats(newBody);
        setBodyFilters(newBody)
        resetPagination();
        await loadData(sortParameters, newBody, true);
    }

    const onTileClick = async (tileClicked: TodoFilterTiles) => {
        dispatch(saveTodoListFilters(tileClicked));
        setSelectedTile(tileClicked);

        let newFilters = Object.assign({}, bodyFilters, calcTileFilters(tileClicked));

        await loadTodoListStats(newFilters);
        await loadNewData(newFilters);
    }

    const onRefundTypeClicked = async (refundType: PracticeType) => {
        let body = bodyFilters;
        body.agreement = refundType;
        await loadTodoListStats(body);
        await loadNewData(body);
    }

    const extractDefaultOrderedColumn = ():string => {
        return sortParameters?.find(item => item.name === "sortColumn")?.value;
    }

    const extractDefaultOrderedColumnDirection = () => {
        const beValue = sortParameters?.find(item => item.name === "sortDirection")?.value;
        return (beValue === 'ASC') ? 'ascend' : 'descend';
    }

    return <>
        <Flex justify={'space-between'} align={'center'}>
            <PageTitle title={"To do list"} backTo={Paths.HOME}/>

            <NewPracticesTableFilters hide={selectedTile == TodoFilterTiles.NONE}
                                      initialValues={initialValues}
                                      defaultValues={defaultValues}
                                      onCloseModal={onCloseModal}
                                      showStatusFilter={false}
                                      showRefundTypeFilter={false}/>
        </Flex>
        <Row gutter={[16,16]}>
            <Col span={18}>
                <PracticeTable data={practices}
                               loading={loading}
                               goToDetail={goToDetail}
                               onSort={onSort}
                               onScrollLastRow={onScrollLastRow}
                               orderedColumn={extractDefaultOrderedColumn()}
                               orderedColumnDirection={extractDefaultOrderedColumnDirection()}
                               hideStatus={true}/>
            </Col>
            <Col span={6}>
                <NewTodoListFilters currentRefundType={bodyFilters.agreement}
                                    selectedTile={selectedTile}
                                    todoListStats={todoListStats}
                                    canChangeRefundType={selectedTile == TodoFilterTiles.TO_CONTACT}
                                    onTileClick={onTileClick}
                                    onRefundTypeClicked={onRefundTypeClicked}/>
            </Col>
        </Row>
    </>
}
