import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TodoFilterTiles} from "../../components/TodoList/NewTodoListFilters";
import {initial} from "lodash";

interface TodoListFiltersState {
    selectedTile: TodoFilterTiles;
}

const initialState = {
    selectedTile: TodoFilterTiles.NONE,
} satisfies TodoListFiltersState as TodoListFiltersState;

const TodoListFiltersSlice = createSlice({
    name: 'todoListFilters',
    initialState,
    reducers: {
        saveTodoListFilters: (state, action: PayloadAction<TodoFilterTiles>) => {
            state.selectedTile = action.payload;
        },
        resetTodoListFilters: (state) => {
            state.selectedTile = initialState.selectedTile;
        }
    },
});

export const { saveTodoListFilters, resetTodoListFilters } = TodoListFiltersSlice.actions;
export default TodoListFiltersSlice.reducer;