import SignatureCanvas from "react-signature-canvas";

import "./index.css";
import { Modal } from "antd";
import { useState } from "react";

export interface CambioVisusFirmaModalProps {
  isOpen: boolean;
  onComplete: (base64Firma: string) => void;
  onCancel: () => void;
}

export function CambioVisusFirmaModal(props: CambioVisusFirmaModalProps) {
  const [sigCanvas, setSigCanvas] = useState<SignatureCanvas | null>(null);
  const [okButtonDisabled, setDisabled] = useState<boolean>(
    sigCanvas?.isEmpty() || true
  );

  const cleanup = () => {
    if (sigCanvas == null) return;

    sigCanvas.clear();
    setDisabled(true);
  };

  const handleOk = () => {
    if (sigCanvas == null) return;

    const dataUrl = sigCanvas.toDataURL("image/png");
    props.onComplete(dataUrl);
    cleanup();
  };

  const handleCancel = () => {
    if (sigCanvas == null) return;

    props.onCancel();
    cleanup();
  };

  return (
    <Modal
      title="Firma"
      open={props.isOpen}
      okText="CONFERMA"
      cancelText="ANNULLA"
      onOk={handleOk}
      okButtonProps={{ disabled: okButtonDisabled }}
      onCancel={handleCancel}
      width={700}
    >
      <SignatureCanvas
        ref={(ref) => setSigCanvas(ref)}
        penColor="black"
        canvasProps={{ width: 650, height: 400, className: "sigCanvas" }}
        onEnd={() => setDisabled(false)}
      />
    </Modal>
  );
}
