import {IconLuxottica} from "../IconLuxottica/IconLuxottica";
import React, {ReactElement} from "react";
import "./IconButton.css"
import {IconLuxotticaDimension} from "../../../models/IconLuxotticaDimension";
import {Button} from "antd";
import {DeleteFilled} from "@ant-design/icons";
import type {SizeType} from "antd/es/config-provider/SizeContext";
import {isNil} from "lodash";
export interface IconButtonProps{
    icon:ReactElement<any, any>;
    onCLick?:()=>void
    dimension?:IconLuxotticaDimension
    selected?:boolean
    className?:string
    hide?:boolean
}
export function IconButton(props:IconButtonProps){

    function getDimensionSizeClass(){
        let returnValue = "small";
        switch (props.dimension) {
            case (IconLuxotticaDimension.SMALL):
                returnValue = "small"
                break;
            case (IconLuxotticaDimension.MEDIUM):
                returnValue = "medium";
                break;
            case (IconLuxotticaDimension.LARGE):
                returnValue = "large";
                break;
        }
        return returnValue as SizeType;
    }
    function isSelected(){
        if(props.selected){
            return "default"
        }
        return "primary"
    }
    return <>
        { (props?.hide === false || isNil(props?.hide)) && <Button  type={isSelected()} shape="circle" className={props.className}
                               size={getDimensionSizeClass()} icon={props.icon} onClick={props.onCLick}></Button>}
    </>
}