import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    values: {},
    series:{}
};

const DashboardFormFiltersState = createSlice({
    name: 'form',
    initialState,
    reducers: {
        saveDashboardFormFilters: (state, action) => {
            state.values  = action.payload;
        },
        saveDashboardSeries: (state, action) => {
            state.series  = action.payload;
        },
    },
});

export const { saveDashboardFormFilters,saveDashboardSeries } = DashboardFormFiltersState.actions;
export default DashboardFormFiltersState.reducer;