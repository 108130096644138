import {Button, Flex} from "antd";

export interface AgreementActionsProps {
    onClickDiscountButton: () => void;
    onClickShopActivitiesButton: () => void;
}
export function AgreementActions(props: AgreementActionsProps) {

    return (
        <>
            <Flex vertical className="actions" justify={'space-around'} gap={"small"}>
                <Button type={"primary"} onClick={props.onClickDiscountButton}>Scontistica</Button>
                <Button type={"primary"} onClick={props.onClickShopActivitiesButton}>Attività negozio</Button>
            </Flex>
        </>
    )
}