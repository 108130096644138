import {Button,  Flex, Space, Tag, Typography} from "antd";
import {CloseCircleFilled} from "@ant-design/icons";
import {MessageTextBox} from "../../Common/MessageTextBox/MessageTextBox";
import {isNil} from "lodash";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {NotificationService} from "../../../services/NotificationService";
import {useParams} from "react-router-dom";
import {
    PracticeMessage,
    PracticeMessageBody,
    PracticeMessageResponse,
    PracticeMessageState
} from "../../../models/Practice/PracticeMessage";
import React, {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import {EmptyLuxottica} from "../../Common/Empty/EmptyLuxottica";
import {Utils} from "../../../services/Utils";
import {SpinLuxottica} from "../../Common/SpinLuxottica/SpinLuxottica";
import {AuthService} from "../../../services/AuthService";
import {Employee} from "../../../models/Auth/Employee";
import {ClaimStatuses, PracticeProgress, ProceedNextRequest} from "../../../models/Practice/PracticeDetail";
import {PracticeProgressStatus} from "../../../models/Practice/Practice";
import {useTranslation} from "react-i18next";

export interface ChatColumnProps{
    onClickExit:()=>void;
    onSaveMessage:()=>void;
    claimStatus:ClaimStatuses;
    progress:PracticeProgress[]
}
export function ChatColumn(props:ChatColumnProps){
    const { t } = useTranslation();
    const { Text } = Typography;
    let { id } = useParams();
    const [data, setData] = useState<PracticeMessage[]>()
    const [isLoading,setLoading]=useState<boolean>(true);

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        setLoading(true);
        PracticesHttpService.getPracticeMessages(id).then((response:AxiosResponse<PracticeMessageResponse,any>)=>{
            setData(response.data.items);
        }).finally(()=>{
            setLoading(false);
        })
    }
    function onClickMessageButton(from:string,messageText:string,state:PracticeMessageState|undefined){

        if(isNil(id)){
            return;
        }
        let operator:Employee|null = AuthService.getOperatorOnline();
        let body:PracticeMessageBody={
            operator: operator?.name ?? "",
            text: messageText,
            status:state
        }
        PracticesHttpService.savePracticeMessage(body,id).then(()=>{
            NotificationService.getInstance().openNotificationSuccess("Messaggio inviato","");
            loadData();
        })
    }
    function saveProceed(state : PracticeMessageState | undefined, operator: Employee|null){
        let bodyAppointment:ProceedNextRequest = { metadata :{operator:operator?.name ?? "" }, event:PracticeProgressStatus.PRESO_APPUNTAMENTO }
        let bodyContact:ProceedNextRequest = { metadata :{operator:operator?.name ?? "" }, event:PracticeProgressStatus.CONTATTATO }

        switch(state){
            case PracticeMessageState.PRESO_APPUNTAMENTO:
                let found = Utils.getProgressData(PracticeProgressStatus.CONTATTATO,props.progress)
                if(found){
                    PracticesHttpService.saveProceedNext(id ?? "",bodyAppointment).then(()=>{
                        props.onSaveMessage();
                    })
                } else {
                    PracticesHttpService.saveProceedNext(id ?? "",bodyContact).then(()=>{
                        PracticesHttpService.saveProceedNext(id ?? "",bodyAppointment).then(()=>{
                            props.onSaveMessage();
                        })
                    })
                }

                break;
            case PracticeMessageState.CONTATTATO:
                PracticesHttpService.saveProceedNext(id ?? "",bodyContact).then(()=>{
                    props.onSaveMessage();
                })
                break;
        }

    }
    function drawChat():React.ReactNode{
        let operatorOnline = AuthService.getOperatorOnline();
        return data?.map((element:PracticeMessage)=>{
            //const randomNumber = random(0, 1);
            let classChatMassage = "chat-message margin-y-10px";

            if(operatorOnline?.name?.toLowerCase() === element?.operator?.toLowerCase()){
                classChatMassage += " float-right";
            } else {
                classChatMassage += " float-left";
            }

            //classChatMassage += randomNumber === 0 ? " float-left" : " float-right";
            return <div key={element.id} className={classChatMassage}>

                <Text strong>{element.operator}</Text>
                <div>
                    <Text>{element.text}</Text>
                </div>
                <Text className={"float-right"}>{Utils.getDatetimeItFormatFromTimestamp(element.insertDate * 1000)}</Text>
                {
                    (element.status as string !== "Senza stato" && element.status as string !== PracticeMessageState.STATELESS)  && <div className={"state-label-container"}>
                        <Tag className={"state-label"}>
                            {t(element.status)}
                        </Tag>
                    </div>
                }
            </div>
        })
    }
    function onCLickStateLabel(labelType:PracticeMessageState){
        let foundContacts = Utils.getProgressData(PracticeProgressStatus.CONTATTATO,props.progress)
        let foundAppointment = Utils.getProgressData(PracticeProgressStatus.PRESO_APPUNTAMENTO,props.progress)
        if( ((isNil(foundContacts) === false && labelType === PracticeMessageState.CONTATTATO) || (isNil(foundAppointment) === false  && labelType === PracticeMessageState.PRESO_APPUNTAMENTO)) === false ){
            let operator:Employee|null = AuthService.getOperatorOnline();
            saveProceed(labelType,operator);
        }

    }
    return <div className={"practices-details-columns"}>
            <Flex vertical justify={"center"} align={"center"} gap={"small"} style={{ width: '100%' }}>
                <Flex justify={"space-between"} align={"center"} gap={"large"} style={{ width: '100%' }}>
                    <Text strong>Contatti</Text>
                    <Button shape="circle" icon={<CloseCircleFilled style={{fontSize: '30px'}} />} onClick={props.onClickExit}/>
                </Flex>
                <SpinLuxottica loading={isLoading} ></SpinLuxottica>

                <div className={"chat-container"}>
                        <Space direction="vertical" size="middle" className={"messages-container"}>
                            {
                                isNil(data) || data?.length === 0 && <EmptyLuxottica></EmptyLuxottica>
                            }
                            {
                                isNil(data) === false && data?.length !== 0 && drawChat()
                            }

                        </Space>
                    </div>

                <Flex vertical className={"width-100"}>
                    <MessageTextBox key="chatMessageTextBox" progress={props.progress} onClickButton={onClickMessageButton} showState={true}
                                    disable={false}
                                    onClickLabel={onCLickStateLabel}></MessageTextBox>

                </Flex>
            </Flex>

    </div>
}