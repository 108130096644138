import React, {useRef, useState} from "react";
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Paths} from "../../models/Paths";
import {NewPracticeForm, NewPracticeFormBody} from "../../components/NewPractice/NewPracticeForm";
import {AppUser} from "../../models/Auth/AppUser";
import {AuthService} from "../../services/AuthService";
import {PracticeType} from "../../models/Practice/Practice";
import {
    PreventiveDialogTrigger
} from "../../components/PracticesDetails/MiddleColumn/components/Preventive/PreventiveDialogTrigger";
import {
    ShelveCambioVisusBodyRequest,
    ShelveEstimateBodyRequest,
    UploadEstimateBodyRequest
} from "../../models/Practice/PracticeDetail";
import {PracticesHttpService} from "../../services/Http/PracticesHttpService";
import {parseFilenameFromHeader, saveFile} from "../../utils/files";
import {isNil} from "lodash";
import {NotificationService} from "../../services/NotificationService";
import {NewVisusModal} from "../../components/CambioVisus/NewVisusModal/NewVisusModal";
import {useModal} from "../../utils/modals";
import {Patient} from "../../models/Patient";
import {Button, Flex} from "antd";
import {useGetInsuranceProvidersQuery} from "../../store/states/InsuranceProvidersState";
import {InsuranceProviderEclaim} from "../../models/InsuranceProvider";
import {CambioVisusFormData} from "../../models/cambioVisus";

enum Phases {
    ASK_PATIENT_DATA,
    GENERATE_DOCS
}

export function NewDocsPage() {
    const [appUser, setAppUser] = useState<AppUser | null>(AuthService.getUser());
    const allowedInsuranceProvidersForGeneration = ["AON"];
    const {data: insuranceProviders, error, isLoading} = useGetInsuranceProvidersQuery([]);

    const patientData = useRef<NewPracticeFormBody>({});

    const currentInsuranceProvider: InsuranceProviderEclaim | undefined  = insuranceProviders?.find((ip:InsuranceProviderEclaim) => ip.id == patientData.current.insuranceProviderId);

    const [currentPhase, setCurrentPhase] = useState(Phases.ASK_PATIENT_DATA);

    const initialValues = {
        locationCode: appUser?.defaultLocation?.code || null
    }

    const {
        isOpen,
        open, close
    } = useModal();

    const onPreventiveSubmit = async (data: UploadEstimateBodyRequest) => {
        if (isNil(patientData))
            return;

        const body: ShelveEstimateBodyRequest = {
            orders: data.orders,
            note: data.note,
            insuranceProviderId: patientData.current.insuranceProviderId!,
            refundType: PracticeType.DIRECT,
            locationCode: patientData.current.locationCode!,
            patientCF: patientData.current.patientCF!,
            patientFirstName: patientData.current.patientFirstName!,
            patientLastName: patientData.current.patientLastName!,
            email: patientData.current.email,
            phonenumber: patientData.current.phonenumber!
        }

        try {
            const response = await PracticesHttpService.shelveEstimate(body);
            const contentDisposition = response.headers["content-disposition"];
            const filename = parseFilenameFromHeader(contentDisposition);
            saveFile(response.data, filename);
        } catch (e) {
            console.error(e);
            NotificationService.getInstance().openNotificationError("Impossibile generare il preventivo");
        }
    }

    const savePatientData = (data: NewPracticeFormBody) => {
        patientData.current = data;
        setCurrentPhase(Phases.GENERATE_DOCS)
    }

    const getPatientData = (): Patient => {
        return {
            cf: patientData.current.patientCF!,
            name: `${patientData.current.patientLastName} ${patientData.current.patientFirstName}`,
            email: patientData.current.email ?? '',
            phone: patientData.current.phonenumber!
        }
    }

    const onSubmit = async (data: CambioVisusFormData) => {
        if (isNil(patientData))
            return;

        const body: ShelveCambioVisusBodyRequest = {
            data,
            insuranceProviderId: patientData.current.insuranceProviderId!,
            refundType: PracticeType.DIRECT,
            locationCode: patientData.current.locationCode!,
            patientCF: patientData.current.patientCF!,
            patientFirstName: patientData.current.patientLastName!,
            patientLastName: patientData.current.patientLastName!,
            email: patientData.current.email,
            phonenumber: patientData.current.phonenumber!
        }

        try {
            const response = await PracticesHttpService.shelveCambiovisus(body);
            const contentDisposition = response.headers["content-disposition"];
            const filename = isNil(contentDisposition) ? 'cambio_visus.pdf' : parseFilenameFromHeader(contentDisposition);
            saveFile(response.data, filename);
        } catch (e) {
            console.error(e);
            NotificationService.getInstance().openNotificationError("Impossibile generare il cambio visus");
        }
    }

    const renderContent = () => {
        if (currentPhase == Phases.ASK_PATIENT_DATA) {
            return <>
                <div>Prima di tutto inserisci i dati del paziente</div>
                <NewPracticeForm initialValues={initialValues}
                                 onSubmit={savePatientData}
                                 allowedInsuranceProviders={allowedInsuranceProvidersForGeneration}/>
            </>
        } else if (currentPhase == Phases.GENERATE_DOCS) {
            return <Flex gap={10}>
                <PreventiveDialogTrigger isLoaded={false}
                                         onSubmit={onPreventiveSubmit}
                                         agreement={PracticeType.DIRECT}
                                         integrationCode={currentInsuranceProvider!.integrationCode!}
                                         insuranceProviderId={patientData.current.insuranceProviderId!}
                                         locationCode={patientData.current.locationCode!}
                />
                <Button type={"primary"} onClick={open}>Cambio visus/Ricetta Oculistica</Button>
                <NewVisusModal shopGroup={"SV"}
                               isModalOpen={isOpen}
                               onCancel={close}
                               onSubmit={onSubmit}
                               patient={getPatientData()}
                               insuranceProvider={currentInsuranceProvider!}/>
            </Flex>
        }
    }

    return <>
        <PageTitle title="Crea documentazione" backTo={Paths.HOME}/>

        {renderContent()}
    </>
}