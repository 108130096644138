import {Col, Row} from "antd";
import {AgreementVoucher} from "../AgreementVoucher/AgreementVoucher";
import {AgreementActions} from "../AgreementActions/AgreementActions";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../models/Paths";

export interface AgreementRowProps {
    insuranceProviderId: number;
    agreement: string;
    visus: string;
    refundCondition: string;
    code: number;
    id:number;
    locationGroupCode: string;
}

export function AgreementRow(props: AgreementRowProps) {
    const navigate = useNavigate();

    const handleClickDiscountButton = () => {
        navigate(`${Paths.DISCOUNT}/${props.id}/${props.code}`)
    }
    const onClickShopActivitiesButton = () => {
        navigate(`${Paths.SHOP_ACTIVITIES}/${props.id}`)
    }

    return (
        <>
            <Row className={"agreement-row"} justify={"space-between"} align={"middle"}
                 gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col span={16}>
                    <AgreementVoucher
                        locationGroupCode={props.locationGroupCode}
                        organizationId={props.insuranceProviderId}
                        agreementType={props.agreement}
                        changeVisus={props.visus}
                        refundCondition={props.refundCondition}
                        discountCode={props.code}
                    />
                </Col>
                <Col span={8}>
                    <AgreementActions
                        onClickDiscountButton={handleClickDiscountButton}
                        onClickShopActivitiesButton={onClickShopActivitiesButton}
                    />
                </Col>
            </Row>
        </>
    )
}