import {Form, Input} from "antd";
export interface FiscalCodeFieldProps{
    required?:boolean

}
export function FiscalCodeField(props:FiscalCodeFieldProps){
    const rules= [
        { required: props.required ?? false, message: 'Il campo codice fiscale è obbligatorio' },
        { pattern: /[A-Z0-9]{16}/, message: 'Il codice fiscale non è valido' },
        { max: 16, message: 'Il codice fiscale è troppo lungo' },
    ]

    return <>
        <Form.Item name={"patientCF"}
                   label={"Codice fiscale"}
                   rules={rules}
        >
            <Input

                placeholder="Codice fiscale"
                onInput={(e:any) => e.target.value = e.target.value.toUpperCase()}
            ></Input>
        </Form.Item>
    </>
}