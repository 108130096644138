import {SquareTile} from "../../components/Home/SquareTile/SquareTile";
import React from "react";
import {Flex} from "antd";
import './Home.css'
import {useNavigate} from "react-router-dom";
import {Paths} from "../../models/Paths";
import {IconHome} from "../../models/IconHome";
import {AuthService} from "../../services/AuthService";
import {useTranslation} from "react-i18next";
import {resetSortParameters, saveForm} from "../../store/states/FormState";
import {useDispatch} from "react-redux";
import {resetTodoListFilters, saveTodoListFilters} from "../../store/states/TodoListFiltersSlice";
import {saveDashboardFormFilters, saveDashboardSeries} from "../../store/states/DashboardFormFiltersState";

export function Home() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    function goInfoMenuPage() {
        navigate(Paths.INFOMENU,{ state: { from: '/home' }});
    }
    function goPracticesPage() {
        dispatch(saveForm({
            values: {},
        }));
        dispatch(resetSortParameters());
        navigate(Paths.PRACTICES,{ state: { from: '/home' }});
    }
    function goDashboardPage() {
        dispatch(saveDashboardFormFilters({
            values: {},
        }));
        dispatch(saveDashboardSeries({
            values: {},
        }));
        navigate(Paths.DASHBOARD,{ state: { from: '/home' }});
    }
    function goTodoPage() {
        dispatch(saveForm({
            values: {},
        }));
        dispatch(resetSortParameters());
        dispatch(resetTodoListFilters());
        navigate(Paths.TODO,{ state: { from: '/home' }});
    }
    function newPractice(){
        navigate(Paths.NEW,{ state: { from: '/home' }});
    }

    return <div className={"home-container"}>

        <div className={"row-container"}>
            <Flex justify={"space-around"} align={"center"}>
                <div className={"tile-container"}>
                    <SquareTile title={t("practices")} description={"Puoi trovare la lista di tutte le pratiche"}
                                icon={IconHome.PRACTICES} onCLick={() => {
                        goPracticesPage()
                    }}></SquareTile>
                </div>
                <div className={"tile-container"}>
                    <SquareTile title={"Nuovo"} description={"Puoi creare nuove pratiche o generare documenti"}
                                icon={IconHome.ADD_PRACTICES} onCLick={() => {
                        newPractice()
                    }}></SquareTile>
                </div>
            </Flex>

        </div>


        <div className={"row-container"}>
            <Flex justify={"space-around"} align={"center"} gap={"middle"}>
                {AuthService.isAreaManager() &&
                    <div className={"tile-container"}
                        // hidden={AuthService.isUseAdmin() === false}
                    >

                        <SquareTile title={"Dashboard"}
                                    description={"Puoi visualizzare i dati statici dei negozi"}
                                    icon={IconHome.DASHBOARD} onCLick={() => {
                            goDashboardPage()
                        }}></SquareTile>
                    </div>}
                <div className={"tile-container"}>
                    <SquareTile title={"To do list"}
                                description={"Puoi trovare tutte le pratiche e le azioni che stanno per scadere"}
                                icon={IconHome.TODO_LIST} onCLick={() => {
                        goTodoPage()
                    }}></SquareTile>
                </div>
                <div className={"tile-container"}>
                    <SquareTile title={"Info"} description={" Puoi consultare la documentazione, i manuali assicurativi e i video tutorial"}
                                icon={IconHome.INFO} onCLick={() => {
                        goInfoMenuPage()
                    }}></SquareTile>
                </div>
            </Flex>

        </div>



    </div>
}
