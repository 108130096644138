import {Button, Flex, Typography} from "antd";
import {ButtonCheckBox} from "../../../../Common/ButtonCheckbox/ButtonCheckBox";
import {PracticesHttpService} from "../../../../../services/Http/PracticesHttpService";
import {useParams} from "react-router-dom";
import {ClaimStatuses, PracticeProgress, ProceedNextRequest} from "../../../../../models/Practice/PracticeDetail";
import {
    PracticeProgressStatus,
    PracticeType
} from "../../../../../models/Practice/Practice";
import {Employee} from "../../../../../models/Auth/Employee";
import {AuthService} from "../../../../../services/AuthService";
import {useState} from "react";
import {VoucherType} from "../../../../../models/Practice/Voucher";
import {isNil, without} from "lodash";
import {Utils} from "../../../../../services/Utils";

export interface UploadVouchersProps{
    onClickSavePurchase:()=>void
    progress: PracticeProgress[] | undefined,
    status: PracticeProgressStatus;
    claimStatus:ClaimStatuses,
    agreement:PracticeType,
    invoiceUploaded: boolean
}
export function UploadVouchers (props:UploadVouchersProps){
    let {id} = useParams();
    const { Text} = Typography;
    const [listOfPurchases,setListOfPurchases] = useState<VoucherType[]>([]);
    const[disableConfirmButton, setDisableConfirmButton] = useState<boolean>(true)
    function changeStatus (){
        let operator:Employee|null = AuthService.getOperatorOnline();
        let bodyVoucher:ProceedNextRequest = { metadata :{
                operator:operator?.name ?? "",
                listOfPurchases:listOfPurchases
            }, event: PracticeProgressStatus.ACQUISTO_EFFETTUATO }
        PracticesHttpService.saveProceedNext(id ?? "",bodyVoucher).then(()=>{
            props.onClickSavePurchase()
        })
    }

    function onCLickVista(value:boolean){
        if(value){
            listOfPurchases.push(VoucherType.VISTA)
            setListOfPurchases(listOfPurchases);
            isDisableConfirmButton(listOfPurchases)

        } else {
            const newArray = without(listOfPurchases, VoucherType.VISTA);
            setListOfPurchases(newArray);
            isDisableConfirmButton(newArray)

        }
    }
    function onCLickSole(value:boolean){
        if(value){
            listOfPurchases.push(VoucherType.SOLE)
            setListOfPurchases(listOfPurchases);
            isDisableConfirmButton(listOfPurchases)

        } else {
            const newArray = without(listOfPurchases, VoucherType.SOLE);
            setListOfPurchases(newArray);
            isDisableConfirmButton(newArray)

        }

    }
    function onCLickOthers(value:boolean){
        if(value){
            listOfPurchases.push(VoucherType.OTHERS)
            setListOfPurchases(listOfPurchases);
            isDisableConfirmButton(listOfPurchases)

        } else {
            const newArray = without(listOfPurchases, VoucherType.OTHERS);
            setListOfPurchases(newArray);
            isDisableConfirmButton(newArray)

        }

    }
    function isSelectedVista(){
        let found = false;
        let progress = Utils.getProgressData(PracticeProgressStatus.ACQUISTO_EFFETTUATO,props.progress);
        if(isNil(progress) === false){
            found = progress?.metadata?.listOfPurchases?.find((purchases:VoucherType)=>{
                return VoucherType.VISTA === purchases
            })
        }

        return found;
    }
    function isSelectedSole(){
        let found = false;
        let progress = Utils.getProgressData(PracticeProgressStatus.ACQUISTO_EFFETTUATO,props.progress);
        if(isNil(progress) === false){
            found = progress?.metadata?.listOfPurchases?.find((purchases:VoucherType)=>{
                return VoucherType.SOLE === purchases
            })
        }

        return found;
    }
    function isSelectedLAC(){
        let found = false;
        let progress = Utils.getProgressData(PracticeProgressStatus.ACQUISTO_EFFETTUATO,props.progress);
        if(isNil(progress) === false){
            found = progress?.metadata?.listOfPurchases?.find((purchases:VoucherType)=>{
                return VoucherType.OTHERS === purchases
            })
        }

        return found;
    }

    let isReadonly;

    /*
        INDIRETTE: acquisto vista, acquisto sole, lac e altro
        DIRETTE: acquisto vista, lac
     */
    let buttons;
    if (props.agreement == PracticeType.DIRECT) {
        isReadonly = !props.invoiceUploaded || props.claimStatus === ClaimStatuses.CLOSED;
        buttons = <>
            <ButtonCheckBox text={"Acquisto Vista"} readonly={isReadonly} onClick={onCLickVista}
                            selected={isSelectedVista()}></ButtonCheckBox>
            <ButtonCheckBox text={"LAC"} readonly={isReadonly} onClick={onCLickOthers} selected={isSelectedLAC()}></ButtonCheckBox>
        </>
    } else {
        isReadonly = (isNil(Utils.getProgressData(PracticeProgressStatus.ACQUISTO_EFFETTUATO,props.progress)) === false || props.claimStatus === ClaimStatuses.CLOSED);
        buttons = <>
            <ButtonCheckBox text={"Acquisto Vista"} readonly={isReadonly} onClick={onCLickVista}
                            selected={isSelectedVista()}></ButtonCheckBox>
            <ButtonCheckBox text={"Acquisto Sole"} readonly={isReadonly} onClick={onCLickSole} selected={isSelectedSole()}></ButtonCheckBox>
            <ButtonCheckBox text={"LAC e altro"} readonly={isReadonly} onClick={onCLickOthers} selected={isSelectedLAC()}></ButtonCheckBox>
        </>
    }
    function isDisableConfirmButton(list :VoucherType[]){
        let returnValue = false;
        if(list.length > 0){
            returnValue = false;
        } else {
            returnValue = true;
        }
        setDisableConfirmButton(returnValue)
    }
    return <div>
        <Text strong>Avvenuta vendita</Text>
        <Flex gap={"small"} className={"margin-y-10px"}>
            { buttons }
            { isReadonly === false && <Button disabled={disableConfirmButton} type={"primary"} onClick={changeStatus}> Conferma acquisti </Button> }
        </Flex>
    </div>
}
