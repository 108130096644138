import {Form, Select} from "antd";
import "./Filters.css"
import {SelectionValue} from "../../../../models/Common/SelectionValue";

export interface FilterShopsProps {
    shops: Array<SelectionValue>;
    onChange?:(valued:string|null)=>void;
    className?:React.HTMLAttributes<HTMLDivElement>['className'];
    required?:boolean;
    showLabel?:boolean;
    defaultValue?: string|null;
}
export function FilterShop(props:FilterShopsProps){
    const rules=[{ required: props.required ?? false, message: 'Il campo negozio è obbligatorio' }]

    const shopsOptions = getOptions(props.shops);

    function getOptions(shops:SelectionValue[]){
        const options = shops?.map((element:SelectionValue)=>{
            const regx = /^([0-9]{1,6})(.*)/
            const label = element.label;
            const match = regx.exec(label);
            let number = null;
            if (match != null) {
                try {
                    number = Number(match[1]);
                } catch(e) {}
            }
            return { value: element.value, label: label, number };
        });

        // @ts-ignore
        const numbered = options.filter(o => o.number != null).sort((a, b) => a.number - b.number);
        const notNumbered = options.filter(o => o.number == null).sort((a, b) => a.label.localeCompare(b.label));

        return [...numbered, ...notNumbered];
    }

    const handleChange = (value: string | null) => {
        if(props?.onChange){
            props?.onChange(value)
        }
    };

    function getClassName(){
        return "filter " + (props?.className ?? "")
    }

    return <>
        <Form.Item name={"locationCode"}
                   label={props.showLabel && "Negozio"}
                   initialValue={props.defaultValue}
                   rules={rules}>
            <Select
                showSearch
                placeholder="Negozio"
                className={getClassName()}
                options={shopsOptions}
                onChange={handleChange}
                optionFilterProp="label"
                allowClear={true}
            />
        </Form.Item>

    </>
}
