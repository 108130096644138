import axios, {AxiosInstance, isAxiosError} from "axios";
import {EnvService} from "../EnvService";
import {AuthService} from "../AuthService";
import {NavigateFunction, Location} from "react-router-dom";
import {CognitoService} from "../CognitoService";
import {NotificationService} from "../NotificationService";
import {AppUser} from "../../models/Auth/AppUser";
import {isNil} from "lodash";
import {Employee} from "../../models/Auth/Employee";

export class HttpService {

  private static axiosInstance: AxiosInstance;

  private static MAX_REFRESH_TRIES = 3;

  static createLuxotticaAxios(location: Location, navigate: NavigateFunction) {
    let customAxios = axios.create({
      baseURL: EnvService.getBackEndUrl(),
    });

    customAxios.interceptors.request.use(config => {
      const authToken = AuthService.getUserTokens();
      if (authToken != null && !config.url?.startsWith('/cognito/config'))
        config.headers.Authorization = `Bearer ${authToken}`;

      let operator: Employee|null = AuthService.getOperatorOnline()
      if(isNil(operator) === false){
        config.headers['x-auth-operator'] = operator?.id;

      }
      return config;
    })

    let refreshTries = 0;

    customAxios.interceptors.response.use(
        response => response,
        async error => {
          let errorMessage = "Errore, impossibile risolvere la richiesta.";
          if (isAxiosError(error)) {
            if (error.response?.status === 401 && (error.config && error.config.url != '/login')) {
              if (refreshTries == HttpService.MAX_REFRESH_TRIES) {
                refreshTries = 0;
                AuthService.clearSession();
                navigate('/login');
              } else {
                try {
                  refreshTries++;
                  await CognitoService.get().doLogin();
                  return customAxios.request(error.config);
                } catch(e) {
                  refreshTries = 0;
                  AuthService.clearSession();
                  navigate('/login');
                }
              }
            }
            if (error.response?.status === 404) {
              errorMessage = "Errore, risorsa non raggiungibile.";
            }
          }
          NotificationService.getInstance().openNotificationError(errorMessage);
          throw error;
        }
    );

    this.axiosInstance = customAxios;
  }

  static getLuxotticaAxios() {
    if (this.axiosInstance == null)
      throw new Error("Axios instance is null, call createLuxotticaAxios to create the instance");

    return this.axiosInstance;
  }
}
