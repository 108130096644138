import {Form, Input} from "antd";
import { RuleObject } from 'antd/lib/form';

export interface PhoneNumberProps {
    required?:boolean
}
export function PhoneNumber(props:PhoneNumberProps) {
    const phoneNumberValidator = (rule: RuleObject, value: string) => {
        const phoneRegex = /^[0-9]{5,15}$/; // Esempio di regex per numeri telefonici
        if (!value || phoneRegex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject('Il campo numero telefonico deve essere un numero valido');
    };
    const rules=[
        { required: props.required ?? false, message: 'Il campo numero telefonico è obbligatorio' },
        { validator: phoneNumberValidator }

    ]

    return <>
        <Form.Item name={"phonenumber"} label="Telefono" rules={rules}>
            <Input placeholder="Telefono"/>
        </Form.Item>
    </>
}