import {Form, Input} from "antd";

export function FilterClaimCode() {
    const className = "filter"

    return <>
        <Form.Item name={"claimCode"}>
            <Input className={className} placeholder="Codice claim" allowClear={true}/>
        </Form.Item>
    </>
}