import {Button, Flex, Typography} from "antd";
import Title from "antd/es/typography/Title";
import "../PracticesDetailsColumns.css"
import {PracticeType} from "../../../models/Practice/Practice";
import {TransformPractice} from "../TransformPractice/TransformPractice";
import {useClaimStatus, claimClosed} from "../ClaimStatusProvider";

export interface LeftColumnProps{
    client: string,
    phoneNumber?:number|string,
    email:string,
    fiscalCode:string,
    code:string
    onClickNoteButton:()=>void;
    onClickTransformToIndirect:()=>void;
    agreement: PracticeType
}
export function LeftColumn (props:LeftColumnProps){
    const { Text } = Typography;

    const claimStatus = useClaimStatus();

    return <div className={"practices-details-columns"}>
        <Title level={5} className={"title"}>Dati cliente</Title>
        <Flex vertical>
            <div>
                <Flex vertical className={"labels"}>
                    <Text strong>Cliente</Text>
                    <Text>{props.client}</Text>
                </Flex>
                <Flex vertical className={"labels"}>
                    <Text strong>Telefono</Text>
                    <Text>{props.phoneNumber}</Text>
                </Flex>
                <Flex vertical className={"labels"}>
                    <Text strong>E-mail</Text>
                    <Text>{props.email}</Text>
                </Flex>
                <Flex vertical className={"labels"}>
                    <Text strong>Codice Fiscale</Text>
                    <Text>{props.fiscalCode}</Text>
                </Flex>
            </div>

            <div className="bottom-container">
                <Flex vertical >
                    <p>
                        Codice Manydesigns<br/>
                        <Text italic>{props.code}</Text>
                    </p>
                    <Flex align="flex-start" gap="small" vertical>
                        { props.agreement == PracticeType.DIRECT && <TransformPractice disabled={claimClosed(claimStatus)} onClickTransformToIndirect={props.onClickTransformToIndirect}></TransformPractice> }
                        <Button type={"primary"} className="button" onClick={props.onClickNoteButton}>Note</Button>
                    </Flex>
                </Flex>

            </div>

        </Flex>


    </div>
}