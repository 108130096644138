import {TableLuxottica} from "../Common/TableLuxottica/TableLuxottica";
import {TableProps} from "antd";
import {Utils} from "../../services/Utils";
import {Patient} from "../../models/Patient";
import {InsuranceProvider} from "../../models/InsuranceProvider";
import {Practice, PracticeRow} from "../../models/Practice/Practice";
import {IconButton} from "../Common/IconButton/IconButton";
import {CaretRightOutlined} from "@ant-design/icons";
import {IconLuxotticaDimension} from "../../models/IconLuxotticaDimension";
import {useTranslation} from "react-i18next";
import {PracticesExpandedInfo} from "../Common/PracticesExpandedInfo/PracticesExpandedInfo";
import {SorterResult} from "antd/es/table/interface";

export interface PracticeTableProps {
    orderedColumn?: string;
    orderedColumnDirection?: 'ascend'|'descend';
    data: Array<Practice>;
    loading: boolean;
    goToDetail: (id: number) => void;
    onSort: (info: SorterResult<any> | SorterResult<any>[]) => void;
    onScrollLastRow: () => void;
    hideStatus?: boolean,
    getScrollY?: () => number|undefined;
}

export function PracticeTable({
                                  orderedColumn,
                                  orderedColumnDirection,
                                  data,
                                  loading,
                                  goToDetail,
                                  onSort,
                                  onScrollLastRow,
                                  hideStatus = false,
                                  getScrollY = () => undefined
                              }: PracticeTableProps) {
    const {t, i18n} = useTranslation();

    const extractDefaultSortOrder = (columnName:string) => {
        return (orderedColumn === columnName) ? orderedColumnDirection: null;
    }

    let columns: TableProps<Practice>['columns'] = [
        {
            title: 'Creazione Voucher',
            dataIndex: 'creationDate',
            key: 'creationDate',
            sorter: true,
            defaultSortOrder: extractDefaultSortOrder('creationDate'),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (value: number) => {
                return <>{Utils.getDateItFormatFromTimestamp(value)}</>
            }
        }, {
            title: 'Cliente',
            dataIndex: 'patient',
            key: 'patient',
            sorter: true,
            defaultSortOrder: extractDefaultSortOrder('patient'),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (item: Patient) => {
                return <>{item.name}</>
            },
        }, {
            title: 'Convenzione',
            dataIndex: 'insuranceProvider',
            key: 'insuranceProvider',
            sorter: true,
            defaultSortOrder: extractDefaultSortOrder('insuranceProvider'),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (item: InsuranceProvider) => {
                return <>{item.description}</>
            }
        }, {
            title: 'Gestione',
            dataIndex: 'agreement',
            key: 'agreement',
            sorter: true,
            defaultSortOrder: extractDefaultSortOrder('agreement'),
            render: (value: string) => {
                return <>{t(value)}</>
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
        }, {
            title: 'Negozio',
            dataIndex: 'shopName',
            key: 'shopName'
        }
    ]

    if (!hideStatus) {
        columns.push({
            title: 'Stato Avanzamento',
            dataIndex: 'status',
            key: 'status',
            render: (value: string) => {
                return <>
                    {t(value)}
                </>
            }
        })
    }

    columns.push({
        title: '',
        render: (record: PracticeRow) => {
            return <>
                <IconButton icon={<CaretRightOutlined/>}
                            dimension={IconLuxotticaDimension.SMALL}
                            onCLick={() => {
                                goToDetail(record.id)
                            }}></IconButton>
            </>
        }
    })

    const scrollY = getScrollY();

    const getExpandedInfo = (record: PracticeRow) => {
        return <PracticesExpandedInfo record={record}></PracticesExpandedInfo>
    }

    return <>
        <TableLuxottica columns={columns}
                        data={data}
                        loading={loading}
                        expandable={true}
                        expandedRowComponent={getExpandedInfo}
                        onSort={onSort}
                        onScrollLastRow={onScrollLastRow}
                        scrollY={scrollY}
        />
    </>
}