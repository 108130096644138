import Sider from "antd/es/layout/Sider";
import "./SideBar.css"
import React, {useEffect, useState} from "react";
import {Flex} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {Paths} from "../../models/Paths";
import {IconButton} from "../Common/IconButton/IconButton";
import {IconLuxotticaDimension} from "../../models/IconLuxotticaDimension";
import {IconHome} from "../../models/IconHome";
import {AuthService} from "../../services/AuthService";
import {includes} from "lodash";
import {saveForm, resetSortParameters} from "../../store/states/FormState";
import {resetTodoListFilters, saveTodoListFilters} from "../../store/states/TodoListFiltersSlice";
import {useDispatch} from "react-redux";
import {saveDashboardFormFilters, saveDashboardSeries} from "../../store/states/DashboardFormFiltersState";
import {TodoFilterTiles} from "../TodoList/NewTodoListFilters";


export function SideBar(){
    let location = useLocation();
    const navigate = useNavigate();
    const [isVisibleSideBar,setIsVisibleSideBar] = useState<boolean>(false);
    const [lastClickedButton,setLastClickedButton] = useState<Paths>(Paths.HOME);
    const dispatch = useDispatch();

    const isAreaManager = AuthService.isAreaManager();

    useEffect(() => {
        let value = showSidebar(location.pathname);
        setIsVisibleSideBar(value)
        setLastClickedButton(location.pathname as Paths)
    }, [location]);

    function showSidebar(pathname:string){
        return pathname !== Paths.HOME;
    }

    function goInfoMenuPage() {
        navigate(Paths.INFOMENU);
    }
    function goAddPractice() {
        navigate(Paths.NEW)
    }
    function goPractices() {
        dispatch(saveForm({
            values: {},
        }));
        dispatch(resetSortParameters());
        navigate(Paths.PRACTICES);
    }
    function goDashboard() {
        dispatch(saveDashboardFormFilters({
            values: {},
        }));
        dispatch(saveDashboardSeries({
            values: {},
        }));
        navigate(Paths.DASHBOARD)
    }
    function goTodoList() {
        dispatch(saveForm({
            values: {},
        }));
        dispatch(resetSortParameters());
        dispatch(resetTodoListFilters());
        navigate(Paths.TODO)
    }
    function isSelectedPractice(){
        //return ((lastClickedButton === Paths.NEW_PRACTICES ) || (lastClickedButton === Paths.PRACTICES_DETAILS))
        return ((lastClickedButton === Paths.PRACTICES ) || (includes(lastClickedButton,Paths.PRACTICES_DETAILS)  ))
    }

    function isSelectedInfo() {
        let returnValue = false;
        switch (lastClickedButton) {
            case Paths.INFOMENU:
            case Paths.INFO:
            case Paths.MANUAL:
            case Paths.VIDEO:
            case Paths.SHOP_ACTIVITIES :
                returnValue = true
                break;
        }
        return returnValue;
    }

    const isSelectedNew = location.pathname.startsWith(Paths.NEW);

    return <>

        {
            isVisibleSideBar ?
                <Sider
                    width="6%"
                    className={"side-bar"} >

                    <Flex vertical justify={"space-evenly"} align={"center"} className={"buttons-container"}>
                        <IconButton icon={IconHome.PRACTICES} dimension={IconLuxotticaDimension.LARGE} onCLick={goPractices} selected={isSelectedPractice()}></IconButton>
                        <IconButton icon={IconHome.ADD_PRACTICES} dimension={IconLuxotticaDimension.LARGE} onCLick={goAddPractice} selected={isSelectedNew}></IconButton>
                        <IconButton icon={IconHome.DASHBOARD} dimension={IconLuxotticaDimension.LARGE} onCLick={goDashboard} selected={lastClickedButton === Paths.DASHBOARD} hide={!isAreaManager}></IconButton>
                        <IconButton icon={IconHome.TODO_LIST} dimension={IconLuxotticaDimension.LARGE} onCLick={goTodoList} selected={lastClickedButton === Paths.TODO}></IconButton>
                        <IconButton icon={IconHome.INFO} dimension={IconLuxotticaDimension.LARGE} onCLick={goInfoMenuPage} selected={isSelectedInfo()}></IconButton>

                    </Flex>

                </Sider> : null
        }
    </>
}