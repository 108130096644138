import {ReactElement} from "react";
import {IconLuxotticaDimension} from "../../../models/IconLuxotticaDimension";

export interface IconLuxotticaProps{
    icon:ReactElement<any, any>;
    dimension?:IconLuxotticaDimension
    className?: string
}
export function IconLuxottica ({icon, dimension = IconLuxotticaDimension.MEDIUM, className}: IconLuxotticaProps){
    const actualClassName = `luxottica-icon luxottica-icon-${dimension} ` + (className ?? "")

    return <>
        <span className = {actualClassName}>
            {icon}
        </span>
    </>
}