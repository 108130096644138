import {PageTitle} from "../../Common/PageTitle/PageTitle";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Document, Page} from 'react-pdf';
import {useState} from "react";
import "./Manual.css";
import { Flex} from "antd";
import {ZoomPdf} from "./components/ZoomPdf";
import {PagePdf} from "./components/PagePdf";

export type PDFFile = string | File | null;

export function Manual(){
    let {integrationCode} = useParams();
    const { t, i18n } = useTranslation();

    let file = `/manuals/${integrationCode}.pdf`;
    if (integrationCode == 'INSALUTE') {
        file = `/manuals/BLUEASSISTANCE.pdf`
    }

    const [numPages, setNumPages] = useState<number>();
    const [scale,setScale] = useState<number>(2);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    function onClickZoom(value:number){
        setScale(value);
    }

    return <div className={"manual-page"}>
        <Flex justify={'space-between'} align={'center'}>
            <PageTitle title={t("manual")} />
            <Flex gap={"small"}>
                <PagePdf numberOfPages={numPages}></PagePdf>
                <ZoomPdf initialZoom={scale} onClickZoom={onClickZoom}></ZoomPdf>
            </Flex>
        </Flex>
        <div id="scrollArea">
            <Document
                className={"document"}
                file={file}
                loading={<>Caricamento...</>}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            scale={scale}
                            height={620}
                            loading={<></>}
                            pageNumber={index + 1}
                        />
                    )
                )}
            </Document>
        </div>

    </div>
}