import {Spin} from "antd";
export interface SpinLuxotticaProps{
    children?: React.ReactNode;
    loading?:boolean
}
export function SpinLuxottica (props:SpinLuxotticaProps){
    return <>
        <Spin tip="Caricamento..." spinning={props.loading ?? false}>
            {props.children}
        </Spin></>
}