import {VoucherDetail} from "../../../../models/Info/voucher.model";
import DOMPurify from "dompurify";
import {Flex, Typography} from "antd";

export interface PracticeDiscountProps {
    agreementDetail: VoucherDetail
}
export function PracticeDiscount(props:PracticeDiscountProps){
    const { Text } = Typography;

    const sanitizedTitle = DOMPurify.sanitize(props.agreementDetail.title);
    const sanitizedBody = DOMPurify.sanitize(props.agreementDetail.text);

    return (
        <>
            <Flex vertical >
                <Text strong>
                    <span dangerouslySetInnerHTML={{__html: sanitizedTitle}}></span>
                </Text>
                <Flex vertical>
                    <div dangerouslySetInnerHTML={{__html: sanitizedBody}}/>
                </Flex>
            </Flex>
        </>
    )
}