import {HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

export class OperatorsHttpService {

    static URL_OPERATORS_FILE_UPLOAD = "/operators/upload"

    static async uploadPracticeFiles(formData:FormData):Promise<AxiosResponse<any, any>>{
        return await HttpService.getLuxotticaAxios().post<any>(this.URL_OPERATORS_FILE_UPLOAD, formData)
    }

}
