import { Button, Flex, Form, Input } from "antd";
import { SubmitFunction } from "../../LoginForm";
import LuxotticaPassword from "../../LuxotticaPassword";
import {RuleRender} from "rc-field-form/lib/interface";

export interface AskCodeProps {onSubmit: SubmitFunction<{code: string, newPassword: string}>}

const AskCode = (props: AskCodeProps) => {
  const confirmPasswordRule: RuleRender = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Le password che hai inserito non sono uguali!'));
    },
  });

  return (
    <Form key="recoverPassword-askCode" layout="vertical" onFinish={props.onSubmit} autoComplete="off">
      <Flex gap="middle" align="center" justify="center" vertical>
        <Form.Item name="code" label="Inserire il codice ricevuto per email" rules={[{ required: true, message: 'Inserire codice'}]} style={{width: '100%'}}>
          <Input.OTP size="large" />
        </Form.Item>
        <LuxotticaPassword name="newPassword"
                           label="Nuova password"
                           followPasswordPolicy={true}
                           style={{width: '100%'}}/>
        <LuxotticaPassword name="newPasswordConfirm"
                           placeholder="Conferma Password"
                           followPasswordPolicy={true}
                           customRules={[confirmPasswordRule]}
                           style={{width: '100%'}}/>
        <Form.Item>
          <Button className={"luxottica-button-primary"} htmlType="submit">
            Cambia password
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  )
}

export default AskCode;