import {Dashboard} from "../../../models/Dashboard/Dashboard";
import {Flex, Typography} from "antd";

export interface PieChartLegendProps{
    data:Dashboard|undefined;
}
export function PieChartLegend(props:PieChartLegendProps){
    const { Text } = Typography;
    function getTotal():number{
        let total = 0;
        total += props.data?.toBeContacted ?? 0;
        total += props.data?.closedWithoutSale ?? 0;
        total += props.data?.inProgress ?? 0;
        total += props.data?.purchaseMade ?? 0;
        total += props.data?.expired ?? 0;
        return total;
    }
    return<>
           <Flex vertical>
               <Typography.Title level={4} style={{ margin: 0 }}>
                  Totale {getTotal()}
               </Typography.Title>
               <Text> Attive: {props.data?.actives}</Text>
               <Text> Completate: {props.data?.finished}</Text>
               <Text> Chiuse senza vendita: {props.data?.closedWithoutSale}</Text>
               <Text> Scadute: {props.data?.expired}</Text>
           </Flex>
    </>
}