import Title from "antd/es/typography/Title";
import {Flex, Typography} from "antd";
import {Utils} from "../../../../services/Utils";
import {PracticeProgressStatus, PracticeType} from "../../../../models/Practice/Practice";
import {PracticeDetail} from "../../../../models/Practice/PracticeDetail";
import {useTranslation} from "react-i18next";
import {UploadVouchers} from "./UploadVouchers/UploadVouchers";
import {UploadFilesLiquidation} from "./UploadFilesLiquidation/UploadFilesLiquidation";
import React, {useEffect, useState} from "react";
import {PracticeFileRow} from "../../../../models/Practice/PracticeFIle";
import {PracticesHttpService} from "../../../../services/Http/PracticesHttpService";
import {useParams} from "react-router-dom";
import {UploadFilesWarrantyActivation} from "./UploadFilesWarrantyActivation/UploadFilesWarrantyActivation";
import {UploadDocuments} from "./UploadDocuments/UploadDocuments";
import {LoadedFilesTable} from "./LoadedFilesTable/LoadedFilesTable";
import {NotificationService} from "../../../../services/NotificationService";
import {saveFile} from "../../../../utils/files";

export interface DetailPartProps {
    practiceDetail: PracticeDetail;
    changeVisus?: string,
    onClickSavePurchase:() => void;
    onClickConfirmDocs:() => void;
    reloadDetail: () => Promise<void>;
    onSaveFile: () => void;
    attachedFiles: Array<PracticeFileRow>;
}

export function DetailPart(props: DetailPartProps) {
    const { Text } = Typography;
    const { t, i18n } = useTranslation();
    const { id } = useParams();

    const insuranceProvider = props.practiceDetail.insuranceProvider;
    const convention = insuranceProvider.description;
    const progress = props.practiceDetail.progress;

    const manage = props.practiceDetail.agreement;

    useEffect(() => {
        props.onSaveFile();
    }, []);

    const isDisableFilesLiquidationButton = () => {
        let foundPicAuth = Utils.getProgressData(PracticeProgressStatus.PIC_AUTORIZZATA, progress);
        let foundAttachedDoc = Utils.getProgressData(PracticeProgressStatus.DOCUMENTAZIONE_INVIATA, progress);
        let checkAon = insuranceProvider.integrationCode === 'AON' || foundAttachedDoc;
        return !(foundPicAuth && checkAon);
    };

    const deleteFile = async (attachmentId:number) => {
        try {
            await PracticesHttpService.deletePracticeFile(id,attachmentId)
            props.onSaveFile();
        } catch (error) {
            console.error(error);
            NotificationService.getInstance().openNotificationError("Impossibile cancellare l'allegato");
        }
    };

    const downloadFile = async (attachmentId: number, filename: string) => {
        try {
            const response = await PracticesHttpService.downloadPracticeFile(id,attachmentId)
            saveFile(response.data, filename);
        } catch (e) {
            console.error(e);
            NotificationService.getInstance().openNotificationError("Impossibile scaricare l'allegato");
        }
    };

    const detail = () => {
        if (manage === PracticeType.DIRECT) {
            const lensComplainceRequired = ["BLUEASSISTANCE", "INSALUTE"].includes(insuranceProvider.integrationCode);
            const signedPicRequired = ["BLUEASSISTANCE", "INSALUTE", "CASPIE", "GENERALI"].includes(insuranceProvider.integrationCode);
            const declarationRequired = ["POSTE","POSTEWELFARE"].includes(insuranceProvider.integrationCode);
            const isDisable:boolean = isDisableFilesLiquidationButton();

            return <>
                <Flex wrap="wrap" gap="middle">
                    <Flex vertical className={"labels width-40"}>
                        <Text strong>Stato PIC</Text>
                        <Text>{props.practiceDetail.picStatus}</Text>
                    </Flex>
                    <Flex vertical className={"labels width-40"}>
                        <Text strong>Numero PIC</Text>
                        <Text>{props.practiceDetail.picNumber}</Text>
                    </Flex>
                </Flex>
                <UploadFilesWarrantyActivation patient={props.practiceDetail.patient}
                                               convention={convention}
                                               locationCode={props.practiceDetail.shopName}
                                               shopGroup={props.practiceDetail.shopGroup}
                                               agreement={props.practiceDetail.agreement}
                                               insuranceProvider={insuranceProvider}
                                               onClickConfirmDocs={props.onClickConfirmDocs}
                                               files={props.attachedFiles}
                                               loadFiles={props.onSaveFile}
                                               progress={progress}
                                               status={props.practiceDetail.status}
                                               reloadDetail={props.reloadDetail}
                />
                <Flex>
                    <UploadFilesLiquidation files={props.attachedFiles} lensComplainceRequired={lensComplainceRequired}
                                            signedPicRequired={signedPicRequired}
                                            declarationRequired={declarationRequired}
                                            insuranceProvider={insuranceProvider}
                                            loadFiles={props.onSaveFile} disableButtons={isDisable}
                                            progress={progress} status={props.practiceDetail.status}
                                            reloadDetail={props.reloadDetail}
                    />
                </Flex>
                <div className={"margin-y-10px"}>
                    <UploadDocuments onDocumentUpload={props.onSaveFile} insuranceProvider={insuranceProvider}></UploadDocuments>
                </div>
                <Text strong>Documenti caricati </Text>
                <LoadedFilesTable state={props.practiceDetail.status}
                                  claimStatus={props.practiceDetail.claimStatus}
                                  progress={progress}
                                  files={props.attachedFiles}
                                  deleteFileCallback={deleteFile}
                                  downloadFileCallback={downloadFile} />
            </>
        } else if (manage === PracticeType.INDIRECT) {
            return <>
                <Flex wrap="wrap" gap="middle">
                <Flex vertical className={"labels width-20"}>
                        <Text strong>Cambio visus</Text>
                        <Text>{props.changeVisus ?? "-"}</Text>
                    </Flex>
                </Flex>
                <UploadVouchers agreement={props.practiceDetail.agreement}
                                status={props.practiceDetail.status}
                                claimStatus={props.practiceDetail.claimStatus}
                                progress={progress}
                                invoiceUploaded={false}
                                onClickSavePurchase={props.onClickSavePurchase}/>
            </>
        } else
            return <></>
    }

    return <div className={"practices-details-columns"}>
        <Title level={5} className={"title"}>Dati assicurativi</Title>
        <Flex wrap="wrap" gap={"middle"}>
            <Flex vertical className={"labels width-20"}>
                <Text strong>Convenzione</Text>
                <Text>{convention}</Text>
            </Flex>
            <Flex vertical className={"labels width-20"}>
                <Text strong>Gestione</Text>
                <Text>{t(manage || "")}</Text>
            </Flex>
            <Flex vertical className={"labels width-20"}>
                <Text strong>Data voucher</Text>
                <Text>{Utils.getDateItFormat(props.practiceDetail.voucherDate)}</Text>
            </Flex>
            <Flex vertical className={"labels width-20"}>
                <Text strong>Stato</Text>
                <Text>{t(props.practiceDetail.claimStatus)}</Text>
            </Flex>
            <Flex vertical className={"labels width-100"}>
                <Text strong>Negozio</Text>
                <Text>{props.practiceDetail.shopName}</Text>
            </Flex>
        </Flex>
        { detail() }
    </div>
}
