export interface InsuranceProvider{
    id: number,
    description: string,
}

export interface InsuranceProviderEclaim{
    address: string,
    businessName:string,
    cap: string,
    city:string,
    civicNumber:number
    codFisc:string
    code:string
    description:string
    email:string
    expiringDeliverDateHospDays:number
    expiringSentDateHospDays:number
    integrationCode:string
    id:number
    insuranceType:string
    nationality:string
    pIva:string
    pec:string
    province:string
    sdiCode:string
    state:string
    telNumber:string
}


export enum InsuranceProviderIntegrationCodes{
    PREVIMEDICAL="PREVIMEDICAL",
    AON="AON",
    BLUEASSISTANCE="BLUEASSISTANCE",
    GRUPPOGENERALI="GRUPPOGENERALI",
    GENERALI="GENERALI",
    INSALUTE="INSALUTE",
    CASPIE="CASPIE",
    MARSH="MARSH",
    QUAS="QUAS",
    POSTE="POSTE",
    POSTEWELFARE="POSTEWELFARE"
}
