import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Paths} from "../../models/Paths";
import {SquareTile} from "../../components/Home/SquareTile/SquareTile";
import {PlusOutlined, FileAddOutlined} from "@ant-design/icons";
import {Flex} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";

export function NewPage() {
    const navigate = useNavigate();

    return <div className={"info-menu-container"}>
        <PageTitle title={"Nuova"} backTo={Paths.HOME}/>

        <Flex justify={"space-around"} align={"center"} gap={"middle"} className={"flex-container"}>
            <div className={"info-menu-square-tile"}>
                <SquareTile title={"Nuova pratica"}
                            description={"Puoi creare una nuova pratica"}
                            icon={<PlusOutlined/>}
                            onCLick={() => navigate(Paths.NEW_PRACTICES)}></SquareTile>
            </div>
            <div className={"info-menu-square-tile"}>
                <SquareTile title={"Crea documentazione"}
                            description={"Puoi creare un nuovo documento"}
                            icon={<FileAddOutlined/>}
                            onCLick={() => navigate(Paths.NEW_DOCS)}></SquareTile>
            </div>
        </Flex>
    </div>
}