import {AxiosResponse} from "axios";
import {HttpService} from "./HttpService";
import {
    Dashboard,
    DashboardFilter,
    DashboardResponse,
    ExportDashboardDataRequest
} from "../../models/Dashboard/Dashboard";

export class DashboardHttpService{
    static URL_DASHBOARD = "/claims/dashboardData"
    static URL_EXPORT_DATA = "/claims/export"

    static getDashboardData(values:DashboardFilter):Promise<AxiosResponse<DashboardResponse,any>>{
        return HttpService.getLuxotticaAxios().post<DashboardResponse>(this.URL_DASHBOARD,values);
    }

    static exportDashboardData(request: ExportDashboardDataRequest) {
        return HttpService.getLuxotticaAxios().post(this.URL_EXPORT_DATA, request, {responseType: 'blob'});
    }
}