import {AxiosInstance} from "axios";
import {HttpService} from "./Http/HttpService";
import {Amplify} from "aws-amplify";
import {fetchAuthSession} from "aws-amplify/auth";
import {AuthService} from "./AuthService";
import {LogService} from "./LogService";

interface CognitoConfigResponse {
    clientId: string;
    userPoolId: string;
}

export class CognitoService {

    private static instance: CognitoService;

    static createInstance() {
        this.instance = new CognitoService(HttpService.getLuxotticaAxios());
    }

    static get() {
        return this.instance;
    }

    private axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance
    }

    async loadConfig() {
        const {data} = await this.axiosInstance.get<CognitoConfigResponse>('/cognito/config?tenantCode=LUX')
        Amplify.configure({
            Auth: {
                Cognito: {
                    userPoolId: data.userPoolId,
                    userPoolClientId: data.clientId
                }
            }
        })
    }

    async doLogin() {
        const authTokens = await fetchAuthSession()
        if (authTokens.tokens) {
            const accessToken = authTokens.tokens.accessToken
            const username = accessToken.payload.username as string;
            const token = accessToken.toString()

            await AuthService.login({
                username: username,
                password: token,
                tenant: 'LUX'
            })
        } else {
            LogService.warning("Cannot find tokens");
        }
    }
}
