import { configureStore } from '@reduxjs/toolkit'
import {InsuranceProvidersApi} from "./states/InsuranceProvidersState";
import FormState from "./states/FormState";
import TodoListFiltersState from "./states/TodoListFiltersSlice";
import DashboardFormFiltersState from "./states/DashboardFormFiltersState";

export const store = configureStore({
    reducer: {
        [InsuranceProvidersApi.reducerPath]: InsuranceProvidersApi.reducer,
        practiceTableFilterForm: FormState,
        todoListFilters: TodoListFiltersState,
        dashboardFormFilters: DashboardFormFiltersState,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(InsuranceProvidersApi.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch