import {SubmitFunction, UserPasswordFormData} from "../LoginForm";
import {Alert, Button, Flex, Form, Input} from "antd";
import "../../../../styles/Buttons.css";
import LuxotticaPassword from "../LuxotticaPassword";

export interface UserPasswordFormProps {
    errorMessage: string | null;
    onSubmit: SubmitFunction<UserPasswordFormData>;
}

const UserPasswordForm = (props: UserPasswordFormProps) => {
    return (
        <Form key="loginStep" onFinish={props.onSubmit} autoComplete="off">
            <Flex gap="middle" align="center" justify="center" vertical>
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Inserisci il nome utente!' }]}
                    style={{width: '100%'}}
                >
                    <Input placeholder={"Username"}/>
                </Form.Item>
                <LuxotticaPassword name="password"
                                   placeholder="Password"
                                   followPasswordPolicy={false}
                                   style={{width: '100%'}}/>
                {props.errorMessage != null && <><Alert message={props.errorMessage} type="error" /><br/></>}
                <Form.Item>
                    <Button className={"luxottica-button-primary"} htmlType="submit">
                        Login
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    )
}

export default UserPasswordForm;
