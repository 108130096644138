import React, {createContext, useContext, ReactNode} from "react";

type ClaimStatusContextType = string;
const ClaimStatusContext = createContext<ClaimStatusContextType | undefined>(undefined);
type ClaimStatusProviderProps = {
    children: ReactNode;
    claimStatus: string;
};

export const ClaimStatusProvider = ({ children, claimStatus }: ClaimStatusProviderProps) => (
    <ClaimStatusContext.Provider value={claimStatus}>
        {children}
    </ClaimStatusContext.Provider>
);

export const useClaimStatus = () => {
    const context = useContext(ClaimStatusContext);
    if (context === undefined) {
        throw new Error('useClaimStatus deve essere usato all\'interno di un ClaimStatusProvider');
    }
    return context;
};

export const claimClosed = (status: string)=> {
    return status === 'CLOSED';
}