import {Button, Upload, UploadProps} from "antd";
import {
    UploadOutlined
} from "@ant-design/icons";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {UploadAttachment} from "../../../models/Practice/PracticeFIle";
import {NotificationService} from "../../../services/NotificationService";

export interface UploadFileProps{
    id:string|undefined,
    attachmentData : UploadAttachment,
    onUpload: ()=>void;
    beforeUpload: (file: File) => boolean;
    acceptTypes?: string;
}

export function UploadFile(props:UploadFileProps){
    const uploadConfigProps:UploadProps = {
        customRequest: async({ file, onSuccess, onError }) =>{
            const formData = new FormData();
            const attachmentData = Object.assign({}, props.attachmentData);
            attachmentData.filename = (file as File).name;
            formData.append('attachmentData', JSON.stringify(attachmentData));
            formData.append('file', file);
            try {
                const response = await PracticesHttpService.uploadPracticeFiles(props.id,formData);
                console.log("Upload File ", response);
                if (response.status === 200 && onSuccess) {
                    const compressed = response.data ? response.data.compressed : false;
                    const message = compressed ? "File compresso e salvato correttamente" : "File caricato correttamente";
                    onSuccess(response.data);
                    NotificationService.getInstance().openNotificationSuccess(message);
                } else {
                    throw new Error('Failed to upload');
                }
            } catch (error:any) {
                let message = "Impossibile caricare file";
                if (error.response.status === 400) {
                    const data = error.response.data;
                    message = data.message;
                }
                NotificationService.getInstance().openNotificationError(message);
                if (onError){
                    onError(error);
                }
            }
            props?.onUpload()
        },
        fileList: [],
        accept: props.acceptTypes,
        // multiple: true,
    };

    return <>
        <Upload {...uploadConfigProps} beforeUpload={props.beforeUpload}>
            <Button type="primary" icon={<UploadOutlined />}>Carica</Button>
        </Upload>
    </>
}