import {Button, Flex, Typography} from "antd";
import {UploadFilesModal} from "../../../../Common/UploadFileModal/UploadFilesModal";
import {useState} from "react";
import {AttachmentType, PracticeFileRow, UploadAttachment} from "../../../../../models/Practice/PracticeFIle";
import {PracticeProgress, ProceedNextRequest} from "../../../../../models/Practice/PracticeDetail";
import {PracticeProgressStatus} from "../../../../../models/Practice/Practice";
import {AuthService} from "../../../../../services/AuthService";
import {PracticesHttpService} from "../../../../../services/Http/PracticesHttpService";
import {NotificationService} from "../../../../../services/NotificationService";
import {useParams} from "react-router-dom";
import {InsuranceProviderEclaim} from "../../../../../models/InsuranceProvider";
import {useClaimStatus, claimClosed} from "../../../ClaimStatusProvider";

export interface UploadFilesLiquidationProps {
    files: Array<PracticeFileRow>;
    loadFiles: () => void;
    lensComplainceRequired: boolean;
    signedPicRequired: boolean;
    declarationRequired: boolean;
    disableButtons:boolean;
    progress: PracticeProgress[] | undefined,
    status: PracticeProgressStatus
    reloadDetail: () => Promise<void>
    insuranceProvider: InsuranceProviderEclaim
}

export function UploadFilesLiquidation(props: UploadFilesLiquidationProps){
    const { Text} = Typography;
    const { id } = useParams();

    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [dataToSend, setDataToSend] = useState<UploadAttachment>();

    const claimStatus = useClaimStatus();

    const disableButtons = (status: string)=> {
        return claimClosed(claimStatus) || props.disableButtons || props.status === PracticeProgressStatus.PIC_NON_AUTORIZZATA;
    }

    const onClose= function() {
        if (isModalOpen)
            setModalOpen(false)
        props.loadFiles();
    }

    const uploadAttachmentLensComplaince : UploadAttachment ={
        filename: "ConformitaLenti.png",
        attachmentType: AttachmentType.LENS_COMPLAINCE
    }

    const uploadAttachmentInvoice : UploadAttachment ={
        filename: "fattura.png",
        attachmentType: AttachmentType.INVOICE
    }

    const uploadAttachmentSignedPic : UploadAttachment ={
        filename: "picFirmata.png",
        attachmentType: AttachmentType.SIGNED_PIC
    }

    const uploadAttachmentDeclaration : UploadAttachment ={
        filename: "dichiarazioneFirmata.png",
        attachmentType: AttachmentType.DECLARATION
    }

    const openModal = (docLoaded: boolean, dataToSend: UploadAttachment) => {
        if (docLoaded) return;

        setDataToSend(dataToSend);
        setModalOpen(true);
    }

    const invoiceLoaded = props.files.find(file => file.type == AttachmentType.INVOICE) != undefined;
    const lensComplainceLoaded = props.files.find(file => file.type == AttachmentType.LENS_COMPLAINCE) != undefined;
    const signedPicLoaded = props.files.find(file => file.type == AttachmentType.SIGNED_PIC) != undefined;
    const declarationLoaded = props.files.find(file => file.type == AttachmentType.DECLARATION) != undefined;

    const shouldConfirm = [
        PracticeProgressStatus.PIC_AUTORIZZATA,
        PracticeProgressStatus.ERRORE_IN_FATTURA
    ].includes(props.status);

    const canConfirm = (
        invoiceLoaded &&
        (!props.lensComplainceRequired || lensComplainceLoaded) &&
        (!props.signedPicRequired || signedPicLoaded) &&
        (!props.declarationRequired || declarationLoaded)
    )

    const onDocConfimed = async () => {
        let operator = AuthService.getOperatorOnline();
        const body: ProceedNextRequest = { metadata: {operator: operator!.name }, event: PracticeProgressStatus.FATTURA_DA_INVIARE}
        try {
            await PracticesHttpService.saveProceedNext(id ?? "", body);
            await props.reloadDetail();
        } catch (_) {
            NotificationService.getInstance().openNotificationError("Operazione non riuscita")
        }
    }

    return<>
        <div>
            <Text strong>Documenti obbligatori per liquidazione </Text>
            <Flex gap={"small"} className={"margin-y-10px"}>
                <Button disabled={disableButtons(claimStatus)} type={"primary"} className={invoiceLoaded ? "luxottica-button-success" : ""} onClick={()=>{openModal(invoiceLoaded, uploadAttachmentInvoice)}}>Fattura</Button>
                { props.lensComplainceRequired && <Button disabled={disableButtons(claimStatus)} type={"primary"} className={lensComplainceLoaded ? "luxottica-button-success" : ""} onClick={()=>{openModal(lensComplainceLoaded, uploadAttachmentLensComplaince)}}>Conformità lenti</Button> }
                { props.signedPicRequired && <Button disabled={disableButtons(claimStatus)} type={"primary"} className={signedPicLoaded ? "luxottica-button-success" : ""} onClick={()=>{openModal(signedPicLoaded, uploadAttachmentSignedPic)}}>PIC firmata</Button> }
                { props.declarationRequired && <Button disabled={disableButtons(claimStatus)} type={"primary"} className={declarationLoaded ? "luxottica-button-success" : ""} onClick={()=>{openModal(declarationLoaded, uploadAttachmentDeclaration)}}>PIC firmata</Button> }
                { dataToSend && <UploadFilesModal insuranceProvider={props.insuranceProvider} attachmentData={dataToSend} isModalOpen={isModalOpen} onClose={onClose}></UploadFilesModal> }
                { shouldConfirm && <Button disabled={claimClosed(claimStatus) || !canConfirm} type={"primary"} onClick={onDocConfimed}>Conferma doc.</Button>}
            </Flex>
        </div>

    </>
}