import { SquareTileIcon } from "./SquareTileIcon/SquareTileIcon";
import { SquareTileTitle } from "./SquareTileTitle/SquareTileTitle";
import { SquareTileDescription } from "./SquareTileDescription/SquareTileDescription";
import { ReactElement } from "react";
import './SquareTile.css'
import { Space } from "antd";
export interface SquareTileProps{
    title:string;
    description:string;
    icon:ReactElement<any, any>;
    onCLick: ()=>void
}
export function SquareTile(props:SquareTileProps){
 
    return <>
        <div className = "square-tile" onClick={props.onCLick}>
            <SquareTileIcon icon={props.icon}></SquareTileIcon>
            
            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
                <SquareTileTitle title={props.title}></SquareTileTitle>
            </Space>

            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
                <SquareTileDescription description={props.description}></SquareTileDescription>
            </Space>

        </div>
    </>
}